import { Carousel } from "antd";
import { useRef } from "react";
import { useState } from "react";
import { getImageUrl } from "../../helpers";
import transac from './transaction.module.less'

const TransactionImage = (props) => {
    let { children, images = [] } = props;
    const [transaction, set_transaction] = useState(false)
    const [currentIndex , set_currentIndex] = useState(0)
    const carousel = useRef()

    const onMouseEnter = () => {
        if(currentIndex < images.length - 1){
            carousel.current.goTo(currentIndex + 1, false)
        }else{
            carousel.current.goTo(0, false)
        }
        set_transaction(true)

    }

    const onMouseOut = () => {
        set_transaction(false)
    }
    return <div style={{ width: '100%', height: '100%' }} onMouseEnter={onMouseEnter} onMouseOut={onMouseOut} {...props} >
        <div className={transac["container"]}>
            <Carousel autoplaySpeed={1300} ref = {carousel} style={{ width: '100%', height: '100%' }} autoplay={transaction} dotPosition={'right'}
            afterChange = {index => set_currentIndex(index)}>
                {images.map(item => <img alt = 'carousel' src={getImageUrl(item)} key={item} className={transac["img-fill"]} />)}
            </Carousel>
            {!transaction && <div className={transac["cover-image"]} />}
            {children}
        </div>
    </div>
}

export default TransactionImage