import { createRoot } from 'react-dom/client';
import RootRoute from "./routes/RootRoute";
import { Provider } from "react-redux";
import store from "./ducks/configStore";
import viVN from "antd/lib/locale/vi_VN";
import enUS from "antd/lib/locale/en_US";
import jpJP from "antd/lib/locale/ja_JP";
import { ConfigProvider } from "antd";
import { languages } from "./i18n";
import "./index.css"
import "moment/locale/vi";
import moment from "moment";
import { getI18n } from 'react-i18next';
import i18next from 'i18next';


const get_locale = () => {
  switch (localStorage.getItem("i18nextLng")) {
    case languages.tieng_anh:
        return enUS;
      case languages.tieng_viet:
          return viVN;
        case languages.tieng_nhat:
          return jpJP;
  
    default:
      return viVN;
  }
}



const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<div style={{ width: '100vw', maxWidth: '100%', display: 'flex', justifyContent: 'center', overflow: 'hidden' }}>
  <div className="content-page-container">

    <Provider store={store}>
      <ConfigProvider
        locale={ get_locale ()}
      >
        <RootRoute />
      </ConfigProvider>
    </Provider>
  </div>
</div>);
