import { Carousel, Col, Row, Space, Tooltip } from "antd";
import { Link, useHistory } from "react-router-dom";
// import { Customer1, Customer2, Customer3, Customer4, Customer5, Customer6, Customer7, Customer8, Customer9 } from "../../../assets/svg";
import { calendarIcon, catalogueIcon, cert_astm, cert_bsen2006, cert_bsen2019, cert_iso9001_1, cert_iso9001_2, cert_jis3444, cert_jis3466, newsSign } from "../../../assets/img";
import { apis, keys, paths } from "../../../constants";
import cn from "classnames";
import homepage from "./homePage.module.less";
import { DoubleRightOutlined } from '@ant-design/icons'
import { useEffect, useState } from "react";
import { languageKeys, Translate } from "../../../i18n";
import { useDispatch, useSelector } from "react-redux";
import { appLoading, appLoadingDone, displayMessage } from "../../../ducks/slices/appSlice";
import { convertMillisecondToDate, getImageUrl, getLanguage } from "../../../helpers";
import axios from "axios";
import QueueAnimate from "../../../components/QueueAnimate/QueueAnimate";
const HomePage = () => {
  const [productList, set_productList] = useState([]);
  const history = useHistory()
  const dispatch = useDispatch()
  const certList = [{ Cert: cert_astm }, { Cert: cert_jis3444 }, { Cert: cert_jis3466 }, { Cert: cert_bsen2006 }, { Cert: cert_bsen2019 }, { Cert: cert_iso9001_1 }, { Cert: cert_iso9001_2 },]
  // const customerList = [{ Customer: Customer1 }, { Customer: Customer2 }, { Customer: Customer3 }, { Customer: Customer4 }, { Customer: Customer5 }, { Customer: Customer6 }, { Customer: Customer7 }, { Customer: Customer8 }, { Customer: Customer9 },]

  const [nsmvNews, set_nsmvNews] = useState([])
  const [industryNews, set_industryNews] = useState([])
  const [cultureNews, set_cultureNews] = useState([])
  const currentLanguage = useSelector(state => state.app.currentLanguage)

  useEffect(() => {
    dispatch(appLoading())
    initData()
    setTimeout(() => {
      dispatch(appLoadingDone())
    }, 2000)
  }, [currentLanguage])

  const initData = () => {
    loadDataNews()
    initProductList();
  }

  const loadDataNews = () => {
    let url = apis.Home_Get_News + getLanguage();
    axios.get(url).then(response => {
      if (response.status === keys.STATUS_SUCCESS && response.data.status === keys.STATUS_SUCCESS) {
        set_nsmvNews(response.data.data?.nsmv || [])
        set_industryNews(response.data.data?.industry || [])
        set_cultureNews(response.data.data?.culture || [])
      }
    }).catch((err) => {
      
    })
  }

  const initProductList = () => {
    let config = {};

    let requestPath = apis.Post_Home_Product_List + getLanguage();
    dispatch(appLoading());
    axios.post(requestPath, config)
      .then(response => {
        if (response.status === keys.STATUS_SUCCESS) {
          let { data } = response;
          if (data.status === keys.STATUS_SUCCESS && !!data.data) {
            set_productList(data.data)
          }
        } else {
          dispatch(displayMessage({
            type: 'error',
            content: response.message,
          }))
        }
        dispatch(appLoadingDone())
      })
      .catch(err => {
        dispatch(appLoadingDone())
        dispatch(displayMessage({
          type: 'error',
          content: err.message,
        }))
        
      })
  }

  const productItem = (items, index) => (

    items.map((item, index) => (
      // <div key={item.title}>
      <Col key={index} span={6} className={homepage["product-block"]} onClick={() => history.push(paths.product + "?id=" + item.id)}>
        <Link to={paths.product + "?id=" + item.id}>

          <div style={{ width: '100%', height: 'fit-content' }}>
            <div className={homepage["scalebox"]}>
              <div className={homepage["scalebox-image"]}>
                <img src={getImageUrl(item.imageResponses.length > 0 ? item.imageResponses[0].localThumbnailPath : keys.nsmvLogo)} className={homepage["img-fill"]} style={{ borderRadius: '15px' }} alt='product' />
              </div>
            </div>
          </div>
          <Row justify="space-between" style={{ width: '100%', flexGrow: 1, paddingTop: '8px' }} align='middle' wrap={false}>
            <Col flex={'auto'}>
              <div className={homepage["product-title"]}>{item.language[currentLanguage].productName}</div>
            </Col>
            <Col>
              <DoubleRightOutlined className={homepage["product-title-arrow"]} />
            </Col>
          </Row>
          <Tooltip title={item.language[currentLanguage].description} showArrow={false} color={'#fff'} placement='topRight'
            overlayInnerStyle={{ color: '#000' }}>
            <div className={homepage["item-description"]}>{item.language[currentLanguage].productDetail}</div>
          </Tooltip>
        </Link>
      </Col>
    ))
  )

  const certItem = (item, index) => (
    <Col className={homepage["cert-block"]} key = {index}>
      <div style={{ width: '100%', height: 'fit-content' }}>
        {/* <item.Cert /> */}
        <img src={item.Cert} className={homepage['img-contain']} alt='certificate'/>
      </div>
    </Col>
  )

  // const customerItem = (item) => (
  //   <Col flex={'20%'} className={homepage["product-block"]}>
  //     <div style={{ width: '100%', height: 'fit-content' }}>
  //       <item.Customer />
  //     </div>
  //   </Col>
  // )

  const viewCulture = (item) => {
    history.push(`${paths.news_detail}/${item.id}`)
  }
  let isShowNews = (!!nsmvNews && nsmvNews.length > 0) || (!!industryNews && industryNews.length > 0) || (!!cultureNews && cultureNews.length > 0)

  return (
    <div className={homepage["container"]}>
      <div className={homepage["banner-container"]}>
        {/* <img src={getImageUrl('20230322_110253_008.png')} className={homepage["img-contain"]} />
         */}
        <Carousel autoplay>
          {keys.banner_home.map((item, index) => <div key={index} className={homepage["banner-image"]}>
            <img src={getImageUrl(item)} key={item} className={homepage["img-fill"]} alt='banner'/>
          </div>)}

        </Carousel>
        <div className={homepage["banner-content"]}>
          <div className={homepage["content-title"]}>{Translate(languageKeys.Toppage_title)}</div>
          {/* <div className={homepage["content-data"]}>Nippon Steel Metal Products Vietnam Co., Ltd (NSMV) is a joint venture between top leading Japanese companies in steel manufacturing field, including: Nippon Steel Metal Products Co., Ltd, Sumitomo Corporation, Kyoei Steel Ltd and BacViet Technology Joint stock company (BVIT).</div> */}
        </div>
      </div>

      <div className={homepage["content-container"]}>
        <div className={homepage["block-content"]}>
          <Row wrap='no-wrap' justify={'space-between'}>
            <Col span={13} className={homepage["intro-right"]}>
              <div style={{ height: '100%', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', flexDirection: 'column' }}>

                <div className={homepage["introduction-title"]}>
                  {Translate(languageKeys.Toppage_Introduction_title)}
                </div>
                <div className={homepage["introduction-body"]} style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                  <div>
                    {Translate(languageKeys.Toppage_company_introduction_part_1)}
                  </div>
                  <div style={{ marginTop: '2vh' }}>
                    {Translate(languageKeys.Toppage_company_introduction_part_2)}
                  </div>
                </div>
              </div>

            </Col>

            <Col span={11}>
              <Row gutter={[10, 20]} justify={'space-around'}>
                <Col span={12}>
                  <Link to={{
                    pathname: paths.introduction,
                    state: Translate(languageKeys.AboutUS_Banner_title)
                  }} >
                    <div className={homepage["image-block"]}>
                      <img src={getImageUrl(keys.home_introImage.aboutus)} className={homepage["img-contain"]} alt='navigate'/>
                      <div className={homepage["image-content"]}>
                        <div className={homepage["title"]}>
                          {Translate(languageKeys.Toppage_navigate_AboutUs)}
                        </div>

                      </div>
                    </div>
                  </Link>
                </Col>
                <Col span={12} >
                  <Link to={{
                    pathname: paths.introduction,
                    state: Translate(languageKeys.Project_Banner_title)
                  }}>
                    <div className={homepage["image-block"]}>
                      <img src={getImageUrl(keys.home_introImage.project)} className={homepage["img-contain"]} alt='navigate'/>
                      <div className={homepage["image-content"]}>
                        <div className={homepage["title"]}>
                          {Translate(languageKeys.Toppage_navigate_Project)}
                        </div>
                      </div>
                    </div>
                  </Link>
                </Col>
                {/* </Row>
              <Row gutter={10} justify={'space-between'} style={{ marginTop: '10px' }}> */}

                <Col span={12}>
                  <Link to={{
                    pathname: paths.introduction,
                    state: Translate(languageKeys.Site_Banner_title)
                  }}>
                    <div className={homepage["image-block"]}>
                      <img src={getImageUrl(keys.home_introImage.site)} className={homepage["img-contain"]} alt='navigate' />
                      <div className={homepage["image-content"]}>
                        <div className={homepage["title"]}>
                          {Translate(languageKeys.Toppage_navigate_Sites)}
                        </div>
                      </div>
                    </div>
                  </Link>
                </Col>
                <Col span={12}>
                  <Link to={paths.recruitment} >
                    <div className={homepage["image-block"]}>
                      <img src={getImageUrl(keys.home_introImage.recruitment)} className={homepage["img-contain"]} alt='navigate' />
                      <div className={homepage["image-content"]}>
                        <div className={homepage["title"]}>
                          {Translate(languageKeys.Toppage_navigate_Recuitment)}
                        </div>
                      </div>
                    </div>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>



        {!!isShowNews && <div className={cn(homepage["block-content"], homepage["gray-background"], homepage["full"])}>
          <div className={homepage["block-content-title"]} style={{ alignSelf: 'flex-start', marginLeft: '4vw', marginBottom: '20px' }}>{Translate(languageKeys.Toppage_cultuteNews_title)}</div>

          {(!!nsmvNews && nsmvNews.length > 0) && <div className={cn(homepage["block-content"], homepage["full"])}>
            <Row className={cn(homepage["news-block-title"])} style={{ backgroundColor: '#60A5FA' }}>
              <img src={newsSign} alt='icon' style={{ width: '2vw', marginRight: '10px', maxWidth: '10px' }} /> {Translate(languageKeys.Toppage_nsmvNews_title)}</Row>
            <QueueAnimate>
              {nsmvNews.map((item, index) => <Row key={index} className={cn(homepage["news-block"])} wrap={false} align='top'>
                <Space className={cn(homepage["news-block-content-time"])}>
                  <img src={calendarIcon}  alt='icon' style={{ width: '2vw', maxWidth: '20px' }} />
                  {item.language[`${currentLanguage}`][`${keys.New_FormControls.title}`]}
                </Space>
                <Col flex={'auto'}><div className={cn(homepage["news-block-content"])}>{item.language[`${currentLanguage}`][`${keys.New_FormControls.summary}`]}</div></Col>
              </Row>)}
            </QueueAnimate>
          </div>}

          {(!!industryNews && industryNews.length > 0) && <div className={cn(homepage["block-content"], homepage["full"])}>
            <Row className={cn(homepage["news-block-title"])} style={{ backgroundColor: '#22D3EE' }}>
              <img src={newsSign}  alt='icon' style={{ width: '2vw', marginRight: '10px', maxWidth: '10px' }} />{Translate(languageKeys.Toppage_industryNews_title)}</Row>
            <QueueAnimate>
              {industryNews.map((item, index) => <Row key={index} className={cn(homepage["news-block"])} wrap={false} align='top'>
                <Space className={cn(homepage["news-block-content-time"])}>
                  <img  alt='icon' src={calendarIcon} style={{ width: '2vw', maxWidth: '20px' }} />
                  {item.language[`${currentLanguage}`][`${keys.New_FormControls.title}`]}
                </Space>
                <Col flex={'auto'}><div className={cn(homepage["news-block-content"])}>{item.language[`${currentLanguage}`][`${keys.New_FormControls.summary}`]}</div></Col>
              </Row>)}
            </QueueAnimate>
          </div>}


          {(!!cultureNews && cultureNews.length > 0) && <Row wrap={'wrap'} justify={'space-between'} style={{ paddingLeft: '4vw', paddingRight: '4vw', width: '100%' }}>
            {cultureNews.slice(0, 3).map((item, index) => index === 1 ? <Col span={8} key={index} >
              <QueueAnimate type='bottom' >
                <div className={homepage["culture-block"]} >
                  <div className={homepage["culture-block-content"]} >
                    <div className={homepage["culture-title"]}>{item.language[`${currentLanguage}`][`${keys.New_FormControls.title}`]}</div>
                    <div className={homepage["culture-content"]}>{item.language[`${currentLanguage}`][`${keys.New_FormControls.summary}`]}</div>
                    <div className={homepage["culture-time"]}>{convertMillisecondToDate(item.createDate)}</div>
                  </div>
                  {/* <RecuiteBg /> */}
                  <div className={homepage["image-contain"]} >
                    <img  alt='culture' src={getImageUrl(item.imageResponses.length > 0 ? item.imageResponses[0].localPath : keys.nsmvLogo)} className={homepage[`img-${item.imageStyle}-width`]} />
                  </div>
                  <Link to={`${paths.news_detail}/${item.id}`}>
                    <div className={homepage["culture-readmore"]} onClick={() => viewCulture(item)}>{Translate(languageKeys.Common_ViewMore)}</div>
                  </Link>

                </div>
              </QueueAnimate>
            </Col> : <Col span={8} key={index}>
              <QueueAnimate type='top'>
                <div className={homepage["culture-block"]} >
                  <div className={homepage["image-contain"]} >
                    <img  alt='culture' src={getImageUrl(item.imageResponses.length > 0 ? item.imageResponses[0].localPath : keys.nsmvLogo)} className={homepage[`img-${item.imageStyle}-width`]} />
                  </div>
                  {/* <RecuiteBg /> */}
                  <div className={homepage["culture-block-content"]} >
                    <div className={homepage["culture-time"]}>{convertMillisecondToDate(item.createDate)}</div>
                    <div className={homepage["culture-title"]}>{item.language[`${currentLanguage}`][`${keys.New_FormControls.title}`]}</div>
                    <div className={homepage["culture-content"]}>{item.language[`${currentLanguage}`][`${keys.New_FormControls.summary}`]}</div>
                  </div>
                  <Link to={`${paths.news_detail}/${item.id}`}>
                    <div className={homepage["culture-readmore"]} onClick={() => viewCulture(item)}>{Translate(languageKeys.Common_ViewMore)}</div>
                  </Link>
                </div>
              </QueueAnimate>
            </Col>)}

          </Row>}
        </div>}

        <div className={homepage["block-content"]}>
          <div className={homepage["block-content-title"]}>{Translate(languageKeys.Toppage_Product_title)}</div>
          <a href={keys.catalogLink} target="_blank" rel="noopener noreferrer" >
            <Row className={homepage["catalogue-link"]} align='middle'><img  alt='icon' src={catalogueIcon} style={{ width: '4vw', maxWidth: '40px' }} />
              <div className={homepage["link"]}>{Translate(languageKeys.Toppage_catalog)}</div></Row>
          </a>
          <Row justify={'start'} gutter={[30, 20]} style={{ minHeight: '80px', width: '100%', alignSelf: 'center' }}>
            {Object.keys(productList).map((items, index) => (
              productItem(productList[`${items}`], index)
            ))}
          </Row>
        </div>

        <div className={homepage["block-content"]}>
          <QueueAnimate type='left' style={{ height: 'fit-content' }}>
            <div className={homepage["block-content-title"]}>{Translate(languageKeys.Toppage_Cerificate_title)}</div>
          </QueueAnimate>
          <QueueAnimate type='top'>
            <Row wrap={'wrap'} justify={'space-between'} align='middle'>
              {certList.map((item, index) => certItem(item, index))}
            </Row>
          </QueueAnimate>
        </div>

        {/* <div className={homepage["block-content"]}>
        <QueueAnimate type = 'left' style={{height: 'fit-content'}}>
          <div className={homepage["block-content-title"]}>{Translate(languageKeys.Toppage_Customer_title)}</div>
          </QueueAnimate>
          <QueueAnimate type = 'top'>
          <Row wrap={'wrap'} justify={"space-between"}>
            {customerList.map((item) => customerItem(item))}
          </Row>
          </QueueAnimate>
        </div> */}

      </div>
    </div>
  );
};

export default HomePage;