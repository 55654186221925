import { Button, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import cn from "classnames";
import recruitment from "./recruitment.module.less";
import { getImageUrl } from "../../../helpers";
import { useDispatch, useSelector } from "react-redux";
import { apis, keys } from "../../../constants";
import { appLoading, appLoadingDone, displayMessage } from "../../../ducks/slices/appSlice";
import axios from "axios";
import { languageKeys, Translate } from "../../../i18n";
import TransactionImage from "../../../components/TransactionImage/TransactionImage";
import renderHTML from "react-render-html";


const Recruitment = () => {
  const auth = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const [initData, set_initData] = useState({})

  useEffect(() => {
    initDetail()
  }, [auth])

  //initNewsDetail: lấy data của news để update
  const initDetail = async () => {
    let config = {
      headers: {
        Authorization: auth.token,
        
      },
    }
    dispatch(appLoading())
    await axios.get(apis.Recuitement_Get_GetRecuitment, config)
      .then(response => {
        if (response.status === keys.STATUS_SUCCESS) {
          let { data } = response;
          if (data.status === keys.STATUS_SUCCESS && !!data.data) {
            set_initData(data.data)
          }
        } else {
          dispatch(displayMessage({
            type: 'error',
            content: response.message,
          }))
        }
        dispatch(appLoadingDone())
      })
      .catch(err => {
        dispatch(appLoadingDone())
        dispatch(displayMessage({
          type: 'error',
          content: err.message,
        }))
        
      })
  }

  return (
    <div className={recruitment["container"]}>
      <div className={recruitment["banner-container"]}>
        <img src={getImageUrl(keys.banner_recruitment)} className={recruitment["img-contain"]} alt="recuitBanner"/>
        <div className={recruitment["banner-content"]}>
          <div className={recruitment["content-title"]}>{Translate(languageKeys.Recruitment_Banner_title)}</div>
          {/* <div className={recruitment["content-data"]}>Lorem ipsum dolor sit amet consectetur. Scelerisque at ullamcorper aliquet cursus ornare tempor nisi. Massa dignissim leo velit ipsum. </div> */}
        </div>
      </div>

      <div className={recruitment["content-container"]}>
        <div className={recruitment["block-content"]}>
          <Row wrap='no-wrap' justify={'space-between'}>
            <Col span={24} style={{ paddingRight: '20px' }}>
            {/* <div className={recruitment["introduction-divider"]}>╔═.✾. ══════════════╗</div> */}
              <div className={recruitment["introduction-welcome"]}>{renderHTML(Translate(languageKeys.Recruitment_DirectorMessage1))}</div>
              <div className={cn(recruitment["introduction-welcome"], recruitment["shrink"])}>{renderHTML(Translate(languageKeys.Recruitment_DirectorMessage2))}</div>
            <div className={cn(recruitment["introduction-divider"],recruitment["down"])}></div>
              <div className={recruitment["introduction-body"]}>
                {(!!initData.imageIntroduction && !!initData.imageIntroduction[0]) && <div className={recruitment["image-container"]}>
                  <img src={getImageUrl(initData.imageIntroduction[0].localPath)} alt="imagePresident" className={recruitment['img-contain']} />
                  <div className={recruitment["director-title"]}>{Translate(languageKeys.AboutUs_GeneralDirector_title)}</div>
                  <div className={recruitment["director-name"]}>{Translate(languageKeys.AboutUs_GeneralDirector_name)}</div>
                </div>}
                {renderHTML(Translate(languageKeys.Recuitment_Content))}
                {/* {initData.introduction} */}
              </div>
            </Col>

            {/* <Col span={11} style = {{textAlign: 'center'}}>
                <img src={recruitmentThumb} alt="image"/>
              </Col> */}
          </Row>
          
        </div>
        {/* <div className={cn(recruitment["introduction-divider"],recruitment["break"] )}></div> */}
        <div className={recruitment["block-content"]}>
          <Row wrap='no-wrap' justify={'space-between'} style = {{width: '100%'}}>
            <Col className={recruitment["image-showcase"]}>
              <TransactionImage className={recruitment["image-block"]}
                images={
                  !!initData.imageFactory ?
                    initData.imageFactory.map(item => item.localPath)
                    : [keys.nsmvLogo]

                }
              >
                {/* <img src={recruitmentOffice} className={recruitment["svg"]}/> */}
                <div className={recruitment["image-content"]}>
                  <div className={recruitment["title"]}>
                    {Translate(languageKeys.Recruitment_LifeAtFactory)}
                  </div>
                </div>
              </TransactionImage>
            </Col>
            <Col className={recruitment["image-showcase"]}>
              <TransactionImage className={recruitment["image-block"]}
                images={
                  !!initData.imageOffice ?
                    initData.imageOffice.map(item => item.localPath)
                    : [keys.nsmvLogo]

                }
              >
                <div className={recruitment["image-content"]}>
                  <div className={recruitment["title"]}>
                    {Translate(languageKeys.Recruitment_LifeAtOffice)}
                  </div>
                </div>
              </TransactionImage>
            </Col>
          </Row>
        </div>

        {!!initData.link && <div className={recruitment["block-content"]}>
          <Row wrap='no-wrap' justify={'center'} gutter={40}>
            {initData.link.map((item, index) => (<Col key = {index}
              style={{ textAlign: 'center' }}
              className={recruitment["ref-link"]} >
              <a href={item.referenceLink} target="_blank" rel="noopener noreferrer">
                <Button type = 'primary' className={recruitment["button"]}>
                  {renderHTML(Translate(languageKeys.Recuitment_Button))}
                </Button>
                {/* <img src={item.iconLink} className={recruitment["img-contain"]} /> */}
              </a>
            </Col>))}
          </Row>
        </div>}
      </div>

    </div>
  );
};

export default Recruitment;
