import { Col, Row } from "antd";
import cn from "classnames";
import aboutUs from "./aboutUs.module.less";
import { InfoCircleOutlined } from '@ant-design/icons'
import { recruitmentThumb } from "../../../assets/img";
import { languageKeys, Translate } from "../../../i18n";
import renderHTML from "react-render-html";

// const IconFont = createFromIconfontCN({
//   scriptUrl: [
//     '//at.alicdn.com/t/font_1788044_0dwu4guekcwr.js', // icon-javascript, icon-java, icon-shoppingcart (overrided)
//     '//at.alicdn.com/t/font_1788592_a5xf2bdic3u.js', // icon-shoppingcart, icon-python
//   ],
// });

let a = new Array(16)
a.fill({})
const AboutUs = () => {
  return (
    <div className={aboutUs["content-container"]}>
      <Row justify={'start'} className={aboutUs["title-bar"]}>
        <InfoCircleOutlined style={{ paddingRight: '20px' }} />{Translate(languageKeys.Site_AboutManufacturuing_title)}
      </Row>
      <div className={aboutUs["paragraph"]}>
        {renderHTML(Translate(languageKeys.AboutUs_NSMP_Content1))}
        <div style={{ marginTop: '10px' }}>{renderHTML(Translate(languageKeys.AboutUs_NSMP_Content2))}</div>
        <div>{renderHTML(Translate(languageKeys.AboutUs_NSMP_Content3))}</div>
        <div>{renderHTML(Translate(languageKeys.AboutUs_NSMP_Content4))}</div>
        <div>{renderHTML(Translate(languageKeys.AboutUs_NSMP_Content5))}</div>
        <a href={'https://www.ns-kenzai.co.jp/'} target="_blank" rel="noopener noreferrer">
          <div className={aboutUs["link"]}>{renderHTML(Translate(languageKeys.AboutUs_NSMP_Link))}</div>
        </a>
      </div>

      <Row justify={'start'} className={aboutUs["title-bar"]}>
        <InfoCircleOutlined style={{ paddingRight: '20px' }} />{Translate(languageKeys.Site_AboutRepresentOffice_title)}
      </Row>

      <div className={cn(aboutUs["paragraph"])}>
        <div className={aboutUs["image-container"]}>
          <img src={recruitmentThumb} alt="general" className={aboutUs["image-thumb"]} />
          <div className={aboutUs["director-title"]}>{Translate(languageKeys.AboutUs_GeneralDirector_title)}</div>
          <div className={aboutUs["director-name"]}>{Translate(languageKeys.AboutUs_GeneralDirector_name)}</div>
        </div>
        {renderHTML(Translate(languageKeys.AboutUS_NSMV_Content1))}

        <div style={{ marginTop: '15px' }}>{renderHTML(Translate(languageKeys.AboutUS_NSMV_Content2))}</div>

        <div style={{ marginTop: '15px' }}>{renderHTML(Translate(languageKeys.AboutUS_NSMV_Content3))}</div>

        <div style={{ marginTop: '15px' }}>{renderHTML(Translate(languageKeys.AboutUS_NSMV_Content4))}</div>

        <div style={{ marginTop: '15px' }}>{renderHTML(Translate(languageKeys.AboutUS_NSMV_Content5))}</div>
      </div>

      <Row justify={'space-between'} style={{ width: '100%', paddingTop: '30px', paddingBottom: '30px' }}>
        <Col span={12} style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
          <div className={cn(aboutUs["youtube-wrapper"])}>
            <iframe src="https://www.youtube.com/embed/AwCADpi0yy4" title="YouTube video player" style={{ borderRadius: '15px' }} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
        </Col>
        <Col span={12} style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
          <div className={cn(aboutUs["youtube-wrapper"])}>
            <iframe src="https://www.youtube.com/embed/7u97RuADWvQ" title="YouTube video player" style={{ borderRadius: '15px' }} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
        </Col>
      </Row>

    </div>

    // </div>
  );
};

export default AboutUs;
