import { createSlice } from "@reduxjs/toolkit";
import { keys } from "../../constants";

const initState = {
  loginTime: 0,
  token: ''
};

const authSlice = createSlice({
  name: "auth",
  initialState: initState,
  reducers: {
    doLogin() {},
    doLogout() {},

    authSuccess(state, action) {
      let time  = new Date().getTime();
      localStorage.setItem(keys.key_token, action.payload.token);
      localStorage.setItem(keys.key_loginTime, time.toString());
      return { ...state, token: action.payload.token, loginTime: time };
    },

    authRestore(state, action) {
      return { ...state, token: action.payload.token, loginTime: action.payload.token };
    },

    authFail(state, action) {
      localStorage.removeItem(keys.key_token);
      localStorage.removeItem(keys.key_loginTime);
      return { ...state, token: '', loginTime: 0 };
    },

    resetAuthMessage(state) {
      return { ...state, isOk: undefined, message: undefined };
    },
  },
});

export const { doLogin, doLogout, authSuccess, authFail, resetAuthMessage, authRestore } =
  authSlice.actions;

export default authSlice.reducer;
