import { Col, Row } from 'antd';
import adminNavigator from './adminNavigator.module.less'
import cn from 'classnames'
import { paths } from '../../../../constants';
import { Link, useLocation } from 'react-router-dom';
import { languageKeys, Translate } from '../../../../i18n';

const AdminNavigatorBar = (props) => {
    const location = useLocation()
    return <Row justify={'center'} align='bottom' className={adminNavigator['container']}>
        <Col >
            <Link to = {paths.admin_list}>
            <div className={cn(adminNavigator['navigate-button'], location.pathname.includes(paths.admin_list) && adminNavigator['selected'])}>
                {Translate(languageKeys.Header_Admin_Button_Manager)}
            </div>
            </Link>
        </Col>
        <Col >
            <Link to = {paths.admin_news}>
            <div className={cn(adminNavigator['navigate-button'], location.pathname.includes(paths.admin_news) && adminNavigator['selected'])}>
                {Translate(languageKeys.Header_Admin_Button_News)}
            </div>
            </Link>
        </Col>
        <Col >
            <Link to = {paths.admin_product}>
            <div className={cn(adminNavigator['navigate-button'], location.pathname.includes(paths.admin_product) && adminNavigator['selected'])}>
                {Translate(languageKeys.Header_Admin_Button_Product)}
            </div>
            </Link>
        </Col>
        <Col>
            <Link to = {paths.admin_recruitment}>
            <div className={cn(adminNavigator['navigate-button'], location.pathname.includes(paths.admin_recruitment) && adminNavigator['selected'])}>
                {Translate(languageKeys.Header_Admin_Button_Recruitment)}
            </div>
            </Link>
        </Col>
    </Row>
}
export default AdminNavigatorBar