import { Dropdown, Layout, Row } from "antd";
import mainSider from "./mainSider.module.less";
import cn from "classnames";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import i18n, { languageKeys, languages, Translate } from "../../../i18n";
import { paths } from "../../../constants";
import { setLanguage } from "../../../ducks/slices/appSlice";
import { useDispatch } from "react-redux";
import { locale } from "moment/moment";
import { jap_flag, us_flag, vietnam_flag } from "../../../assets/img";
import { en, jp, vi } from "../../../i18n/languages";

const MainSider = ({ collapsed = false, onPressCollapse = () => { } }) => {
    const history = useHistory();
    const location = useLocation()
    const dispatch = useDispatch()

    const handleClick = (path) => {
        history.push(path)
        onPressCollapse()
    };

    // lấy text ngôn ngữ hiện tại dựa theo locale
    const getLanguageIcon = () => {
        switch (Translate(languageKeys.Language_label)) {
            case en.Language_label:
                return us_flag;
            case vi.Language_label:
                return vietnam_flag
            case jp.Language_label:
                return jap_flag
            default:
                return ""
        }
    }

    return (
        <Layout.Sider
            className={cn(
                mainSider["container"],
                collapsed && mainSider["collapsed"]
            )}
            collapsed={collapsed}
            theme="light"
            width={300}
            collapsedWidth={0}
        >
            <div className={cn(
                mainSider["sider-container"]
            )}>
                <div
                    className={cn(
                        mainSider["sider-navigate"]
                    )}>

                    <div
                        onClick={() => handleClick(paths.home_page)} className={cn(
                            mainSider["sider-button"], location.pathname.includes(paths.home_page) && mainSider["select"]
                        )}>
                        {Translate(languageKeys.Header_Button_TopPage)}
                    </div>
                    <div
                        onClick={() => handleClick(paths.introduction)} className={cn(
                            mainSider["sider-button"], location.pathname.includes(paths.introduction) && mainSider["select"]
                        )}>
                        {Translate(languageKeys.Header_Button_Introduct)}
                    </div>
                    <div
                        onClick={() => handleClick(paths.product_list)} className={cn(
                            mainSider["sider-button"], location.pathname.includes(paths.product) && mainSider["select"]
                        )}>
                        {Translate(languageKeys.Header_Button_Products)}
                    </div>
                    <div
                        onClick={() => handleClick(paths.recruitment)} className={cn(
                            mainSider["sider-button"], location.pathname.includes(paths.recruitment) && mainSider["select"]
                        )}>
                        {Translate(languageKeys.Header_Button_Recruitment)}
                    </div>
                    <div className={mainSider["divider"]} />
                    <div
                        onClick={() => handleClick(paths.contact_us)} className={cn(
                            mainSider["sider-button"], mainSider["contact"]
                        )}>
                        {Translate(languageKeys.Header_Button_Contact)}
                    </div>
                </div>
                <div
                    className={cn(
                        mainSider["language-dropdown"]
                    )}>
                    <Dropdown
                        placement="bottomRight"
                        menu={{
                            items: languageProps,
                            selectable: true,
                            onSelect: (item) => {
                                locale(item.key)
                                i18n.changeLanguage(item.key);
                                window.location.reload(false)
                                dispatch(setLanguage({ language: item.key }))
                            }
                        }}

                    >
                        <div className={mainSider["dropdown-language"]}>
                            <Row align={'middle'} justify='space-around' style={{ paddingRight: '10px', fontWeight: '600' }}>
                                <img src={getLanguageIcon()} alt='language_icon' className={mainSider["img"]} width={"20px"} height={"20px"} style={{ marginRight: '10px', borderRadius: '25px' }} />
                                {Translate(languageKeys.Language_label)}
                            </Row>
                        </div>
                    </Dropdown>
                </div>
            </div>
        </Layout.Sider>
    );
};

export default MainSider;

const languageProps = [
    {
      key: languages.tieng_anh,
      label: <Row align={'middle'} gutter={10} justify='start' style={{width: '120px'}}>
        <img src={us_flag} alt='language_en' className={mainSider["img"]} width={"20px"} height={"20px"} style={{ marginRight: '20px', borderRadius: '25px' }} />
        English
      </Row>,
    },
    {
        key: languages.tieng_nhat,
        label: <Row align={'middle'} gutter={10} justify='start' style={{ width: '120px' }}>
            <img src={jap_flag} alt='language_jp' className={mainSider["img"]} width={"20px"} height={"20px"} style={{ marginRight: '20px', borderRadius: '25px' }} />
            日本語
        </Row>,
    },
    {
        key: languages.tieng_viet,
        label: <Row align={'middle'} gutter={10} justify='start' style={{ width: '120px' }}>
            <img src={vietnam_flag} alt='language_vi' className={mainSider["img"]} width={"20px"} height={"20px"} style={{ marginRight: '20px', borderRadius: '25px' }} />
            Tiếng Việt
        </Row>,
    },
];