import { Col, Row, Image, Modal, Segmented, Button, Select } from "antd";
import React, { useEffect } from 'react'
import { useImperativeHandle } from 'react';
import { useState } from 'react';
import cn from "classnames";
import previewProductModal from './previewProductModal.module.less';
import QRCode from "react-qr-code";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import renderHTML from 'react-render-html';
import { getImageUrl, getLanguage, isEmptyObject } from '../../../helpers';
import '../../../assets/style/previewHtml.css'
import { languageKeys, languages, Translate } from "../../../i18n";
import { Zalo } from "../../../assets/svg";
import { en, jp, vi } from "../../../i18n/languages";
import { jap_flag, us_flag, vietnam_flag } from "../../../assets/img";

const languageProps = [
  {
    value: languages.tieng_anh,
    label: <Row align={'middle'} gutter={10} justify='start'>
      <img src={us_flag} className={previewProductModal["img"]} width={"20px"} height={"20px"} style={{ marginRight: '20px', marginLeft: '5px', borderRadius: '25px' }} alt="language_flag"/>
      {en.Language_label}
    </Row>,
  },
  {
    value: languages.tieng_nhat_api,
    label: <Row align={'middle'} gutter={10} justify='start'>
      <img src={jap_flag} className={previewProductModal["img"]} width={"20px"} height={"20px"} style={{ marginRight: '20px', marginLeft: '5px', borderRadius: '25px' }} alt="language_flag"/>
      {jp.Language_label}
    </Row>,
  },
  {
    value: languages.tieng_viet,
    label: <Row align={'middle'} gutter={10} justify='start'>
      <img src={vietnam_flag} className={previewProductModal["img"]} width={"20px"} height={"20px"} style={{ marginRight: '20px', marginLeft: '5px', borderRadius: '25px' }} alt="language_flag"/>
      {vi.Language_label}
    </Row>,
  },
];


const PreviewProductModal = React.forwardRef((props, ref) => {
  let { onClose = () => { }, currentProduct = {} } = props;
  const [visible, set_visible] = useState(false)
  const [currentLanguage, set_currentLanguage] = useState(getLanguage())
  const [productName, set_productName] = useState()
  const [productDetail, set_productDetail] = useState()
  const [contactList, set_contactList] = useState([]);
  const [standardList, ] = useState([]);
  const [image, set_image] = useState('')
  const [imageList, set_imageList] = useState([])
  const [contactTitle, set_contactTitle] = useState(vi.Product_Contact)

  const [initData, set_initData] = useState({})
  const [currentStandard, set_currentStandard] = useState('')
  const [segment, ] = useState()
  const [dataproduct, set_dataproduct] = useState("<div></div>")


  useImperativeHandle(ref, () => {
    return {
      open() {
        set_visible(true)
      },
      close() {
        set_visible(false)
      },
      openPreview(data) {
        initPreview(data)
      }
    };
  }, []);

  const initPreview = (data) => {
    set_initData(data)
    // Handle image
    let initImage = '';
    let images = data.files || [];
    set_imageList(images)
    if(!!images.length)
      initImage = images[0].uid.includes("upload")? images[0].originFileObj : {...images[0], originFileObj:{url: images[0].url}};
    set_image(initImage)


    set_contactList(data.contacts)
    set_currentLanguage(data.current_language)
    let dataProduct = data.language.find(item => item.lang === data.current_language)
    console.log(data.language[data.current_language], data.current_language, dataProduct);
    set_productName(dataProduct.productName);
    set_productDetail(dataProduct.productDetail);
    let standardsData = []
    switch (data.current_language) {
      case languages.tieng_anh:
        standardsData = data.standards_en
        break;
        case languages.tieng_nhat:
        case languages.tieng_nhat_api:
          standardsData = data.standards_jp
        break;
    
      default:
        standardsData = data.standards
        break;
    }
      
    
    // Handle standards

    let data_segment = standardsData.map(item => item.standardName)
    let segment_detail = standardsData.map(item => item.standardDetail)
    set_currentStandard(data_segment[0])
    set_dataproduct(segment_detail[0] || '<div></div>')

    set_visible(true)
  }

  const onSelectStandard = (value) => {
    let itemStandard = initData.standards.find(item => item.standardName === value)
    set_dataproduct(itemStandard.standardDetail)
    set_currentStandard(value)
  }
  const segmentImageOption = (index, currentImage) => {
    return <Col span={6}>
      <div className={cn(previewProductModal["image-container"], image.currentIndex === index && previewProductModal["selected"])}>
        <Image 
        className={previewProductModal["sub-image"]} 
        height={'100%'} src={currentImage.originFileObj? currentImage.originFileObj.url : currentImage.url} 
        preview= {false} 
        onClick={() => set_image({...image, url: currentImage.originFileObj? currentImage.originFileObj.url : currentImage.url, currentIndex: index})} />
      </div>
    </Col>
  }
  
  useEffect(() => {
    if(!isEmptyObject(initData)){
      let dataProduct = initData.language.find(item => item.lang === currentLanguage)
      set_productName(dataProduct.productName);
      set_productDetail(dataProduct.productDetail);
      let standardsData = []
      switch (currentLanguage) {
        case languages.tieng_anh:
          set_contactTitle(en.Product_Contact)
          standardsData = initData.standards_en
          break;
          case languages.tieng_nhat:
          case languages.tieng_nhat_api:
            set_contactTitle(jp.Product_Contact)
            standardsData = initData.standards_jp
          break;
      
        default:
          set_contactTitle(vi.Product_Contact)
          standardsData = initData.standards
          break;
      }
        
      
      // Handle standards
  
      let data_segment = standardsData.map(item => item.standardName)
      let segment_detail = standardsData.map(item => item.standardDetail)
      set_currentStandard(data_segment[0])
      set_dataproduct(segment_detail[0] || '<div></div>')
    }  

  }, [currentLanguage, initData, set_contactTitle])

  useEffect(() => {
    if (!!currentProduct && !!standardList[segment]) {
      set_dataproduct(standardList[segment].standardDetail)
    }

  }, [segment])

  // lấy danh sách tiêu chuẩn hiện tại theo ngôn ngữ
  const getStandardList = () => {
    switch (currentLanguage) {
      case languages.tieng_anh:
        return initData.standards_en || [];
      case languages.tieng_nhat:
      case languages.tieng_nhat_api:
        return initData.standards_jp || [];
      default:
        return initData.standards || [];
    }
  }

  const onChangeLanguage = (lang) => {
    set_currentLanguage(lang)
  }

  return <Modal
    title={Translate(languageKeys.Admin_Product_Button_Preview)}
    open={visible}
    onCancel={() => { set_visible(false); onClose() }}
    width={1500}
    footer={[
      <Button type="primary" key="back" onClick={() => { set_visible(false); onClose() }}>
        {Translate(languageKeys.Common_Done)}
      </Button>,
    ]}
  >
    <div className={previewProductModal["container"]}>
      <div className={previewProductModal["content-container"]}>
        <div className={previewProductModal["block-content"]}>
        <Select
                defaultValue={languages.tieng_viet}
                value={currentLanguage}
                style={{
                  width: 160,
                  marginRight: 20,
                }}
                options={languageProps}
                onSelect={(lang) => onChangeLanguage(lang)}
              />
          <Row>
            <Col span={12}>
              <div className={cn(previewProductModal["image-container"], previewProductModal["main-image"])}>
                {!!image ? <Image height={'100%'} src={typeof image === 'string' ? getImageUrl(image) : image.url} /> : ''}
              </div>
            </Col>
            <Col flex={"auto"}>

            </Col>
            <Col span={10}>
              <div className={previewProductModal["item-info"]}>
                <div className={previewProductModal["item-title"]}>{productName}</div>
                <div>
                  {productDetail}
                </div>
              </div>

              <div className={previewProductModal["contact-title"]}>{contactTitle}</div>
              {contactList.map((contact) => (
                <div className={previewProductModal["contact-info"]}>

                  <div className={previewProductModal["contact-body"]}>
                    <Col span={6}>
                      <QRCode value="hey" size={64} />
                    </Col>
                    <Col span={18}>
                      <b>{contact.contactName}</b><br />
                      <PhoneOutlined /> {contact.contactPhone} <br />
                      <MailOutlined /> {contact.email} <br />
                      {!!contact.zaloPhone && <div><Zalo/> {contact.zaloPhone} <br /> </div>}
                    </Col>
                  </div>
                </div>
              ))}

            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Row gutter={16}  wrap = {false} style={{overflowX: 'scroll'}}>
                {imageList.map((item, index) =>
                  segmentImageOption(index, item)
                )}
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Segmented
                block
                // defaultValue={keys.product_jis}
                value={currentStandard}
                options={getStandardList().map(item => item.standardName)}
                onChange={(value) => { onSelectStandard(value) }}
                className={previewProductModal["segment-bar"]} size='large' />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className={previewProductModal["segment-container"]}>
                {renderHTML(dataproduct)}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  </Modal>

})

export default PreviewProductModal;