import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons"
import { Button, Input } from "antd"
import { useEffect } from "react"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { paths } from "../../../constants"
import { doLogin } from "../../../ducks/slices/authSlice"
import login from './login.module.less'
// import Brcypt from "bcryptjs-react";

const Login = () => {

    const [username, set_username] = useState('admin')
    const [password, set_password] = useState('')
    // '$2a$10$68vlR0FpkS.GM7aHmqDVz.wyDncC8Gd6OegP02yg3Tz7xX.XveW..'

    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const history = useHistory()

    useEffect(() => {
        let current = new Date().getTime()
        if(!!auth.token && current < auth.loginTime  + 86400000){
            history.push(paths.admin_list)
        }
    }, [auth, history])

    const onPressLogin = () => {
        if(!!password && !!username){
            dispatch(doLogin({username, password}))
        }
    }

    return <div className={login['container']} >
        <Input className={login['input']} addonBefore = 'Username' value={username} onChange = {({target}) => set_username(target.value)} onPressEnter={onPressLogin}/>
        <Input.Password className={login['input']} security = '' addonBefore = 'Password' value={password} onChange = {({target}) => set_password(target.value)} onPressEnter={onPressLogin}
        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}/>

        <Button className={login['button-login']} onClick = {onPressLogin}>Login</Button>
    </div>
}

export default Login