import React, { useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import isHotkey from 'is-hotkey'
import { Editable, withReact, useSlate, Slate } from 'slate-react'
import imageExtensions from 'image-extensions'
import {
  Editor,
  Transforms,
  createEditor,
  Text,
  Element as SlateElement,
} from 'slate'
import { jsx } from 'slate-hyperscript'
import escapeHtml from 'escape-html'
import { withHistory } from 'slate-history'
import isUrl from 'is-url'
import editorCss from './editor.module.less'
import  Icon, { AlignCenterOutlined, AlignLeftOutlined, AlignRightOutlined, BoldOutlined, CodeOutlined, ItalicOutlined, PictureOutlined, UnderlineOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import Colors from '../../../../constants/Colors'
import UploadModal from '../UploadModal/UploadModal'
import { useRef } from 'react'
import { apis } from '../../../../constants'
import { deserializeHtml } from './editorUtils'
import ImageElement from './SlateComponents/ImageElement'
import { languageKeys, Translate } from '../../../../i18n'
import { TextJustify } from '../../../../assets/svg'

var clientWidth = 0;

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
}

const LIST_TYPES = ['numbered-list', 'bulleted-list']
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']

const TextJustifyIcon = (props) => (
  <Icon component={TextJustify} {...props} />
);

const serialize = node => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text)
    if (string === '') {
      return `</br>`
    }

    if (node.bold) {
      string = `<b>${string}</b>`
    }
    if (node.italic) {
      string = `<i>${string}</i>`
    }
    if (node.underline) {
      string = `<u>${string}</u>`
    }
    if (node.code) {
      string = `<code>${string}</code>`
    }


    return string
  }
  let children;

  if (!!node.children) {
    children = node.children.map(n => serialize(n)).join('')
  } else {
    children = node.map(n => serialize(n))
  }

  switch (node.type) {
    case 'quote':
      return `<blockquote><p>${children}</p></blockquote>`
    case 'paragraph':
      return `<p style = "text-align: ${escapeHtml(node.align) || 'left'}">${children}</p>`
    case 'link':
      return `<a href="${escapeHtml(node.url)}">${children}</a>`
    case 'image':
      return `<div class = "${getImageClassName(escapeHtml(node.align))}"><img
        src="${escapeHtml(node.url)}"
        alt="${escapeHtml(node.id)}"
        width= "${caculateWidthByPercent(escapeHtml(node.size.width))}"
        style="
          display: 'block';
          maxWidth: '100%';
          width: '${caculateWidthByPercent(escapeHtml(node.size.width))}';
          objectFit:'contain';
          maxHeight: '20em';
        "
      /></div>`
    default:
      return children
  }
}

const getImageClassName = (align) => {
  switch(align){
    case 'left':
      return "img-left"
    case 'right':
      return "img-right"
    default: 
      return "img-center"
  }
}

const SlateEditor = React.forwardRef((props, ref) => {
  const { onChangeHtmlContent = () => {}} = props;
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])
  const editor = useMemo(() => withImages(withHtml(withHistory(withReact(createEditor())))), [])
  const renderElement = useCallback(props => <Element {...props}  editor = {editor}/>, [])
  const editfield = useRef()

  const uploadModal = useRef(null)

  const [htmlContent, set_htmlContent] = useState('')
  const [editorValue, set_editorValue] = useState(initialValue)


  useImperativeHandle(ref, () => {
    return {
      getHtml() {
        return htmlContent
      },
      parseHtml (html){
        let content = deserializeHtml(html, clientWidth);
        set_htmlContent(html)
        set_editorValue(content)
        editor.children = content
      },
      reset () {
        Transforms.delete(editor, {
          at: {
            anchor: Editor.start(editor, []),
            focus: Editor.end(editor, []),
          },
        });
      }
    };
  }, [htmlContent, editor]);

  useEffect(() => {
    clientWidth = editfield.current.clientWidth - 40; //trừ đi khoảng padding
  }, [editfield])

  const onChange = (value) => {
    // When the document changes, save the serialized HTML to Local Storage.
    let string = serialize(value).join('');
    console.log('html', string);
    set_htmlContent(string)
    onChangeHtmlContent(string)
  }

  //render cac button áp dụng cho nguyên dòng các dòng text đánh dấu
  const BlockButton = ({ format, icon, active_icon }) => {
    const editor = useSlate()
    let active = isBlockActive(
      editor,
      format,
      TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
    )
    return (
      <Button
        onClick={event => {
          event.preventDefault()
          toggleBlock(editor, format)
        }}
        type='text'
        className={editorCss['editorButton']}
        style={{ color: active ? Colors.black : Colors.faded_icon }}
      >
         {(!!active_icon && active) ? active_icon : icon}
      </Button>
    )
  }

  //render cac button áp dụng cho phần text đánh dấu
  const MarkButton = ({ format, icon, active_icon }) => {
    const editor = useSlate()
    let active = isMarkActive(editor, format)
    return <Button
      onClick={event => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
      type='text'
      className={editorCss['editorButton']}
      style={{ color: active ? Colors.black : Colors.faded_icon }}>
      {(!!active_icon && active) ? active_icon : icon}
    </Button>
  }

  //render button upload
  const UploadButton = (event) => {
    return <Button
      onClick={event => {
        event.preventDefault()
        // const url = window.prompt('Enter the URL of the image:')
        // if (url && !isImageUrl(url)) {
        //   alert('URL is not an image')
        //   return
        // }
        // url && insertImage(editor, url)
        uploadModal.current.open()
      }}
      type='text'
      className={editorCss['editorButton']}
      style={{ color: Colors.faded_icon }}>
      <PictureOutlined />
    </Button>
  }


  const insertImageFile = (dataFile) => {
    dataFile.map(file => {
      let imageUrl = apis.Post_Image_Get + file.response.localPath;
      let id = file.response.id;
      insertImage(editor, imageUrl, id);
      return file
    });
  }

  return (
    <div style={{ minWidth: '20vw' }} className={editorCss['container']} {...props}>
      <Slate editor={editor} onChange={onChange} value={editorValue}>
        <div className={editorCss['toolBarContainer']}>
          <div className={editorCss['toolBar']}>
            <MarkButton icon={<BoldOutlined />} format="bold" />
            <MarkButton icon={<ItalicOutlined />} format="italic" />
            <MarkButton icon={<UnderlineOutlined />} format="underline" />
            <MarkButton icon={<CodeOutlined />} format="code" />
            {/* <BlockButton format="block-quote" icon="format_quote" />
        <BlockButton format="numbered-list" icon="format_list_numbered" />
        <BlockButton format="bulleted-list" icon="format_list_bulleted" />  */}
            <UploadButton />
            <BlockButton format="left" icon={<AlignLeftOutlined />} />
            <BlockButton format="center" icon={<AlignCenterOutlined />} />
            <BlockButton format="right" icon={<AlignRightOutlined />} />
            <BlockButton format="justify" icon={<TextJustifyIcon fill = 'black' />} />
          </div>
          <div className={editorCss['divider']} />
        </div>
        <div ref = {editfield}>
          
        <Editable
          value={editorValue}
          
          className={editorCss['editorContainer']}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder={Translate(languageKeys.Slate_Placeholder)}
          spellCheck
          autoFocus
          onKeyDown={event => {
            console.log(event);
            for (const hotkey in HOTKEYS) {
              if (isHotkey(hotkey, event)) {
                event.preventDefault()
                const mark = HOTKEYS[hotkey]
                toggleMark(editor, mark)
              }
            }
          }}
        />
        
        </div>
      </Slate>
      <UploadModal ref={uploadModal} maxCount = {6}  dataUploadSuccess={(dataFile) => { insertImageFile(dataFile); }} />
    </div>
  )
});


const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  )
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  })
  let newProperties
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    }
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    }
  }
  Transforms.setNodes(editor, newProperties)
  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const isBlockActive = (editor, format, blockType = 'type') => {
  const { selection } = editor
  if (!selection) return false

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format,
    })
  )

  return !!match
}

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

const Element = (props) => {
  const editor = useSlate()
  let { attributes, children, element } = props;
  const style = { textAlign: element.align }
  switch (element.type) {
    case 'block-quote':
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      )
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      )
    case 'heading-one':
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      )
    case 'heading-two':
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      )
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      )
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      )
    case 'link':
      return (
        <a href={element.url} {...attributes}>
          {children}
        </a>
      )
    case 'image':
      return <ImageElement {...props}  onChangeSize = {(size) => onChangeImageElement(size, editor)}/>
    default:
      return (
        <div style={style} {...attributes}>
          {children}
        </div>
      )
  }
}

// thay đổi size image
const onChangeImageElement = (size, editor) => {
  Transforms.setNodes(editor, {size})
}

const caculateWidthByPercent = ( width) => {
  if(!!width){
    let percent =width;
    if(typeof width === 'number'){
      percent = Math.round((width / clientWidth) * 100)
    }else if(typeof width === 'string' ){
      if(width.includes('%')){
        return percent
      }
      percent = Math.round((Number(width) / clientWidth) * 100)
    }
    return percent + '%'
  }
  return '50%'
}

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  return <span {...attributes}>{children}</span>
}

const withImages = editor => {
  const { insertData, isVoid } = editor

  editor.isVoid = element => {
    return element.type === 'image' ? true : isVoid(element)
  }

  editor.insertData = data => {
    const text = data.getData('text/plain')
    const { files } = data

    if (files && files.length > 0) {
      for (const file of files) {
        const reader = new FileReader()
        const [mime] = file.type.split('/')

        if (mime === 'image') {
          reader.addEventListener('load', () => {
            const url = reader.result
            insertImage(editor, url)
          })

          reader.readAsDataURL(file)
        }
      }
    } else if (isImageUrl(text)) {
      insertImage(editor, text)
    } else {
      insertData(data)
    }
  }

  return editor
}


const isImageUrl = url => {
  if (!url) return false
  if (!isUrl(url)) return false
  const ext = new URL(url).pathname.split('.').pop()
  return imageExtensions.includes(ext)
}

const insertImage = (editor, url, id = '') => {
  const text = { text: '' }
  const image = { type: 'image', url, id, size : {width: clientWidth/2}, children: [text] }

  Transforms.insertNodes(editor, image)
  Transforms.insertNodes(editor, {
    type: 'paragraph',
    children: [
      { text: '' },
    ],
  })
}

const initialValue = [
  {
    type: 'paragraph',
    children: [
      { text: '' },
    ],
  },
]


const ELEMENT_TAGS = {
  A: el => ({ type: 'link', url: el.getAttribute('href') }),
  BLOCKQUOTE: () => ({ type: 'quote' }),
  H1: () => ({ type: 'heading-one' }),
  H2: () => ({ type: 'heading-two' }),
  H3: () => ({ type: 'heading-three' }),
  H4: () => ({ type: 'heading-four' }),
  H5: () => ({ type: 'heading-five' }),
  H6: () => ({ type: 'heading-six' }),
  IMG: el => ({ type: 'image', url: el.getAttribute('src') }),
  LI: () => ({ type: 'list-item' }),
  OL: () => ({ type: 'numbered-list' }),
  P: () => ({ type: 'paragraph' }),
  PRE: () => ({ type: 'code' }),
  UL: () => ({ type: 'bulleted-list' }),
}

// COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.
const TEXT_TAGS = {
  CODE: () => ({ code: true }),
  DEL: () => ({ strikethrough: true }),
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  S: () => ({ strikethrough: true }),
  STRONG: () => ({ bold: true }),
  U: () => ({ underline: true }),
}

export const deserialize = el => {
  if (el.nodeType === 3) {
    return el.textContent
  } else if (el.nodeType !== 1) {
    return null
  } else if (el.nodeName === 'BR') {
    return '\n'
  }

  const { nodeName } = el
  let parent = el

  if (
    nodeName === 'PRE' &&
    el.childNodes[0] &&
    el.childNodes[0].nodeName === 'CODE'
  ) {
    parent = el.childNodes[0]
  }
  let children = Array.from(parent.childNodes)
    .map(deserialize)
    .flat()

  if (children.length === 0) {
    children = [{ text: '' }]
  }

  if (el.nodeName === 'BODY') {
    return jsx('fragment', {}, children)
  }

  if (ELEMENT_TAGS[nodeName]) {
    const attrs = ELEMENT_TAGS[nodeName](el)
    return jsx('element', attrs, children)
  }

  if (TEXT_TAGS[nodeName]) {
    const attrs = TEXT_TAGS[nodeName](el)
    return children.map(child => jsx('text', attrs, child))
  }

  return children
}

const withHtml = editor => {
  const { insertData, isInline, isVoid } = editor

  editor.isInline = element => {
    return element.type === 'link' ? true : isInline(element)
  }

  editor.isVoid = element => {
    return element.type === 'image' ? true : isVoid(element)
  }

  editor.insertData = data => {
    const html = data.getData('text/html')

    if (html) {
      const parsed = new DOMParser().parseFromString(html, 'text/html')
      const fragment = deserialize(parsed.body)
      Transforms.insertFragment(editor, fragment)
      return
    }

    insertData(data)
  }

  return editor
}



export default SlateEditor

