import { Col, Row, Input, Modal } from "antd";
import React, { useCallback, useEffect } from 'react'
import { useImperativeHandle } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEmptyObject } from "../../../../helpers";
import { languageKeys, Translate } from "../../../../i18n";
import editContactModal from './editContactModal.module.less'
import { displayMessage } from '../../../../ducks/slices/appSlice';

const EditContactModal = React.forwardRef((props, ref) => {
  let { dataChangeHandle = () => {}, onClose = () => {}, currentContact = {}} = props;

  const [editContact, set_editContact] = useState(currentContact)
  const [visible, set_visible] = useState(false)
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (!visible && !isEmptyObject(currentContact)){
  //     set_visible(true);
  //     set_editContact(currentContact);
  //   } 
  // }, [currentContact, visible])

  
  const initEdit = useCallback((data) => {
    set_editContact(data);
    set_visible(true);
  }, set_editContact, set_visible)

  useImperativeHandle(ref, () => {
    return {
      open() {
        set_visible(true)
      },
      close() {
        set_visible(false)
      },
      openEdit(data) {
        console.log('init data', data);
        initEdit(data)
      }
    };
  }, [initEdit]);


  const isValidContact = () => {
    if (!editContact.contactName){
      dispatch(displayMessage({
        type: 'error',
        content: Translate(languageKeys.Api_Message_Require_ContactName),
      }));
      return false
    }
    if (!editContact.contactPhone){
      dispatch(displayMessage ({
        type: 'error',
        content: Translate(languageKeys.Api_Message_Require_ContactPhone),
      }));
      return false
    }
    if (!editContact.email){
      dispatch(displayMessage({
        type: 'error',
        content: Translate(languageKeys.Api_Message_Require_ContactEmail),
      }));
      return false
    }
    return true;
  }

  const confirmUpdate = () => {
    if (!isValidContact()) return;

    dataChangeHandle(editContact)
    onClose();
    set_visible(false)
  }

  return <Modal
    title={Translate(languageKeys.Product_Contact)}
    open={visible}
    okText = {Translate(languageKeys.Admin_Product_Button_Save)}
    cancelText = {Translate(languageKeys.Common_Cancel)}
    onOk={() => {confirmUpdate()}}
    onCancel={() => {set_visible(false); onClose()}}
    >
      <Row wrap={'wrap'} justify={'space-between'} gutter={[20, 20]} className={editContactModal["contact-input-container"]}>
          <Col span={20}>
            <div className={editContactModal["product-input"]}>
              <div className={editContactModal["product-input-title"]}>{Translate(languageKeys.Common_Name ) + " *"}</div>
              <Input onChange={(event) => set_editContact({ ...editContact, contactName: event.target.value })} value={editContact.contactName}/>
            </div>
          </Col>
          <Col span={20}>
            <div className={editContactModal["product-input"]}>
              <div className={editContactModal["product-input-title"]}>{Translate(languageKeys.Common_Phone ) + " *"}</div>
              <Input onChange={(event) => set_editContact({ ...editContact, contactPhone: event.target.value })} value={editContact.contactPhone}/>
            </div>
          </Col>
          <Col span={20}>
            <div className={editContactModal["product-input"]}>
              <div className={editContactModal["product-input-title"]}>{Translate(languageKeys.Common_Email ) + " *"}</div>
              <Input onChange={(event) => set_editContact({ ...editContact, email: event.target.value })} value={editContact.email}/>
            </div>
          </Col>
          <Col span={20}>
            <div className={editContactModal["product-input"]}>
              <div className={editContactModal["product-input-title"]}>Zalo:</div>
              <Input onChange={(event) => set_editContact({ ...editContact, zaloPhone: event.target.value })} value={editContact.zaloPhone}/>
            </div>
          </Col>
      </Row>
    </Modal>

})

export default EditContactModal;