import configs from "./configs";
const apis = {
    Post_Image_Upload: configs.Domain + 'upload',
    Post_Product_Create: configs.Domain + 'create-product',
    Post_Image_Get: configs.Domain + 'images/',

    Post_Login: configs.Domain + 'login',
    Post_Product_Categories_Get: configs.Domain + 'get-product-categories',
    Post_Product_Categories_Update: configs.Domain + 'update-category',
    
    Get_Product_Categories: configs.Domain + 'get-product-categories',
    Get_Product_List: configs.Domain + 'get-products',
    Get_Product: configs.Domain + 'product-detail',

    Post_News_CreateNews: configs.Domain + 'create-news',
    Post_News_UpdateProperties: configs.Domain + 'hide-news',
    Get_News_Detail: configs.Domain + 'detail-news?id=',
    Get_News_List: configs.Domain + 'admin/find-news',
    Post_News_Delete : configs.Domain + 'delete-news',
    Post_Home_Product_List : configs.Domain + 'home/get-products?lang=',

    Home_Get_News: configs.Domain + 'home/get-news/',

    Recuitement_Post_UpdateRecuitment: configs.Domain + 'create-recruitment',
    Recuitement_Get_GetRecuitment: configs.Domain + 'find-recruitment',

    Delete_Product: configs.Domain + 'delete-product',
    
};

export default apis;
