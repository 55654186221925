import { Layout } from "antd";
import { useEffect, useState } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { paths } from "../constants";
import { Header, MainSider } from "../layouts/main";
import { NotFound } from "../pages/common";
import { ScrollToTop } from "../hooks";
import { AboutUs, ContactUs, HomePage, ProductList, Recruitment, Site, Project, Introduction, Product, NewsDetail } from "../pages/main";
import Footer from "../layouts/common/Footer/Footer";
import { SmallUp } from "../assets/svg";
import '../assets/style/mixins.less'
import { VCard } from "../pages/admin";

const DefaultRoute = (props) => {
  const [collapsed, setCollapsed] = useState(true);

  const [scrollHeight, setHeight] = useState(0)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    // return 
    //   window.removeEventListener('scroll', handleScroll)
  },
    [])


  const handleScroll = (event) => {
    setHeight(window.scrollY)
  }

  return (
    <>
      <ScrollToTop />
      <Layout>
        <MainSider collapsed={collapsed}  onPressCollapse={() => setCollapsed(true)}/>
        <Layout>
          <Header
            collapsedSider={collapsed}
            onPressCollapse={() => setCollapsed((val) => !val)}
          />
          <Layout style={{ background: '#fff' }}>
            <Layout.Content className={'body-container'}>
              <Switch>
                <Route
                  exact
                  path={paths.home_page}
                  component={HomePage}
                />

                <Route
                  exact
                  path={paths.vcard}
                  component={VCard}
                />

                <Route
                  exact
                  path={paths.vcard_wId}
                  component={VCard}
                />

                <Redirect exact from={paths.main} to={paths.home_page} />
                <Redirect exact from={paths.home_page_route} to={paths.home_page} />
                {/* <Redirect exact from={'/'} to={paths.vcard} /> */}
                <Route component={NotFound} />
              </Switch>
            </Layout.Content>
            <Footer/>
              
          </Layout>
        </Layout>
      </Layout>
      {scrollHeight > 500 && <div onClick={() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth' // for smoothly scrolling
        });
      }}
        className='scrollToTop' >
        <div className='svg-container' style={{ width: '88%', height: '88%' }}>
          <SmallUp className='svg' />
        </div>
      </div>}
    </>
  );
};

export default withRouter(DefaultRoute);
