import { createSlice } from "@reduxjs/toolkit";
import { getLanguage } from "../../helpers";

const initState = {
  isLoading: false,
  message: '',
  currentLanguage: getLanguage()
};

const appSlice = createSlice({
  name: "app",
  initialState: initState,
  reducers: {

    appLoading(state){
      return {...state, isLoading: true}
    },
    appLoadingDone(state){
      return {...state, isLoading: false}
    },

    displayMessage (state, {payload}){
      return {...state, message: payload}
    },

    resetMessage (state, payload){
      return {...state, message:''}
    },

    setLanguage (state, payload){
      return {...state, currentLanguage:payload.language}
    }

  },
});

export const { appLoading, appLoadingDone, displayMessage, resetMessage, setLanguage } =
  appSlice.actions;

export default appSlice.reducer;
