// import { SettingOutlined } from "@ant-design/icons";
import { Button, Col, Layout, Row, Dropdown } from "antd";
import header from "./header.module.less";
import { Link, useLocation } from "react-router-dom";
import { paths } from "../../../constants";
import cn from "classnames";
import { useEffect, useState } from "react";
import i18n, { languageKeys, languages, Translate } from "../../../i18n";
import { locale } from "moment/moment";
import { NsmvLogoImg, jap_flag, us_flag, vietnam_flag } from "../../../assets/img";
import { en, jp, vi } from "../../../i18n/languages";
import { setLanguage } from "../../../ducks/slices/appSlice";
import { useDispatch } from "react-redux";
import { MenuFoldOutlined, MenuOutlined } from "@ant-design/icons";
const Header = ({
  children,
  collapsedSider,
  onPressCollapse = () => {}
}) => {
  const location = useLocation()
  const dispatch = useDispatch()

  const [scrollHeight, setHeight] = useState(0)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  },
    [])


  const handleScroll = (event) => {
    setHeight(window.scrollY)
  }

  // lấy text ngôn ngữ hiện tại dựa theo locale
  const getLanguageIcon = () => {
    switch (Translate(languageKeys.Language_label)) {
      case en.Language_label:
        return us_flag;
      case vi.Language_label:
        return vietnam_flag
      case jp.Language_label:
        return jap_flag
      default:
        return ""
    }
  }

  return (
    <Layout.Header
      className={cn(
        header["container"],
        scrollHeight > 200 && header["shrink"]
      )}
    >
      {!!children ? (
        children
      ) : (
        <>
          <div
            className={cn(header["wrapper"], header["right"])}
          > <Link to = {paths.home_page} style={{height: '100%'}}>
            <img src = {NsmvLogoImg} className={cn(header["img-contain"])} alt="logo"/>
          </Link>

          </div>

          <div
            className={cn(
              header["wrapper"],
              header["grow"]
            )}
          >  
            <Row gutter={[0, 40]} className={cn(
              header["content-wrapper"],
              header["grow"]
            )} align={scrollHeight > 200 ? 'middle' : 'bottom'} style={{ paddingBottom: scrollHeight > 200 ? 0 : 10 }}>
              
              {scrollHeight < 200 && <Col span={24}>
                <div className={header["language-button"]}>
                  <Row align={'middle'}>
                    <Dropdown
                      menu={{
                        items: languageProps,
                        selectable: true,
                        onSelect: (item) => {
                          locale(item.key)
                          i18n.changeLanguage(item.key);
                          window.location.reload(false)
                          dispatch(setLanguage({language: item.key}))
                        }
                      }}

                    >
                      <div className={header["dropdown-language"]}>
                        <Row align={'middle'} justify='space-around' style={{paddingRight: '10px', fontWeight:'600'}}>
                          <img src={getLanguageIcon()} alt='language_icon' className={header["img"]} width={"20px"} height={"20px"} style={{ marginRight: '10px', borderRadius: '25px' }} />
                          {Translate(languageKeys.Language_label)}
                        </Row>
                      </div>
                    </Dropdown>
                  </Row>
                </div>
              </Col>}
              <Col flex={'auto'}> 
                </Col>
              <Col  className={header["button-container"]}>
                <Link to = {paths.home_page}>
                <div
                  className={cn(header["button"], location.pathname.includes(paths.home_page) && header["select"])}>
                 {Translate(languageKeys.Header_Button_TopPage)}
                </div>
                </Link>
              </Col>
              <Col  className={header["button-container"]}>
                <Link to = {paths.introduction}>
                <div
                  className={cn(header["button"], location.pathname.includes(paths.introduction) && header["select"])}>
                  {Translate(languageKeys.Header_Button_Introduct)}
                </div>
                </Link>
              </Col>
              <Col  className={header["button-container"]}>
                <Link to = {paths.product_list}>
                <div
                  className={cn(header["button"], location.pathname.includes(paths.product_list) && header["select"])}>
                  {Translate(languageKeys.Header_Button_Products)}
                </div>
                </Link>
              </Col>
              <Col  className={cn(header["button-container"])}>
                <Link to = {paths.recruitment}>
                <div
                  className={cn(header["button"], location.pathname.includes(paths.recruitment) && header["select"])}>
                  {Translate(languageKeys.Header_Button_Recruitment)}
                </div>
                </Link>
              </Col>
              <Col className={cn(header["button-container"], header["contact"])}>
                <Link to = {paths.contact_us}>
                <Button className={cn(header["button"], header["contact"])} 
                type = 'primary'>
                  {Translate(languageKeys.Header_Button_Contact)}
                </Button>
                </Link>
              </Col>
            </Row>
            <div  className={header["button-menu"]} onClick={() => onPressCollapse()}>
              {collapsedSider? <MenuOutlined /> : <MenuFoldOutlined />}
            </div>
          </div>
        </>
      )}
    </Layout.Header>
  );
};

export default Header;

const languageProps = [
  {
    key: languages.tieng_anh,
    label: <Row align={'middle'} gutter={10} justify='start' style={{width: '120px'}}>
      <img src={us_flag} alt='language_en' className={header["img"]} width={"20px"} height={"20px"} style={{ marginRight: '20px', borderRadius: '25px' }} />
      English
    </Row>,
  },
  {
    key: languages.tieng_nhat,
    label: <Row align={'middle'} gutter={10} justify='start' style={{width: '120px'}}>
      <img src={jap_flag} alt='language_jp' className={header["img"]} width={"20px"} height={"20px"} style={{ marginRight: '20px', borderRadius: '25px' }} />
      日本語
    </Row>,
  },
  {
    key: languages.tieng_viet,
    label: <Row align={'middle'} gutter={10} justify='start' style={{width: '120px'}}>
      <img src={vietnam_flag} alt='language_vi' className={header["img"]} width={"20px"} height={"20px"} style={{ marginRight: '20px', borderRadius: '25px' }} />
      Tiếng Việt
    </Row>,
  },
];
