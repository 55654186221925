import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Image, Input, Row } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ImageAdd } from '../../../assets/svg';
import { apis, keys } from '../../../constants';
import Colors from '../../../constants/Colors';
import { appLoading, appLoadingDone, displayMessage } from '../../../ducks/slices/appSlice';
import { getImageUrl } from '../../../helpers';
import { languageKeys, Translate } from '../../../i18n';
import { UploadModal } from '../components';
import recuit from './recuit.module.less';

const RecuitmentAdmin = (props) => {
    const uploadModal = useRef();
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [introduction, set_introduction] = useState('')
    const [image, set_image] = useState('')
    const [officeImages, set_officeImages] = useState([])
    const [factoryImages, set_factoryImages] = useState([])
    const [uploadingCategory, set_uploadingCategory] = useState('')
    const [initData, set_initData] = useState({})
    const [intro_delete, set_intro_delete] = useState([])
    const [office_delete, set_office_delete] = useState([])
    const [factory_delete, set_factory_delete] = useState([])

    const [recuiteLink, set_recuiteLink] = useState([{
        referenceLink: '',
        iconLink: ''
    }])

    useEffect(() => {
        if (!!auth.token) {
            initDetail()
        }
    }, [auth])

    //initNewsDetail: lấy data của news để update
    const initDetail = async () => {
        let config = {
            headers: {
                Authorization: auth.token,

            },
        }
        let requestPath = apis.Recuitement_Get_GetRecuitment;
        dispatch(appLoading())
        await axios.get(requestPath, config)
            .then(response => {
                if (response.status === keys.STATUS_SUCCESS) {
                    let { data } = response;
                    if (data.status === keys.STATUS_SUCCESS && !!data.data) {
                        set_initData(data.data)
                        fillFormData(data.data)
                    }
                } else {
                    dispatch(displayMessage({
                        type: 'error',
                        content: Translate(languageKeys[`Api_Api_Message_${response.message}`]),
                    }))
                }
                dispatch(appLoadingDone())
            })
            .catch(err => {
                dispatch(appLoadingDone())
                dispatch(displayMessage({
                    type: 'error',
                    content: Translate(languageKeys.Api_Message_Error),
                }))

            })
    }

    const fillFormData = (data) => {
        set_recuiteLink(data?.link || []);
        set_factoryImages(data?.imageFactory || []);
        set_officeImages(data?.imageOffice || []);
        set_image(data.imageIntroduction.length > 0 ? data.imageIntroduction[0] : '')
        set_introduction(data?.introduction || '');
    }

    const onEditFormControl = ({ value }, control) => {
        switch (control) {
            case keys.Recuitment_FormControls.introduction:
                set_introduction(value)
                break;
            default: break;
        }
    }

    //thay doi anh thumbnail
    const onChangeImage = (data) => {
        if (!!data.length) {
            switch (uploadingCategory) {
                case 'general':
                    // let name = data[0].response.localPath;
                    // let imageUrl = apis.Post_Image_Get + name;
                    if (!!image.id) {
                        set_intro_delete([...intro_delete, image.id])
                    }
                    set_image(data[0])

                    break;
                case 'factory':
                    set_factoryImages([...factoryImages, ...data
                        // .map(item => {
                        // let name = item.response.localPath;
                        // return apis.Post_Image_Get + name;})
                    ])
                    break;
                case 'office':
                    set_officeImages([...officeImages, ...data
                        // .map(item => {
                        // let name = item.response.localPath;
                        // return apis.Post_Image_Get + name; })
                    ])
                    break;
                default: break;
            }


        }
    }


    const onAddImage = (type) => {
        set_uploadingCategory(type)
        uploadModal.current.open()
    }

    const onChangeLinkProperties = (value, type, itemIndex) => {
        let updateData = recuiteLink;
        updateData = updateData.map((item, index) => {
            if (index === itemIndex) {
                item[`${type}`] = value
            }
            return item
        })
        set_recuiteLink(updateData)
    }

    const addRecuitLinkForm = () => {
        set_recuiteLink([...recuiteLink, { referenceLink: '', iconLink: '' }])
    }

    const onPressSubmitForm = async () => {
        let formData = new FormData()
        let requestBody = {}
        requestBody = {
            id: initData.id,
            introduction,
            link: recuiteLink,
            intro_delete,
            office_delete,
            factory_delete,
        }
        if (!image.localPath) {
            requestBody.intro_delete = initData.imageIntroduction.map(item => item.id)
            formData.append('introduction_file', image.originFileObj)
        }
        const blob = new Blob([JSON.stringify(requestBody)], {
            type: 'application/json'
        });
        formData.append('recruitment', blob)
        factoryImages.forEach(item => {
            if (!item.localPath) {
                formData.append('factory_file', item.originFileObj)
            }
        })
        officeImages.forEach(item => {
            if (!item.localPath) {
                formData.append('office_file', item.originFileObj)
            }
        })

        let config = {
            headers: {
                Authorization: auth.token,

            },
        }
        dispatch(appLoading())
        await axios.post(apis.Recuitement_Post_UpdateRecuitment, formData, config)
            .then(response => {
                if (response.status === keys.STATUS_SUCCESS) {
                    let { data } = response;
                    if (data.status === keys.STATUS_SUCCESS &&
                        (data.message === keys.API_RESPONSE_MESSAGE_REGISTED ||
                            data.message === keys.API_RESPONSE_MESSAGE_UPDATED)) {
                        dispatch(displayMessage({
                            type: 'success',
                            content: Translate(languageKeys.Api_Message_OK),
                        }))
                    } else {
                        dispatch(displayMessage({
                            type: 'error',
                            content: Translate(languageKeys.Api_Message_Error),
                        }))
                    }
                } else {
                    dispatch(displayMessage({
                        type: 'error',
                        content: Translate(languageKeys.Api_Message_Error),
                    }))
                }
                dispatch(appLoadingDone())
            })
            .catch(err => {
                dispatch(appLoadingDone())
                dispatch(displayMessage({
                    type: 'error',
                    content: Translate(languageKeys.Api_Message_Error),
                }))

            })
    }

    return <div className={recuit['container']}>
        <Row gutter={50}>
            <Col span={15}>
                <div className={recuit['form-container']}>
                    <div className={recuit['form-title']}>{Translate(languageKeys.Admin_Recuitment_Title_Intro)}</div>
                    <Input.TextArea required style={{ flex: 1, overflow: 'auto' }}
                        value={introduction}
                        onChange={({ target }) => onEditFormControl(target, keys.Recuitment_FormControls.introduction)} />
                </div>
            </Col>
            <Col span={9}>
                <div
                    className={recuit['image-container']}
                    onClick={() => !image && onAddImage('general')}>
                    {!!image ?
                        <Image src={image.localPath ? getImageUrl(image.localPath) : image.url} className={recuit['img-contain']} width={'100%'} height={'100%'} />
                        : <ImageAdd width={240} height={220} />}
                    {!!image && <div
                        className={recuit['change-image']}
                        onClick={() => onAddImage('general')}>

                        <ImageAdd width={40} height={40} />
                    </div>}
                </div>
            </Col>
        </Row>
        <Row gutter={50}>
            <Col span={9}>
                <div className={recuit['form-title']}>{Translate(languageKeys.Admin_Recuitment_Title_FactoryImages)}</div>
                <Row gutter={[15, 15]} className={recuit['showcase-container']}>
                    {factoryImages.map((item, index) => <Col span={8} key={index}>
                        <div className={recuit['image-showcase']} key={index}>
                            <Image src={item.localPath ? getImageUrl(item.localThumbnailPath) : item.url} className={recuit['img-cover']} width={'100%'} height={'100%'} />
                            <div className={recuit['deleteBadge']} onClick={() => {
                                let new_ls = [...factoryImages];
                                new_ls.splice(index, 1)
                                set_factoryImages(new_ls)
                                if (!!item.id) {
                                    set_factory_delete([...factory_delete, item.id])
                                }
                            }}>
                                <DeleteOutlined style={{ color: '#fff' }} />
                            </div>
                        </div>
                    </Col>)}
                    <Col span={8}>
                        <div className={recuit['image-showcase']} onClick={() => onAddImage('factory')}>
                            <ImageAdd width={100} height={100} />
                        </div>
                    </Col>
                </Row>
            </Col>
            <Col span={6}></Col>
            <Col span={9}>
                <div className={recuit['form-title']}>{Translate(languageKeys.Admin_Recuitment_Title_OfficeImages)}</div>
                <Row gutter={[15, 15]} className={recuit['showcase-container']}>
                    {officeImages.map((item, index) => <Col span={8} key={index}>
                        <div className={recuit['image-showcase']} key={index}>
                            <Image src={item.localPath ? getImageUrl(item.localThumbnailPath) : item.url} className={recuit['img-cover']} width={'100%'} height={'100%'} />
                            <div className={recuit['deleteBadge']} onClick={() => {
                                let new_ls = [...officeImages];
                                new_ls.splice(index, 1)
                                set_officeImages(new_ls)
                                if (!!item.id) {
                                    set_office_delete([...office_delete, item.id])
                                }
                            }}>
                                <DeleteOutlined style={{ color: '#fff' }} />
                            </div>
                        </div>
                    </Col>)}
                    <Col span={8}>
                        <div className={recuit['image-showcase']} onClick={() => onAddImage('office')}>
                            <ImageAdd width={100} height={100} />
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>


        <Row align={'middle'} className={recuit['form-title']}>
            <Col><div className={recuit['recuit-title']}>{Translate(languageKeys.Admin_Recuitment_Title_RecruitmentLinks)}</div></Col>
            <Col><div className={recuit['add-link-button']} type='primary' onClick={addRecuitLinkForm}><PlusOutlined style={{ color: Colors.white, fontSize: '18px' }} /></div></Col>
        </Row>
        <Row gutter={[20, 20]}>
            {recuiteLink.map((item, index) => <Col span={12} key={index}> <Row className={recuit['recuite-link-form']}>
                <div className={recuit['form-sub-title']}>{Translate(languageKeys.Admin_Recuitment_Title_Link)}</div>
                <Col span={24}>
                    <Input value={item.referenceLink} onChange={({ target }) => onChangeLinkProperties(target.value, 'referenceLink', index)}
                        placeholder={Translate(languageKeys.Admin_Recuitment_PlaceholderLink)} />
                </Col>
                {/* <div className={recuit['form-sub-title']}>Icon link</div>
                <Col span={24}><Input value={item.iconLink} onChange={({ target }) => onChangeLinkProperties(target.value, 'iconLink', index)} /></Col> */}
                {recuiteLink.length > 1 && <div className={recuit['deleteBadge']} onClick={() => {
                    let new_ls = [...recuiteLink];
                    new_ls.splice(index, 1)
                    set_recuiteLink(new_ls)
                }}>
                    <DeleteOutlined style={{ color: '#fff' }} />
                </div>}
            </Row></Col>)}
        </Row>
        <Row gutter={20} style={{ marginTop: '20px', paddingLeft: '10px' }}>
            <Button className={recuit['button']} type='primary' onClick={onPressSubmitForm}>{Translate(languageKeys.Admin_Recuitment_Button_Save)}</Button>
        </Row>
        <UploadModal ref={uploadModal} dataUploadSuccess={onChangeImage} maxCount={10} noUpload={true} />
    </div>
}

export default RecuitmentAdmin;