import { Button, Col, Input, Row } from "antd";
import React, { useRef } from "react";
import cn from "classnames";
import contactUs from "./ContactUs.module.less";
import { getImageUrl } from "../../../helpers";
import { languageKeys, Translate } from "../../../i18n";
import { keys, paths } from "../../../constants";
import { useState } from "react";
import emailjs from '@emailjs/browser';
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { appLoading, appLoadingDone, displayMessage } from "../../../ducks/slices/appSlice";


const ContactUs = () => {
  const emailForm = useRef();
  const dispatch = useDispatch()
  const history = useHistory()

  const [subject, set_subject] = useState('')
  const [name, set_name] = useState('')
  const [email, set_email] = useState('')
  const [phone, set_phone] = useState('')
  const [message, set_message] = useState('')
  const [submited, set_submited] = useState(false)


  const resetForm = () => {
    set_subject('')
    set_name('')
    set_email('')
    set_phone('')
    set_message('')
  }

  const onClickSendmail = (anything) => {
    set_submited(true)
    if(!!subject.trim() && !!name.trim() && !!phone.trim() && !!email.trim()){
      dispatch(appLoading())
          emailjs.sendForm(keys.mailServiceKey, keys.mailTemplateKey, emailForm.current, keys.mailApiKey)
           .then((result) => {
            // history.push(paths.home_page)
            dispatch(appLoadingDone())
            dispatch(displayMessage({
              type: 'success',
              content: Translate(languageKeys.Contact_MailForm_Success_Message),
            }));
               // show the user a success message
           }, (error) => {
            dispatch(appLoadingDone())
            dispatch(displayMessage({
              type: 'error',
              content: Translate(languageKeys.Api_Message_Error),
            }));
               // show the user an error
           });
           set_submited(false)
           resetForm()
    }
  }


  return (
    <div className={contactUs["container"]}>
      <div className={contactUs["banner-container"]}>
        <img src={getImageUrl(keys.banner_contactUs)} className={contactUs["img-contain"]}  alt='banner'/>
        <div className={contactUs["banner-content"]}>
          <div className={contactUs["content-title"]}>{Translate(languageKeys.Contact_Banner_title)} </div>
        </div>
      </div>
      <div className={contactUs["content-container"]}>
        <div className={contactUs["block-content"]}>
          <Row wrap='no-wrap' justify={'space-between'} >
            <Col span={9} style={{ paddingRight: '20px' }} >
              <div className={contactUs["location-content"]}>
              <div className={cn(contactUs["location-block"],contactUs["left"])}>
              <div className={contactUs["location-title"]}>
                {Translate(languageKeys.Contact_BnOffice)}
              </div>
              <div className={contactUs["location-body"]}>
                {Translate(languageKeys.Common_BNCommpany_Address)}<br />
                {Translate(languageKeys.Common_Tel)} 0222 3617568 <br />
                {Translate(languageKeys.Common_Fax)} 0222 361 7567 <br />
                Email: contact@nsmv.com.vn <br />
              </div>
              </div>
              <div className={contactUs["location-block"]}>
              <div className={contactUs["location-title"]}>
                {Translate(languageKeys.Contact_HnOffice)}
              </div>
              <div className={contactUs["location-body"]} style={{ marginBottom: '100px' }}>
                {Translate(languageKeys.Common_HNCommpany_Address)}<br />
                {Translate(languageKeys.Common_Tel)} 0243 227 2083 <br />
                Email: contact@nsmv.com.vn <br />
              </div>
              </div>
              </div>
            </Col>

            <Col span={15}>
              <div className={contactUs["contact-form"]}>
                <div className={contactUs["contact-title"]} >
                  {Translate(languageKeys.Contact_ContactUs)}
                </div>
                <div className={contactUs["contact-body"]}>
                  {Translate(languageKeys.Contact_Content)}
                </div>
                <form action="/" ref = {emailForm}  onSubmit={onClickSendmail} >
                  <Input name="subject" placeholder={Translate(languageKeys.Common_Subject) + " *"} onInvalid={e => e.target.setCustomValidity(' ')} onInput={e => e.target.setCustomValidity(' ')}    value={subject} onChange={({target}) => set_subject(target.value)}  className={cn(contactUs["input"], contactUs["required"])} required/>
                  {submited && subject.trim() === '' && <div style = {{color: 'red'}}>{Translate(languageKeys.Contact_MailForm_Error_Subject)}</div>}
                  <Input name="name" placeholder={Translate(languageKeys.Common_Name) + " *"} onInvalid={e => e.target.setCustomValidity(' ')} onInput={e => e.target.setCustomValidity(' ')}    value={name} onChange={({target}) => set_name(target.value)}  className={cn(contactUs["input"], contactUs["required"])} required  />
                  {submited && name.trim() === '' && <div style = {{color: 'red'}}>{Translate(languageKeys.Contact_MailForm_Error_Name)}</div>}
                  <Input name="email" placeholder={Translate(languageKeys.Common_Email) + " *"} onInvalid={e => e.target.setCustomValidity(' ')} onInput={e => e.target.setCustomValidity(' ')}    value={email} onChange={({target}) => set_email(target.value)} className={cn(contactUs["input"], contactUs["required"])} required />
                  {submited && email.trim() === '' && <div style = {{color: 'red'}}>{Translate(languageKeys.Contact_MailForm_Error_Email)}</div>}
                  <Input name="phone" placeholder={Translate(languageKeys.Common_Phone) + " *"} onInvalid={e => e.target.setCustomValidity(' ')} onInput={e => e.target.setCustomValidity(' ')}     value={phone} onChange={({target}) => set_phone(target.value)} className={cn(contactUs["input"], contactUs["required"])} required />
                  {submited && phone.trim() === '' && <div style = {{color: 'red'}}>{Translate(languageKeys.Contact_MailForm_Error_Phone)}</div>}
                  <Input name="message" placeholder={Translate(languageKeys.Common_Message)}  value={message} onChange={({target}) => set_message(target.value)} className={cn(contactUs["input"], contactUs["required"])} />
                  <Button className={contactUs["button"]} onClick={() => onClickSendmail()} > {Translate(languageKeys.Contact_Submit)}</Button>
                </form>
              </div>
            </Col>
          </Row>
        </div>
      </div>

    </div>
  );
};

export default ContactUs;
