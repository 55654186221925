import { DeleteOutlined, DownloadOutlined, RedoOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, Image, Input, Row } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ImageAdd } from '../../../assets/svg';
import { apis, keys } from '../../../constants';
import Colors from '../../../constants/Colors';
import { appLoading, appLoadingDone, displayMessage } from '../../../ducks/slices/appSlice';
import { getImageUrl } from '../../../helpers';
import { languageKeys, Translate } from '../../../i18n';
import { UploadModal } from '../components';
import vcard from './vcard.module.less';
import { logoNSMV, vcardBg, vcardBg2 } from '../../../assets/banner';
import cn from "classnames";
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import QRCode from 'react-qr-code';
import * as htmlToImage from 'html-to-image';
import CreateVCard from 'vcard-creator';
import { card_bacTong_back, card_bacTong_front, card_anhLuan_back, card_anhLuan_front, card_anhHieu_front, card_anhHieu_back, card_chiHuyen_front, card_chiHuyen_back, card_kobayashi_soki_front, card_kobayashi_soki_back, card_Yamada_Atsushi_front, card_Yamada_Atsushi_back } from '../../../assets/img';
import { t } from 'i18next';

const VCard = (props) => {

    const [flip, set_flip] = useState(true);
    const [cardInfo, set_cardInfo] = useState({})
    const cardRef = useRef();
    const [QRHeight , set_QRHeight] = useState('3vw')

    const { id } = useParams();

    useEffect(() => {
        console.log(id);
        let card = listDemoCard.find(item => item.id === id);
        console.log('card info', card);
        set_cardInfo(!!card ? card : {})
    }, [id])

    const flipCard = () => {
        set_flip(!flip)
    }

    const downloadCardImage = async () => {
        let ref = flip ? 'cardBack' : 'cardFront';
        await htmlToImage.toPng(document.getElementById(ref))
        .then(function (dataUrl) {
            const link = document.createElement('a');
            link.download = "vcard.png";
            link.href = dataUrl;
            link.click();
    
        });
    }

    const downloadVCFCard = async () => {
        var vCardsJS = require("vcards-js");
     
        var vcard = vCardsJS()
        vcard.firstName = cardInfo.firstName;
        vcard.lastName = cardInfo.lastName;
        vcard.organization = "Nippon Steel Metal Products VietNam";
        vcard.cellPhone = cardInfo.cellPhone;
        vcard.workPhone = cardInfo.workPhone;
        vcard.title = cardInfo.title;
        vcard.email = cardInfo.email;
        vcard.url = 'http://www.nsmv.com.vn';
        vcard.workFax = cardInfo.workFax;
        vcard.workAddress = cardInfo.workAddress;

        // vcard.saveToFile(`./${cardInfo.firstName} ${cardInfo.lastName}.vcf`)

        //get as formatted string
        // console.log(vCard.getFormattedString());
        return vcard.getFormattedString();
    }

    const downloadFile = vcfText => {
        const element = document.createElement("a");
        const file = new Blob([vcfText], { type: "text/vcard;charset=utf-8" });
        element.href = URL.createObjectURL(file);
        element.download = `${cardInfo.firstName} ${cardInfo.lastName}.vcf`;
        document.body.appendChild(element);
        element.click();
      };

    const createVCard  = () => {
        const myVCard = new CreateVCard();

        myVCard.addName (cardInfo.firstName, cardInfo.lastName)
        .addCompany("Nippon Steel Metal Products VietNam")
        .addEmail(cardInfo.email)
        .addPhoneNumber(cardInfo.cellPhone)
        .addPhoneNumber(cardInfo.workPhone, 'type=WORK')
        .addPhoneNumber(cardInfo.workFax, 'type=FAX')
        .addRole(cardInfo.title)
        .addURL(cardInfo.url)
        .addAddress(cardInfo.workAddress)

        let output = myVCard.getOutput();

        output = output.replace

        return myVCard.getOutput()

    }

    const getQrHeight = () => {
        if(!!cardRef.current){
            console.log(cardRef.current.clientHeight);
            return cardRef.current.clientHeight*0.3
        }
        return '3vw'
    }


    return <div className={vcard['container']}>
        <div onClick={flipCard} className={cn(vcard['vcard-container'], flip ? vcard['flipped'] : '')} ref = {cardRef} >
            <div className={cn(vcard['front'])} id = {'cardFront'}>
            {!!cardInfo.image_back ? <img src={cardInfo.image_front}  style={{width: '100%', height: '100%', position: 'relative', borderRadius: '10px'}} /> :
            <div style={{width: '100%', height: '100%', position: 'relative', borderRadius: '10px', padding: '5%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}} 
                    className = {vcard['front-content']}>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row',  alignItems: 'center', marginLeft: '20px' }}>
                        <img src={logoNSMV}  className={cn(vcard['image-contain-height'])} style={{aspectRatio: 1/1, width: '4%'}}/>
                        <div style={{ fontWeight: 'bold', marginLeft: '20px'}} className={cn(vcard['card-title'])}>
                            NIPPON STEEL 
                        </div>
                    </div>
                    {!!cardInfo.title &&<div  style={{ marginTop: '2%', marginLeft: '20px'}} >{cardInfo.title}</div>}
                    {!!cardInfo.department &&<div  style={{ marginLeft: '20px'}}>{cardInfo.department}</div>}
                    {(!!cardInfo.firstName || !!cardInfo.lastName) && <div  className={vcard['name']}  style={{ fontWeight: 'bold',  marginLeft: '20px'}}>{`${cardInfo?.firstName || ''} ${cardInfo?.lastName || ''}`}</div>}

                    {(!!cardInfo.firstName || !!cardInfo.lastName) &&<div  className={vcard['company-title']}>NIPPON STEEL METAL PRODUCT VIETNAM CO., LTD</div>}
                    {!!cardInfo.workAddress &&<div>{t(languageKeys.Common_Address)} {cardInfo.workAddress}</div>}
                    {!!cardInfo.cellPhone &&<div>{t(languageKeys.Common_Mobile)}: {cardInfo.cellPhone}</div>}
                    {!!cardInfo.workPhone &&<div>{t(languageKeys.Common_Phone)}: {cardInfo.workPhone}</div>}
                    {!!cardInfo.workFax && <div>{t(languageKeys.Common_Fax)}: {cardInfo.workFax}</div>}
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    {!!cardInfo.email &&<div>Email: {cardInfo.email}</div>}
                    {!!cardInfo.url &&<div>Website: {cardInfo.url}</div>}
                    </div>
                    {(!!cardInfo.firstName || !!cardInfo.lastName ) && <div style={{ position: 'absolute', top: '5%', right: '5%', width: '20%' }} >{ !!cardInfo.image ? <div><img src={cardInfo.image} style={{width: '100%', objectFit: 'contain'}}/></div> : <QRCode value={window.location.href} size={getQrHeight()} />}</div>}
                </div>}

            </div>
            <div className={cn(vcard['back'])} id = {'cardBack'}>
                {!!cardInfo.image_back ? <img src={cardInfo.image_back}  style={{width: '100%', height: '100%', position: 'relative', borderRadius: '10px'}} /> :
                <div style={{width: '100%', height: '100%', position: 'relative', borderRadius: '10px', padding: '5%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}} 
                    className = {vcard['back-content']}>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row',  alignItems: 'center', marginLeft: '20px' }}>
                        <img src={logoNSMV}  className={cn(vcard['image-contain-height'])} style={{aspectRatio: 1/1, width: '4%'}}/>
                        <div style={{ fontWeight: 'bold', marginLeft: '20px'}} className={cn(vcard['card-title'])}>
                            NIPPON STEEL 
                        </div>
                    </div>
                    {!!cardInfo.title &&<div  style={{ marginTop: '2%', marginLeft: '20px'}} >{cardInfo.title}</div>}
                    {!!cardInfo.department &&<div  style={{ marginLeft: '20px'}}>{cardInfo.department}</div>}
                    {(!!cardInfo.firstName || !!cardInfo.lastName) && <div className={vcard['name']}  style={{ marginLeft: '20px'}}>{`${cardInfo?.firstName || ''} ${cardInfo?.lastName || ''}`}</div>}

                    {(!!cardInfo.firstName || !!cardInfo.lastName) &&<div className={vcard['company-title']}>NIPPON STEEL METAL PRODUCT VIETNAM CO., LTD</div>}
                    {!!cardInfo.workAddress &&<div>{t(languageKeys.Common_Address)} {cardInfo.workAddress}</div>}
                    {!!cardInfo.cellPhone &&<div>{t(languageKeys.Common_Mobile)}: {cardInfo.cellPhone}</div>}
                    {!!cardInfo.workPhone &&<div>{t(languageKeys.Common_Phone)}: {cardInfo.workPhone}</div>}
                    {!!cardInfo.workFax && <div>{t(languageKeys.Common_Fax)}: {cardInfo.workFax}</div>}
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    {!!cardInfo.email &&<div>Email: {cardInfo.email}</div>}
                    {!!cardInfo.url &&<div>Website: {cardInfo.url}</div>}
                    </div>
                    {(!!cardInfo.firstName || !!cardInfo.lastName) && <QRCode value={window.location.href} size={getQrHeight()} style={{ position: 'absolute', top: '5%', right: '5%' }} />}
                </div>
                }
            </div>
        </div>

        <Row style={{marginTop: '20px', width: '92%'}} justify='center' gutter={'20'} >
            {/* <Col span = {6} className={cn(vcard['button-container'])}>
                <div className={cn(vcard['button'])} onClick={downloadCardImage}>
                    <DownloadOutlined className={cn(vcard['button-icon'])} />
                </div>
            </Col> */}
            <Col className={cn(vcard['button-container'])}>
                <div className={cn(vcard['button'])} onClick={flipCard}>
                    <RedoOutlined className={cn(vcard['button-icon'])} />
                    Flip card
                </div>
            </Col>
            <Col className={cn(vcard['button-container'])}>
                <div className={cn(vcard['button'])} onClick={() => downloadFile(createVCard())}>
                    <SaveOutlined className={cn(vcard['button-icon'])} />
                    Save to Contact
                </div>
            </Col>
        </Row>

        <QRCode value={window.location.href} size={getQrHeight()} style={{ marginTop: '40px',  marginBottom: '40px' }} />
    </div>
}

export default VCard;

const listDemoCard = [
    {
        id : '9f0A9sdmZsadfm3dm2j56h56',
        firstName : "Ishikawa",
        lastName : "Takufumi",
        organization : "NIPPON STEEL METAL PRODUCT VIETNAM CO., LTD",
        cellPhone : "+84 91 584 2121",
        workPhone : "+84 222 3617 568",
        title : 'General Director',
        email : 'tak.ishikawa@nsmv.com.vn',
        workFax: '+84 222 3617 567',
        url : 'https://nsmv.com.vn',
        image: vcardBg2,
        workAddress : "17th Floor, ICON4 Building, 243A De La Thanh St., Lang Thuong Ward, Dong Da Dist., Hanoi, VietNam",
        image_front: card_bacTong_front,
        image_back: card_bacTong_back,
    },
    // {
    //     id : 'AStd87tASd7d87ASBVd878s7',
    //     firstName : "Nguyen",
    //     lastName : "Quang Vinh",
    //     organization : "NIPPON STEEL METAL PRODUCT VIETNAM CO., LTD",
    //     cellPhone : "+84 96 331 9296",
    //     workPhone : "+84 24 3227 2083",
    //     title : 'Team Leader - Technical Leader',
    //     email : 'vinh.nq@nsmv.com.vn',
    //     workFax: '',
    //     url : 'https://nsmv.com.vn',
    //     workAddress : "17th Floor, ICON4 Building, 243A De La Thanh St., Lang Thuong Ward, Dong Da Dist., Hanoi, VietNam",
    // },
    {
        id : 'ba98d6abs8d6t6tda76std7w',
        firstName : "Nguyen",
        lastName : "Son Hai",
        organization : "NIPPON STEEL METAL PRODUCT VIETNAM CO., LTD",
        cellPhone : "+84 97 245 0993",
        workPhone : "+84 24 3227 2083",
        title : 'Team Leader',
        email : 'hai.ns@nsmv.com.vn',
        workFax: '',
        url : 'https://nsmv.com.vn',
        workAddress : "17th Floor, ICON4 Building, 243A De La Thanh St., Lang Thuong Ward, Dong Da Dist., Hanoi, VietNam",
    },
    {
        id : '79dynf087Dfb976SDTbf76tb',
        firstName : "Nguyen",
        lastName : "Quang Thanh",
        organization : "NIPPON STEEL METAL PRODUCT VIETNAM CO., LTD",
        cellPhone : "+84 96 866 9759",
        workPhone : "+84 24 3227 2083",
        title : 'Project Manager',
        email : 'thanh.nq@nsmv.com.vn',
        workFax: '',
        url : 'https://nsmv.com.vn',
        workAddress : "17th Floor, ICON4 Building, 243A De La Thanh St., Lang Thuong Ward, Dong Da Dist., Hanoi, VietNam",
    },
    {
        id : '9vd3ASdv76RAvsd5raA5srdv',
        firstName : "Do",
        lastName : "Thi Tam",
        organization : "NIPPON STEEL METAL PRODUCT VIETNAM CO., LTD",
        cellPhone : "+84 38 912 8678",
        workPhone : "+84 24 3227 2083",
        title : 'Team Leader',
        email : 'tam.dt@nsmv.com.vn',
        workFax: '',
        url : 'https://nsmv.com.vn',
        workAddress : "17th Floor, ICON4 Building, 243A De La Thanh St., Lang Thuong Ward, Dong Da Dist., Hanoi, VietNam",
    },

    {
        id : '9vd3ASdv76Rf33gg4g5srdv',
        firstName : "Dao",
        lastName : "Van Phong",
        organization : "NIPPON STEEL METAL PRODUCT VIETNAM CO., LTD",
        cellPhone : "+84 382 294 144",
        workPhone : "+84 24 3227 2083",
        title : 'Team Leader',
        email : 'phong.dv@nsmv.com.vn',
        workFax: '',
        url : 'https://nsmv.com.vn',
        workAddress : "17th Floor, ICON4 Building, 243A De La Thanh St., Lang Thuong Ward, Dong Da Dist., Hanoi, VietNam",
    },
    {
        id : 'f32fdnd83ndydNS0d8y',
        firstName : "Kobayashi",
        lastName : "Soki",
        organization : "NIPPON STEEL METAL PRODUCT VIETNAM CO., LTD",
        cellPhone : "+84 373 041 638",
        workPhone : "+84 222 3617 568",
        title : 'Senior Manager',
        email : 's.kobayashi@nsmv.com.vn',
        workFax: '+84 222 3617 567',
        url : 'https://nsmv.com.vn',
        workAddress : "17th Floor, ICON4 Building, 243A De La Thanh St., Lang Thuong Ward, Dong Da Dist., Hanoi, VietNam",
        image_front: card_kobayashi_soki_front,
        image_back: card_kobayashi_soki_back,
    },


    {
        id : 'f31fdnd83ndydNS0d8y',
        firstName : "Yamada",
        lastName : "Atsushi",
        organization : "NIPPON STEEL METAL PRODUCT VIETNAM CO., LTD",
        cellPhone : "+84 392 611 158",
        workPhone : "+84 222 3617 568",
        title : 'Administrative Department',
        email : 'a.yamada@nsmv.com.vn',
        workFax: '+84 222 3617 567',
        url : 'https://nsmv.com.vn',
        workAddress : "17th Floor, ICON4 Building, 243A De La Thanh St., Lang Thuong Ward, Dong Da Dist., Hanoi, VietNam",
        image_front: card_Yamada_Atsushi_front,
        image_back: card_Yamada_Atsushi_back,
    },


    {
        id : 'f32nffna792f909a03va',
        firstName : "Le",
        lastName : "Xuan Luan",
        organization : "NIPPON STEEL METAL PRODUCT VIETNAM CO., LTD",
        cellPhone : "+84 968 853 131",
        workPhone : "+84 222 3617 568",
        title : 'Manager',
        email : 'luan.lx@nsmv.com.vn',
        workFax: "+84 222 3617 567",
        url : 'https://nsmv.com.vn',
        workAddress : "17th Floor, ICON4 Building, 243A De La Thanh St., Lang Thuong Ward, Dong Da Dist., Hanoi, VietNam",
        image_front: card_anhLuan_front,
        image_back: card_anhLuan_back,
    },
    {
        id : 'f32nffna7f2f23f9a03va',
        firstName : "Tran",
        lastName : "Xuan Hieu",
        organization : "NIPPON STEEL METAL PRODUCT VIETNAM CO., LTD",
        cellPhone : "+84 91 141 2122",
        workPhone : "+84 24 3227 2083",
        title : 'Chief Application Engineer',
        email : 'hieu.tx@nsmv.com.vn',
        workFax: "+84 222 3617 567",
        url : 'https://nsmv.com.vn',
        workAddress : "17th Floor, ICON4 Building, 243A De La Thanh St., Lang Thuong Ward, Dong Da Dist., Hanoi, VietNam",
        image_front: card_anhHieu_front,
        image_back: card_anhHieu_back,
    },

    {
        id : 'f32thhdb7921104a03va',
        firstName : "Nguyen",
        lastName : "Thu Huyen",
        organization : "NIPPON STEEL METAL PRODUCT VIETNAM CO., LTD",
        cellPhone : "+84 915 140 768",
        workPhone : "+84 243 2272 083",
        title : 'IT Division',
        email : 'huyen.nt@nsmv.com.vn',
        workFax: " ",
        url : 'https://nsmv.com.vn',
        workAddress : "17th Floor, ICON4 Building, 243A De La Thanh St., Lang Thuong Ward, Dong Da Dist., Hanoi, VietNam",
        image_front: card_chiHuyen_front,
        image_back: card_chiHuyen_back,
    }

]





