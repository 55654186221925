import {   banner_home1,
  banner_home2,
  banner_home3,
  home_introImage_site,
  home_introImage_aboutus,
  home_introImage_project,
  home_introImage_recruitment,
  banner_aboutus,
  banner_site,
  banner_projects,
  banner_product,
  banner_recruitment,
  banner_contactUs,
  site_location_ha_noi,
  site_location_bac_ninh,
  banner_home4,
  banner_home5,
  banner_home6}from '../assets/banner'
const keys = {
  enable_app_log: true,
  section: "SECTION",
  nsmvLogo: '20230322_170123_842.png',
  catalogLink: 'https://api.nsmv.com.vn/pdf/Catalogue.pdf',
  mailApiKey: '5lQZyVkBTFHED9CAx',
  mailServiceKey: 'service_kbndvqm',
  mailTemplateKey: 'template_z2heana',
  
  banner_home : [
    banner_home1,
    banner_home4,
    banner_home2,
    banner_home5,
    banner_home3,
    banner_home6
 ],
 home_introImage: {
   site: home_introImage_site,
   aboutus:home_introImage_aboutus,
   project: home_introImage_project,
   recruitment: home_introImage_recruitment,
 },
 banner_aboutus: banner_aboutus,
 banner_site: banner_site,
 banner_projects: banner_projects,
 banner_product: banner_product,
 banner_recruitment: banner_recruitment,
 banner_contactUs: banner_contactUs,
 site_location: {
   ha_noi: site_location_ha_noi,
   bac_ninh: site_location_bac_ninh
 },
  // banner_home : [
  // // '20230322_110253_008.png',
  // '20230313_181345_945.png',
  // '20230403_201525_320.png',
  // '20230407_133107_137.jpg',
  // ],
  // home_introImage: {
  //   site:"20230321_204802_709.png",
  //   aboutus:"20230321_204342_181.png",
  //   project: "20230321_204659_374.png",
  //   recruitment: "20230321_204834_545.png",
  // },
  // banner_aboutus: '20230329_114405_726.jpg',
  // banner_site: '20230313_183414_963.png',
  // banner_projects: '20230407_131019_296.JPG',
  // banner_product: '20230324_130339_656.png',
  // banner_recruitment: '20230313_190715_198.png',
  // banner_contactUs: '20230313_185846_375.png',
  // site_location: {
  //   ha_noi: '20230323_191000_412.jpg',
  //   bac_ninh: '20230323_191339_603.png'
  // },

  STATUS_SUCCESS: 200,

  API_RESPONSE_MESSAGE_UPDATED: "UPDATED",
  API_RESPONSE_MESSAGE_REGISTED: "REGISTERED",

  key_token: 'token',
  key_loginTime: 'loginTime',

  project_domestic: 'DOMESTIC',
  project_oversea: 'OVERSEA',

  Category_Main_Product: 'Main Product',
  Introduction_About_US: 'About us',
  Introduction_Projects: 'Projects',
  Introduction_Sites: 'Sites',

  Product_Form: {
    Product_Name: 'productName',
    Product_Detail: 'productDetail',
  },

  New_FormControls: {
    title: 'title',
    summary: 'summary',
    description: 'description',
    image: 'image',
    createDate: "createDate"
  },

  Recuitment_FormControls: {
    introduction: 'introduction',
    link: 'link',
  },

  // feature
  quan_ly_tiep_don: "quan_ly_tiep_don",
};

export default keys;
