import { Col, Popover, Row, Segmented } from "antd";
import { keys } from "../../../constants";
import cn from "classnames";
import project from "./project.module.less";
import { useEffect, useState } from "react";
import { example1, example2, example3, example4, example5, example6, example7, example8, example9, material1, material2, material3, material4, material5, material6, material7, material8, material9 } from "../../../assets/img";
import { dhaka,materialDhaka,cauDongTru,materialCauDongTru,cauVuotTienLang,materialcauVuotTienLang,lgHaiPhong,materialLgHaiPhong,selabam,materialSelabam,qlo45,materialQL45,melody,materialMelody,mongDuong,materialDongDuong } from "../../../assets/img";
import { languageKeys, Translate } from "../../../i18n";
const Project = () => {

    const [segment, set_segmented] = useState(keys.project_domestic)

    const [dataProject, set_dataProject] = useState([])

    useEffect(()=> {
        set_dataProject(segment === keys.project_domestic ? sampleData : sampleDataOversea)
    },[segment])

    return (
        <div className={project["container"]}>

            <Segmented
                block
                defaultValue={keys.project_domestic}
                options={segmentOptions(segment)}
                onChange={(value) => {set_segmented(value)}}
                className={project["segment-bar"]} size='large' />
            <div className={project["segment-container"]}>
                <Row style={{ width: '100%', marginTop: '20px', alignSelf: 'center' }} gutter={[50, 20]} >
                    {dataProject.map(item => <Col className={project["project-col"]} span={8}>
                    <Popover placement="bottom" content={item.source} >
                        <div className={project["scalebox"]}>
                            <div className={project["scalebox-image"]}>
                                <img src={item.image} className={project["img"]} alt = 'banner'/>
                            </div>
                        <div className={project["project-title"]}>
                            {item.title}
                        </div>
                        </div>
                        <div className={project["project-material"]}>
                            <div className={project["scalebox"]}>
                                <div className={project["scalebox-image"]}>
                                    <img src={item.material} className={project["img"]} alt = 'project_item' />
                                </div>
                            </div>
                        </div>
                        </Popover>
                    </Col>)}
                </Row>
            </div>
        </div>
    );
};

export default Project;


const sampleData = [{
    title: Translate(languageKeys.Project_DienMatTroiHauGiang),
    image: example4,
    material: material1,
    source: <a href={'https://daiphongpower.com'} target="_blank" rel="noopener noreferrer">{Translate(languageKeys.Common_Source)}: https://daiphongpower.com</a>
}, {
    title: Translate(languageKeys.Project_NhietDienNghiSon),
    image: example1,
    material: material2,
    source: <a href={'https://baochinhphu.vn'} target="_blank" rel="noopener noreferrer">{Translate(languageKeys.Common_Source)}: https://baochinhphu.vn</a>
}, {
    title: Translate(languageKeys.Project_NhaMayNuocThaiHoaLac),
    image: example6,
    material: material3,
    source: <a href={'https://nhadautu.vn'} target="_blank" rel="noopener noreferrer">{Translate(languageKeys.Common_Source)}: https://nhadautu.vn</a>
}, {
    title: Translate(languageKeys.Project_GiengChimLachChay),
    image: example5,
    material: material4,
    source: <a href={'nguoiduatin.vn'} target="_blank" rel="noopener noreferrer">{Translate(languageKeys.Common_Source)}: nguoiduatin.vn</a>
}, {
    title: Translate(languageKeys.Project_MayTapTheDuc),
    image: example2,
    material: material5,
   
}, {
    title: Translate(languageKeys.Project_LandMark81),
    image: example3,
    material: material6,
    source: <a href={'baochinhphu.vn'} target="_blank" rel="noopener noreferrer">{Translate(languageKeys.Common_Source)}: baochinhphu.vn</a>
}, {
    title: Translate(languageKeys.Project_DienGioDongHai),
    image: example9,
    material: material7,
    source: <a href={'https://tuoitrenews.vn'} target="_blank" rel="noopener noreferrer">{Translate(languageKeys.Common_Source)}: https://tuoitrenews.vn</a>
}, {
    title: Translate(languageKeys.Project_CaoTocDaNang),
    image: example8,
    material: material8,
    source: <a href={'tinnhanhchungkhoan.vn'} target="_blank" rel="noopener noreferrer">{Translate(languageKeys.Common_Source)}: tinnhanhchungkhoan.vn</a>
}, {
    title: Translate(languageKeys.Project_CauHoangVanThu),
    image: example7,
    material: material9,
},{
    title:Translate(languageKeys.Project_CauDongTru),
    image: cauDongTru,
    material: materialCauDongTru,
    source:<a href={'https://vneconomy.vn'} target="_blank" rel="noopener noreferrer">{Translate(languageKeys.Common_Source)}: https://vneconomy.vn</a>
},{
    title: Translate(languageKeys.Project_CauVanUc),
    image: cauVuotTienLang,
    material: materialcauVuotTienLang,
    source: <a href={'https://tapchixaydung.vn'} target="_blank" rel="noopener noreferrer">{Translate(languageKeys.Common_Source)}: https://tapchixaydung.vn</a>
},{
    title: Translate(languageKeys.Project_NhietDienMongDuong),
    image: mongDuong,
    material: materialDongDuong,
    source: <a href={'https://baoquangninh.vn'} target="_blank" rel="noopener noreferrer">{Translate(languageKeys.Common_Source)}: https://baoquangninh.vn</a>
},{
    title: Translate(languageKeys.Project_MelodyResident),
    image: melody,
    material: materialMelody,
    source: <a href={'https://vietekcons.vn/lavavita-charm-thu-duc.html'} target="_blank" rel="noopener noreferrer">{Translate(languageKeys.Common_Source)}: https://vietekcons.vn/lavavita-charm-thu-duc.html</a>
},{
    title: Translate(languageKeys.Project_LGHaiPhong),
    image: lgHaiPhong,
    material: materialLgHaiPhong,
    source: <a href={'https://e.vnexpress.net/news/business/companies/lg-s-hai-phong-smartphone-factory-for-sale-4260571.html'} target="_blank" rel="noopener noreferrer">{Translate(languageKeys.Common_Source)}: https://e.vnexpress.net/news/business/companies/lg-s-hai-phong-smartphone-factory-for-sale-4260571.html</a>
},{
    title: Translate(languageKeys.Project_QuocLo45),
    image: qlo45,
    material: materialQL45,
    source: <a href={'https://baochinhphu.vn/hoan-thanh-chuan-bi-dau-tu-12-du-an-thanh-phan-cao-toc-bac-nam-giai-doan-2-10222071418524844.htm'} target="_blank" rel="noopener noreferrer">{Translate(languageKeys.Common_Source)}: https://baochinhphu.vn/hoan-thanh-chuan-bi-dau-tu-12-du-an-thanh-phan-cao-toc-bac-nam-giai-doan-2-10222071418524844.html</a>
}]

const sampleDataOversea = [{
    title: Translate(languageKeys.Project_Dhaka),
    image: dhaka,
    material: materialDhaka,
    source: <a href={'newagebd.net'} target="_blank" rel="noopener noreferrer">{Translate(languageKeys.Common_Source)}: newagebd.net</a>
}, {
    title: Translate(languageKeys.Project_Selabam),
    image: selabam,
    material: materialSelabam,
    source: <a href={'hobomaps.com'} target="_blank" rel="noopener noreferrer">{Translate(languageKeys.Common_Source)}: hobomaps.com</a>
}, ]


const segmentOptions = (key) =>([
    {
        label: (
            <div className={cn(project["segment-label"],key === 'in' && project["selected"])}>
                {Translate(languageKeys.Project_Segment_NationProject)}
            </div>
        ),
        value: keys.project_domestic,
    },
    {
        label: (
            <div className={cn(project["segment-label"],key === 'out' && project["selected"])}>
                 {Translate(languageKeys.Project_Segment_OverseaProject)}
            </div>
        ),
        value: keys.project_oversea,
    }
])