import { Col, Row,  } from "antd";
import { MapMark } from "../../../assets/svg";
import { keys } from "../../../constants";
import site from "./site.module.less";
import { languageKeys, Translate } from "../../../i18n";
import { getImageUrl } from "../../../helpers";
import { VNMAP } from "../../../assets/img";


let a = new Array(16)
a.fill({})
const Site = () => {
  return (
    <div className={site["content-container"]}>

      <div className={site["svg-map-container"]} >
        {/* <VnMap className={site["svg"]} />
         */}
         <img src = {VNMAP} className={site["img-contain"]} alt="mapVietNam"/>
      </div>


      <Row justify={'start'} className={site["title-bar"]}>
        <MapMark style={{ marginRight: '20px', marginLeft: '10px' }} />{Translate(languageKeys.Site_BacNinh)}
      </Row>
      <div className={site["paragraph"]}>
        NIPPON STEEL METAL PRODUCTS VIETNAM CO., LTD.
        <div>{Translate(languageKeys.Site_Manufacturuing_title)}</div>
        <div className={site["gray-text"]} style={{ marginTop: '15px' }}>{Translate(languageKeys.Common_Address)}{Translate(languageKeys.Common_BNCommpany_Address)}</div>
        <div className={site["gray-text"]}>{Translate(languageKeys.Common_Tel)} +84-222 3617568/ 568/ 569 *{Translate(languageKeys.Common_Fax)} 0222 361 7567</div>
        <div className={site["gray-text"]}>Email: contact@nsmv.com.vn</div>

      </div>

      <Row gutter={10} justify={'start'} className={site["media-row"]} >
        <Col span={12}>
          <div className={site["media-content"]} >
            <iframe title='mapBacNinh' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3720.9234248377556!2d106.12234371540364!3d21.15544548881622!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31350b6519baee81%3A0x837731c90d0e4bc2!2sNSMV(Nippon%20Steel%20Metal%20Products%20Vietnam)!5e0!3m2!1svi!2s!4v1676949969312!5m2!1svi!2s" width="100%" height="400" style={{ border: 0, borderRadius: '10px' }} allowfullscreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </Col>

        <Col span={12}>
          <div className={site["media-content"]} >
            <img src={getImageUrl(keys.site_location.bac_ninh)} className={site["img-fill"]} alt="localBacninh"/>

            {/* <img src={getImageUrl('20230323_191339_603.png')} className={site["img-fill"]} /> */}
          </div>
        </Col>
      </Row>



      <Row justify={'start'} className={site["title-bar"]}>
        <MapMark style={{ marginRight: '20px', marginLeft: '10px' }}/>{Translate(languageKeys.Site_HaNoi)}
      </Row>

      <div className={site["paragraph"]}>
        NIPPON STEEL METAL PRODUCTS VIETNAM CO., LTD.
        <div>{Translate(languageKeys.Site_RepresentOffice_title)}</div>
        <div className={site["gray-text"]} style={{ marginTop: '15px' }}>{Translate(languageKeys.Common_Address)}{Translate(languageKeys.Common_HNCommpany_Address)}</div>
        <div className={site["gray-text"]}>{Translate(languageKeys.Common_Tel)} +84-243 227 2083</div>
        <div className={site["gray-text"]}>Email: contact@nsmv.com.vn</div>
      </div>

      <Row gutter={10} justify={'start'} className={site["media-row"]} >
        <Col span={12}>
          <div className={site["media-content"]} >

            <iframe title='mapHanoi' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3724.124569590226!2d105.80440259999999!3d21.0277011!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135ab43b09c6ff3%3A0xaaf500532c108ba3!2zMjQzQSDEkC4gTGEgVGjDoG5oLCBMw6FuZyBUaMaw4bujbmcsIMSQ4buRbmcgxJBhLCBIw6AgTuG7mWk!5e0!3m2!1svi!2s!4v1676944819703!5m2!1svi!2s" width="100%" height="400" style={{ border: 0, borderRadius: '10px' }} allowfullscreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </Col>

        <Col span={12}>
          <div  className={site["media-content"]} >
            <img src={getImageUrl(keys.site_location.ha_noi)} className={site["img-fill"]} alt="localHanoi"/>
          </div>
        </Col>
      </Row>

    </div>

    // </div>
  );
};

export default Site;


