import { Layout } from "antd";
import { useState } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { paths } from "../constants";
import { Header } from "../layouts/admin";
import { NotFound } from "../pages/common";
import { ScrollToTop } from "../hooks";
import { Login, NewsEditor, Product, RecuitmentAdmin, ListAdmin, Sample } from "../pages/admin";
import Footer from "../layouts/common/Footer/Footer";
import '../assets/style/mixins.less'
const CisRoute = ({ location }) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <>
      <ScrollToTop />
      <Layout>
        <Layout>
          <Header
            collapsedSider={collapsed}
            toggleSider={() => setCollapsed((val) => !val)}
          />

          <Layout style={{ background: '#fff' }}>
            <Layout.Content className={'admin-body-container'}>
              <Switch>
                <Route
                  path={paths.admin_product}
                  component={Product}
                />
                <Route
                  path={paths.admin_product_wId}
                  component={Product}
                />
                <Route
                  exact
                  path={paths.login}
                  component={Login}
                />

                <Route
                  path={paths.admin_sample}
                  component={Sample}
                />

                <Route
                  exact
                  path={paths.admin_news}
                  component={NewsEditor}
                />
                <Route
                  path={paths.admin_news_wID}
                  component={NewsEditor}
                />
                {/* <Route
                    path={':id'}
                    component={NewsEditor}
                  /> */}


                <Route
                  path={paths.admin_recruitment}
                  component={RecuitmentAdmin}
                />

                <Route
                  path={paths.admin_list}
                  component={ListAdmin}
                />

                <Redirect exact from={paths.admin_tag} to={paths.home_page} />
                <Route component={NotFound} />
              </Switch>
            </Layout.Content>


            {/* <SubSider /> */}
            <Footer/>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default withRouter(CisRoute);
