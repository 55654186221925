import React, { useState } from "react";
import { Col, Row, Tooltip } from "antd";
import { ProductIcon } from "../../../assets/svg";
import productList from "./productList.module.less";
import cn from "classnames";
import { Link } from "react-router-dom";
import { paths } from "../../../constants";
import { getImageUrl } from "../../../helpers";
import { DoubleRightOutlined } from "@ant-design/icons";
import { Translate } from "../../../i18n";
import { useSelector } from "react-redux";

const MenuItem = ({ idx, title, items, lang }) => {
    const [expanded, set_expanded] = useState(true);
    const clickHandler = () => { set_expanded(!expanded) };
    const currentLanguage = useSelector(state => state.app.currentLanguage)
    return (
        <div style={{display: 'flex', flexDirection: 'column'}} >
            <Row
                className={productList["menu-item-title"]}
                style={{ marginTop: idx > 0 ? '60px' : '0px' }}
                onClick={clickHandler} justify="start" align='middle'>
                <ProductIcon width="5vw" height="5vw" style={{ marginRight: '10px', maxWidth: '35px', maxHeight: '35px' }} />
                {title}
            </Row>
            <Row justify={'start'} gutter={40} style={{ minHeight: '80px', width: '100%', alignSelf: 'center' }}>
                {items.map((item, index) => (
                    // <div key={item.title}>
                    <Col key={title + index} span={6}
                        className={cn(productList["item-layout"], expanded ? productList['expanded'] : productList['collapsed'])}
                        // onClick={() => history.push(paths.product + "?id=" + item.id)}
                    >
                        <Link to={paths.product + "?id=" + item.id}>
                            <div className={productList["scalebox"]}>
                                <div className={productList["scalebox-image"]}>
                                    <img src={getImageUrl(item.imageResponses[0].localThumbnailPath)} className={productList["img-fill"]} style={{ borderRadius: '10px' }} alt='product'/>
                                </div>
                            </div>
                            <Row align={'middle'} style={{marginTop: '10px'}}>
                                <Col span={23}>
                                    <div className={productList["item-title"]}>
                                        {item.language[currentLanguage].productName}
                                    </div>
                                </Col>
                                <Col span={1}>
                                <DoubleRightOutlined className={productList["product-title-arrow"]}/>
                                </Col>

                            </Row>

                            <Tooltip title={item.description} showArrow={false} color={'#fff'} placement='topRight'
                                overlayInnerStyle={{ color: '#000' }}>
                                <div className={productList["item-description"]}>{item.language[currentLanguage].productDetail}</div>
                            </Tooltip>
                        </Link>
                    </Col>

                    // </div>           
                ))}
            </Row>
        </div>

    );
};

const Menu = ({ mainItems, lang }) => {
    return !mainItems ? null : (
        <div className={productList["product-menu"]}>
            {Object.keys(mainItems).map((item, index) => (
                <MenuItem key={index.toString()} idx={index} title={Translate(item.replaceAll(' ', '_'))} items={mainItems[item]} lang={lang} />
            ))}
        </div>
    );
};

export default Menu;



