import { takeLatest, put } from "redux-saga/effects";
import { common_post, HLog, localRemove } from "../../helpers";
import { authFail, authSuccess, doLogin, doLogout } from "../slices/authSlice";
import { apis, keys } from "../../constants";
import { displayMessage } from "../slices/appSlice";
export function* watchDoAuth() {
  yield takeLatest(doLogin.type, handleLogin);
  yield takeLatest(doLogout.type, handleLogout);
}

export function* handleLogin(action) {
  HLog("Call handle login", action);

  try {
    const response = yield common_post(apis.Post_Login, action.payload, false);

    if (!!response && response.status === 200 && response.message === 'OK') {
      const { accessToken } = response.data;
      HLog("Login response", response);
      yield put(
        authSuccess({
          token: accessToken
        })
      );
    } else {
      yield put(
        authFail({
        })
      );
      yield put(
        displayMessage({
          type: 'error',
          content: response.message,
        })
      )
    }
  } catch (error) {
    HLog("Login Error", error);

    yield put(
      authFail({
        isOk: false,
        message: "Login Error",
        user: undefined,
      })
    );
  }
}

export function* handleLogout() {
  yield localRemove(keys.access_token);
  yield localRemove(keys.user_data);

  window.location.reload();
}
