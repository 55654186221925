import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { keys, paths } from "../constants";
import { authRestore } from "../ducks/slices/authSlice";
export const PublicRoute = ({ component: Component, ...rest }) => {
    const dispatch = useDispatch()
    let token = localStorage.getItem(keys.key_token);
    let loginTime = localStorage.getItem(keys.key_loginTime);
    loginTime = Number(loginTime)

    let current = new Date().getTime()
    var isAuth = !!token && !!loginTime && current < loginTime + 21600000
    useEffect(() => {
        if (isAuth) {
            dispatch(authRestore({ token, loginTime }))
        }
    }, [isAuth, token, dispatch, loginTime])

    return (
        <Route
            {...rest}
            render={(props) =>
                <Component {...props} />
            }
        />
    );
};

export const PrivateRoute = ({ component: Component, ...rest }) => {
    const dispatch = useDispatch()

    let token = localStorage.getItem(keys.key_token);
    let loginTime = localStorage.getItem(keys.key_loginTime);
    loginTime = Number(loginTime)

    let current = new Date().getTime()
    var isAuth = !!token && !!loginTime && current < loginTime + 21600000
    useEffect(() => {
        if (isAuth) {
            dispatch(authRestore({ token, loginTime }))
        }
    }, [isAuth, token, dispatch, loginTime])

    return (
        <Route
            {...rest}
            render={(props) =>
                isAuth ? <Component {...props} /> : <Redirect to={paths.auth} />
            }
        />
    );
};

