const auth = "/auth";
const cis = "/cis";

const main_tag = '/main';
const admin_tag = '/admin';

const paths = {
  main: "/",

  main_tag: '/main',

  home_page: "/",
  home_page_route: main_tag + '/home-page',
  about_us: main_tag + '/about-us',
  recruitment: main_tag + '/recruitment',
  contact_us: main_tag + '/contact-us',
  site: main_tag + '/site',
  product_list: main_tag + '/product-list',
  projects: main_tag + '/projects',
  product: main_tag + '/product',
  introduction: main_tag + '/introduction',
  news_detail: main_tag + '/news_detail',
  news_detail_wId: main_tag + '/news_detail/:id',

  admin_sample: admin_tag + '/sample',
  admin_news: admin_tag + '/news',
  admin_news_wID: admin_tag + '/news/:id',
  admin_tag: '/admin',
  admin_login: admin_tag + '/login',
  admin_product: admin_tag + '/product',
  admin_product_wId: admin_tag + '/product/:id',
  admin_recruitment: admin_tag + '/recuitment',
  admin_list: admin_tag + '/list',

  auth: auth,
  login: auth + "/login", // Đăng nhập

  vcard: "/vcard",
  vcard_wId: "/vcard/:id",

  cis: cis,
  quan_ly_danh_muc: cis + "/quan-ly-danh-muc", // Quản lý danh mục (quản lý hệ thống)
};

export default paths;
