import { Col, Row, Spin } from "antd";
import Menu from "./Menu";
import React, { useEffect, useState } from "react";
import product from "./productList.module.less";
import { getImageUrl, getLanguage } from "../../../helpers";
import { apis, keys } from '../../../constants';
import { useDispatch } from 'react-redux';
import { displayMessage, appLoading, appLoadingDone } from "../../../ducks/slices/appSlice";
import axios from "axios";
import { languageKeys, Translate } from "../../../i18n";


const ProductList = () => {
  const [productList, set_productList] = useState([]);
  const dispatch = useDispatch();
  const [currentLanguage, ] = useState(getLanguage());
  const [isLoading, set_isLoading] = useState(true)

  useEffect(() => {
    initProductList()
  }, [])

  const initProductList = async () => {
    let config = {};
    let requestPath = apis.Post_Home_Product_List + `${currentLanguage}`;
    // dispatch(appLoading())
    set_isLoading(true)
    await axios.post(requestPath, config)
      .then(response => {
        if (response.status === keys.STATUS_SUCCESS) {
          let { data } = response;
          if (data.status === keys.STATUS_SUCCESS && !!data.data) {
            set_productList(data.data)
          }
        } else {
          dispatch(displayMessage({
            type: 'error',
            content: response.message,
          }))
        }
          set_isLoading(false)
        // dispatch(appLoadingDone())
      })
      .catch(err => {
        // dispatch(appLoadingDone())
        set_isLoading(false)
        dispatch(displayMessage({
          type: 'error',
          content: err.message,
        }))
        
      })
  }


  return (
    <div className={product["container"]}>
      <div className={product["banner-container"]}>

        <img src={getImageUrl(keys.banner_product)} className={product["img-contain"]} alt='banner'/>
        <div className={product["banner-content"]}>
          <div className={product["content-title"]}>{Translate(languageKeys.Product_Banner_title)}</div>
         </div>
      </div>

      <div className={product["content-container"]}>
        <div className={product["block-content"]}>
          <Row wrap={'wrap'} justify={'space-between'}>
            {isLoading ? <Col span={24} className={product["loading-indicator"]}><Spin style={{ alignSelf: 'center'}}/></Col> :
            <Menu mainItems={productList} lang={currentLanguage} />}
          </Row>
        </div>
      </div>

    </div>
  );
};

export default ProductList;
