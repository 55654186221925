import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { paths } from "../constants";
import { Login } from "../pages/admin";
import { NotFound } from "../pages/common";

const AuthRoute = ({ location }) => {
  return (
    <Switch>
      <Route exact path={paths.login} component={Login} />

      <Redirect exact from={paths.auth} to={paths.login} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default withRouter(AuthRoute);
