import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { Col, Row, Input, Select, Button, Card, Space } from "antd";
import { CloseOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import axios from 'axios';
import { SlateEditor, UploadModal } from "../components";
import product from "./Product.module.less";
import { apis, keys } from '../../../constants';
import { useSelector, useDispatch } from 'react-redux';
import { languageKeys, languages, Translate } from "../../../i18n";
import { jap_flag, us_flag, vietnam_flag } from "../../../assets/img";
import { appLoading, appLoadingDone, displayMessage } from '../../../ducks/slices/appSlice';
import { getBase64, getImageUrl } from '../../../helpers';
import EditContactModal from '../components/EditContactModal/EditContactModal';
import PreviewProductModal from './PreviewProductModal';

const languageProps = [
  {
    value: languages.tieng_anh,
    label: <Row align={'middle'} gutter={10} justify='start'>
      <img src={us_flag} className={product["img"]} width={"20px"} height={"20px"} style={{ marginRight: '20px', marginLeft: '5px', borderRadius: '25px' }} alt = 'language_flag'/>
      English
    </Row>
  },
  {
    value: languages.tieng_nhat_api,
    label: <Row align={'middle'} gutter={10} justify='start'>
      <img src={jap_flag} className={product["img"]} width={"20px"} height={"20px"} style={{ marginRight: '20px', marginLeft: '5px', borderRadius: '25px' }} alt = 'language_flag'/>
      日本語
    </Row>
  },
  {
    value: languages.tieng_viet,
    label: <Row align={'middle'} gutter={10} justify='start'>
      <img src={vietnam_flag} className={product["img"]} width={"20px"} height={"20px"} style={{ marginRight: '20px', marginLeft: '5px', borderRadius: '25px' }} alt = 'language_flag'/>
      Tiếng Việt
    </Row>
  },
];


const newAddContact =
{
  contactName: "",
  contactPhone: "",
  email: "",
};

const newAddStandard =
{
  standardName: "",
  standardDetail: "",
};

const Product = () => {
  const [fileList, set_fileList] = useState([]);
  const [name, set_name] = useState('');
  const [category, set_category] = useState();
  const [categories, set_categories] = useState([]);
  const [detail, set_detail] = useState('');

  const [contentProducts, set_contentProducts] = useState(sampleContentProducts);
  const [contactList, set_contactList] = useState([]);
  const [deletedImageList, set_deletedImageList] = useState([]);
  const [currentContact, set_currentContact] = useState({});
  const [newStandard, set_newStandard] = useState(newAddStandard);
  const [lang, set_lang] = useState(languages.tieng_viet);
  const [standardList, set_standardList] = useState([]);
  const [standardList_en, set_standardList_en] = useState([]);
  const [standardList_jp, set_standardList_jp] = useState([]);
  const auth = useSelector(state => state.auth);
  const uploadModal = useRef(null);
  const editContactModal = useRef(null);
  const previewProductModal = useRef(null);
  const editorRef = useRef();
  const search = useLocation().search;
  const [edittingStandard, set_edittingStandard] = useState()

  const id = new URLSearchParams(search).get('id');
  const dispatch = useDispatch();


  useEffect(() => {
    contentProducts.map(item => {
      if (item.lang === lang) {
        set_name(item.productName);
        set_detail(item.productDetail);
      }
      return item
    });

  }, [lang])

  useEffect(() => {
    initCategory()
    if (!!auth.token) {
      if (!!id) {
        init()
      } else {
        resetForm()
      }
    }
  }, [auth, id])

  const resetForm = () => {
    set_contentProducts(sampleContentProducts)
    set_contactList([])
    set_deletedImageList([])
    set_currentContact({})
    set_newStandard(newAddStandard)
    set_standardList([])
    set_standardList_en([])
    set_standardList_jp([])
    set_fileList([])
    set_name('')
    set_detail('')
    set_category('')
    editorRef.current.reset()
  }

  const init = () => {

    let config = {
      headers: {
        Authorization: auth.token,
        
      },
    };

    if (!!id) {
      config = {
        headers: {
          Authorization: auth.token,
          
        },
        params: {
          id: id
        },
      };
      axios.get(apis.Get_Product, config)
        .then(res => {
          
          if (res.status === 200)
            handleLoad(res.data.data);

        })
        .catch(err => {
          
        });
    }
  }

  const initCategory = () => {
    let config = {
      headers: {
        Authorization: auth.token,
        
      },
    };


    axios.get(apis.Get_Product_Categories, config)
      .then(res => {
        let options = [];
        for (let i = 0; i < res.data.data.length; i++) {
          options[i] = {
            value: res.data.data[i].name,
            name: Translate(res.data.data[i].name.replaceAll(' ', '_')),
          };
        }

        set_categories(options);
      })
      .catch(err => {
        
      });

  }

  const handleLoad = (product) => {
    console.log('data', product);
    set_name(product.language[`${languages.tieng_viet}`].productName);
    set_detail(product.language[`${languages.tieng_viet}`].productDetail);
    set_contentProducts(product.language);
    const updatedLanguage = contentProducts.map(content => {
      console.log(content.lang, content);
      if (content.lang === languages.tieng_viet && !!product.language[`${languages.tieng_viet}`])
        content = product.language[`${languages.tieng_viet}`];
      if (content.lang === languages.tieng_anh && !!product.language[`${languages.tieng_anh}`])
        content = product.language[`${languages.tieng_anh}`];
      if (content.lang === languages.tieng_nhat_api && !!product.language[`${languages.tieng_nhat_api}`])
        content = product.language[`${languages.tieng_nhat_api}`];
      return content;
    });

    console.log('updated content', updatedLanguage);

    set_contentProducts(updatedLanguage);

    const imageList = product.imageResponses.map((image) => {
      return { uid: image.id, name: image.fileName, status: 'done', url: getImageUrl(image.localThumbnailPath) };
    });

    let standard_vi = product.standards || [];
    let standard_en = product.standards_en || [];
    let standard_jp = product.standards_jp || []

    set_fileList(imageList);
    set_contactList(product.contacts);
    set_standardList(standard_vi.map(item => ({...item, standardDetail: item.standardDetail.replaceAll('homepage-api.nsmv.com.vn', 'api.nsmv.com.vn')})));
    set_standardList_en(standard_en.map(item => ({...item, standardDetail: item.standardDetail.replaceAll('homepage-api.nsmv.com.vn', 'api.nsmv.com.vn')})));
    set_standardList_jp(standard_jp.map(item => ({...item, standardDetail: item.standardDetail.replaceAll('homepage-api.nsmv.com.vn', 'api.nsmv.com.vn')})));
    set_category(product.category);
  };
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
  };

  const handleChange = ({ fileList: newFileList }) => {
    let files = newFileList.filter(file => (file.type === undefined || file.type.includes("image")))

    set_fileList(files)
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>{Translate(languageKeys.Common_Upload)}</div>
    </div>
  );

  const handleProductInput = (event, productItem) => {
    if (productItem === keys.Product_Form.Product_Name)
      set_name(event.target.value)
    else
      set_detail(event.target.value)
    const updatedLanguage = contentProducts.map(content => {
      if (content.lang === lang) {
        content[`${productItem}`] = event.target.value;
        return content
      } else {
        return content;
      }
    });
    // Re-render with the new array
    set_contentProducts(updatedLanguage);
  }

  const handleStandard = () => {
    if (!newStandard.standardName) {
      dispatch(displayMessage({
        type: 'error',
        content: Translate(languageKeys.Api_Message_Require_StandardName),
      }));
      return;
    }

    if (!editorRef.current.getHtml()) {
      dispatch(displayMessage({
        type: 'error',
        content: Translate(languageKeys.Api_Message_Require_StandardDetail),
      }));
      return;
    }

    console.log(getStandardList(), editorRef.current.getHtml());
    if (!!edittingStandard || edittingStandard === 0) {
      saveStandardList(getStandardList().map((p, index) => index === edittingStandard ? { standardName: newStandard.standardName, standardDetail: editorRef.current.getHtml() } : p));
    } else {
      saveStandardList([...getStandardList(), { standardName: newStandard.standardName, standardDetail: editorRef.current.getHtml() }]);
    }
    set_edittingStandard()
    set_newStandard({ newAddStandard });
    editorRef.current.reset();
  }

  const saveStandardList = (data) => {
    switch (lang) {
      case languages.tieng_anh:
        set_standardList_en(data)
        return;
      case languages.tieng_nhat:
      case languages.tieng_nhat_api:
        set_standardList_jp(data)
        return;
      default:
        set_standardList(data)
        return;
    }
  }

  const checkProduct = () => {
    // New product
    if (fileList.length <= 0 && !id) {
      dispatch(displayMessage({
        type: 'error',
        content: Translate(languageKeys.Api_Message_Require_Image),
      }));
      return false;
    }
    // Edit product
    else if (fileList.length <= 0 && !!id) {
      // All load images were deleted
      if (deletedImageList.length > 0) {
        dispatch(displayMessage({
          type: 'error',
          content: Translate(languageKeys.Api_Message_Require_Image),
        }));
        return false;
      }
    }
    if (standardList.length <= 0 && standardList_en.length <= 0 && standardList_jp.length <= 0) {
      dispatch(displayMessage({
        type: 'error',
        content: Translate(languageKeys.Api_Message_Require_Standard),
      }));
      return false;
    }
    return true;
  };

  const createProduct = () => {
    let formData = new FormData();
    let sampleProduct = {
      'id': id,
      'images_delete': deletedImageList,
      'language': contentProducts,
      'contacts': contactList,
      'standards': standardList,
      'standards_en': standardList_en,
      'standards_jp': standardList_jp,
      'category': category
    };

    console.log('product save data',sampleProduct );
    if (!checkProduct())
      return;

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": auth.token,
        
      },
    };

    const json = JSON.stringify(sampleProduct);
    const blob = new Blob([json], {
      type: 'application/json'
    });

    formData.append('product', blob);

    fileList.map(image => {
      if (image.uid.includes("upload"))
        formData.append('files', image.originFileObj);
        return image
    });

    dispatch(appLoading())
    axios.post(apis.Post_Product_Create, formData, config)
      .then(res => {
        
        if (res.status === 200)
          dispatch(displayMessage({
            type: 'success',
            content: res.data.message ,
          }));
        dispatch(appLoadingDone())
      })
      .catch(err => {
        
        dispatch(displayMessage({
          type: 'error',
          content: Translate(languageKeys.Api_Message_Error),
        }));
        dispatch(appLoadingDone())
      });
  }

  const handleUpdateContact = (editedContact) => {
    const updatedList = contactList.map(
      (contact, index) => index === editedContact.index ? editedContact : contact);
    !!editedContact.index || editedContact.index === 0 ? set_contactList(updatedList) : set_contactList([...contactList, editedContact])
  }

  const handleRemoveStandard = (removedStandard) => {
    saveStandardList(getStandardList().filter((a, index) => index !== removedStandard.index))
  }

  const handleRemoveImage = (file) => {
    if (!file.uid.includes("upload")) {
      set_deletedImageList([...deletedImageList, file.uid]);
      set_fileList(fileList.filter((a, index) => index !== file.uid))
    }
  }

  const handlePreviewProduct = () => {
    let previewProduct = {
      'id': id,
      'images_delete': deletedImageList,
      'language': contentProducts,
      'contacts': contactList,
      'standards':  standardList,
      'standards_en':  standardList_en,
      'standards_jp':  standardList_jp,
      'current_language': lang,
      'category': category,
      'files': fileList
    };
    console.log(previewProduct);
    previewProductModal.current?.openPreview(previewProduct)
  }


  const ContactExtra = (contact) => {
    return <div>
      <EditOutlined style={{ color: 'blue', marginRight: 10 }} onClick={() => {set_currentContact(contact); editContactModal.current?.openEdit(contact)}} />
      <CloseOutlined onClick={() => { set_contactList(contactList.filter((a, index) => index !== contact.index)) }} />
    </div>
  }

  const onEditStandard = (data, index) => {
    if(!!edittingStandard || edittingStandard === 0){
      dispatch(displayMessage({
        type: 'error',
        content: 'Lưu nội dung hiện tại trước',
      }));
      return
    }
    set_edittingStandard(index)
    set_newStandard(data)
    editorRef.current.parseHtml(data.standardDetail)
  }

  const onChangeLanguage = (lang) => {
    set_lang(lang)
    set_newStandard(newAddStandard)
    editorRef.current.reset();
    set_edittingStandard()
    if (!!contentProducts[lang]) {
      set_detail(contentProducts[lang][keys.Product_Form.Product_Detail])
      set_name(contentProducts[lang][keys.Product_Form.Product_Name])
    } else {
      set_detail('')
      set_name('')
    }
  }

  // lấy danh sách tiêu chuẩn hiện tại theo ngôn ngữ
  const getStandardList = () => {
    switch (lang) {
      case languages.tieng_anh:
        return standardList_en;
      case languages.tieng_nhat:
      case languages.tieng_nhat_api:
        return standardList_jp;
      default:
        return standardList;
    }
  }

  console.log(getStandardList());

  return (
    <div className={product["container"]}>
      <div className={product["product-container"]}>
        <Row justify={'space-between'} gutter={40}>
          <Col span={14}>
            <div className={product["product-form-column"]}>
              <div className={product["product-input-title"]}>{Translate(languageKeys.Admin_Product_Title_ProductName)}</div>
              <Input value={name} onChange={(event) => handleProductInput(event, keys.Product_Form.Product_Name)} />
              <div className={product["product-input-title"]}>{Translate(languageKeys.Admin_Product_Title_ProductDetail)}</div>
              <Input.TextArea
                value={detail}
                onChange={(event) => handleProductInput(event, keys.Product_Form.Product_Detail)}
                style={{ flex: 1, overflow: 'auto' }} />
            </div>
          </Col>
          <Col span={10} style={{ paddingTop: '40px' }}>
            <Space >
              <Select
                defaultValue={languages.tieng_viet}
                style={{
                  width: 160,
                  marginRight: 20,
                }}
                options={languageProps}
                onSelect={(lang) => onChangeLanguage(lang)}
              />
              <Select
                style={{
                  width: 160,
                }}
                options={categories}
                value={category}
                onSelect={(cate) => set_category(cate)}
              />
            </Space>
            <div className={product["product-input-title"]}>{Translate(languageKeys.Admin_Product_Title_Image)}:</div>
            <div className={product["product-upload-container"]}>
              <Upload
                multiple
                listType="picture-card"
                fileList={fileList}
                maxCount={6}
                onPreview={handlePreview}
                onChange={handleChange}
                onRemove={handleRemoveImage}
                beforeUpload={async (file) => {
                  file.url = await getBase64(file);
                  set_fileList([...fileList, file]);
                  return false
                }}
                accept="image/*"
              >
                {fileList.length >= 6 ? null : uploadButton}
              </Upload>
            </div>
          </Col>
        </Row>

        <Row wrap={'wrap'} justify={'space-between'} style={{ marginTop: '20px' }}>
          <Col span={24}>
            <div className={product["product-input-title"]}>
              <span style={{ marginRight: 10 }}>{Translate(languageKeys.Admin_Product_Title_Contact)}</span>
              <Button
                style={{ width: 35 }}
                type='primary'
                onClick={() => { editContactModal.current?.openEdit(newAddContact) }}
                icon={<PlusOutlined />}
              />
            </div>
          </Col>
        </Row>
        <Row justify={'start'} gutter={[0, 20]} className={product["contact-input-container"]}>

          {contactList.map((contact, index) => (
            <Col span={6} style={{ paddingRight: '20px' }} key={index}>
              <Card
                size="small"
                title={contact.contactName}
                extra={ContactExtra({ ...contact, index })}
                style={{ height: '100%' }}>
                <p>{contact.contactPhone}</p>
                <p>{contact.email}</p>
                <p>{contact.zaloPhone}</p>
              </Card>
            </Col>
          ))}
        </Row>

        <div style={{ marginTop: '20px' }}>
          <div className={product["standard-input-title"]}>{Translate(languageKeys.Admin_Product_Title_Standards)}</div>
          <Row wrap={'wrap'} justify={'space-between'} gutter={[20, 20]} style={{ marginTop: '10px' }}>
            <Col span={14}>
              <Input
                placeholder={Translate(languageKeys.Admin_Product_Button_AddStandard)}
                value={newStandard.standardName}
                onChange={(event) => set_newStandard({ ...newStandard, standardName: event.target.value })} />
            </Col>
            <Col span={10}>
              <Button
                onClick={handleStandard} type='primary'>
                {(edittingStandard === 0 || !!edittingStandard) ? Translate(languageKeys.Admin_Product_Button_SaveStandard) : Translate(languageKeys.Admin_Product_Button_AddStandard)}
              </Button>
            </Col>
          </Row>
          <SlateEditor
            value={newStandard.standardDetail}
            style={{ minHeight: '200px', marginTop: '20px' }}
            onChange={(event) => set_newStandard({ ...newStandard, standardDetail: event.target.value })}
            ref={editorRef} />
        </div>

        <Row gutter={[20, 20]}>
          {getStandardList().map((standard, index) => (
            <Col span={6} key={index} onClick={() => onEditStandard(standard, index)}>
              <div className={product["standard-item"]} size="small">
                <div className={product["standard-name"]}>
                  {standard.standardName}
                </div>
                <div className={product["standard-cancel-button"]} onClick={() => handleRemoveStandard({ ...standard, index })} >
                  <DeleteOutlined style={{ color: 'white' }} />
                </div>
              </div>
            </Col>
          ))}
        </Row>
        <Row style={{ marginTop: 20 }} gutter={[20, 20]} align='middle' justify='end'>
          <Col>
            <Button onClick={handlePreviewProduct}>{Translate(languageKeys.Admin_Product_Button_Preview)}</Button>
          </Col>
          <Col>
            <Button type='primary'
              onClick={createProduct} disabled = {!!edittingStandard || edittingStandard === 0}>
              {(!!id && id !== null) ? Translate(languageKeys.Admin_Product_Button_Save) : Translate(languageKeys.Admin_Product_Button_Submit)}
            </Button>
          </Col>
        </Row>
        <UploadModal ref={uploadModal} dataUploadSuccess={(dataFile) => { set_fileList(fileList.concat(dataFile)) }} />
        <EditContactModal ref={editContactModal} dataChangeHandle={(dataContact) => handleUpdateContact(dataContact)} currentContact={currentContact} />
        <PreviewProductModal ref={previewProductModal} />
      </div>
    </div>
  );
};

export default Product;


const sampleContentProducts = [
  {
    "lang": languages.tieng_anh,
    "productName": "",
    "productDetail": "",
    "description": "",
  },
  {
    "lang": languages.tieng_viet,
    "productName": "",
    "productDetail": "",
    "description": ""
  },
  {
    "lang": languages.tieng_nhat_api,
    "productName": "",
    "productDetail": "",
    "description": "",
  }
];

