import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { en, vi, jp } from "./languages";
import languageKeys from "./languageKeys";

const languages = {
  tieng_viet: "vi",
  tieng_anh: "en",
  tieng_nhat: "ja",
  tieng_nhat_api: "jp", //chỉ su dung cho api
};

const resources = {
  [languages.tieng_anh]: {
    translation: en,
  },
  [languages.tieng_viet]: {
    translation: vi,
  },
  [languages.tieng_nhat]: {
    translation: jp,
  },
};

const Translate = i18n.t

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: resources,
    fallbackLng: languages.tieng_anh,
    localeDetection: true,
    detection: {
      order:  ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
    },
  });


export { languages, languageKeys, i18n as default, Translate };
