import { Modal } from 'antd';
import React from 'react'
import { useImperativeHandle } from 'react';
import { useState } from 'react';
import renderHTML from 'react-render-html';
import preview from './previewModal.module.less'

const NewPreviewModal = React.forwardRef((props, ref) => {
  let { onClose = () => {} } = props;

  const [visible, set_visible] = useState(false)
  const [previewData, set_previewData] = useState()

  useImperativeHandle(ref, () => {
    return {
      open() {
        set_visible(true)
      },
      openPreview(data){
        onOpenPreview(data)
      },
      close() {
        set_visible(false)
        set_previewData()
      }
    };
  }, []);

  const onOpenPreview = (data) => {
    set_previewData(data)
    set_visible(true)
  }

  return <Modal
    open={visible}
    wrapClassName={preview['container']}
    mask
    maskClosable={true}
    destroyOnClose
    footer={null}
    width = {'100vw'}
    onCancel={() => {set_visible(false); onClose()}}>
    
    {!!previewData && <div className={preview['content-container']}>
        {/* {!!previewData.image &&
        <img src={typeof previewData.image === 'string' ? getImageUrl(previewData.image) : previewData.image.url} className={preview[`img-contain`]} style={{ width: '100%' }} />} */}
       {!!previewData.title &&
        <div className={preview['preview-title']}>{previewData.title}</div>
        }
        {!!previewData.summary &&
        <div className={preview['preview-summary']}>{previewData.summary}</div>
        }
        {!!previewData.html &&
        <div className={preview['preview-html']}>{renderHTML(previewData.html)}</div>
        }
    </div>}

  </Modal>

})

export default NewPreviewModal;