const en = {
  Language_label : "English",
  Common_ViewMore: "View more",
  Common_Commpany_Address: "Km7, Highway 18, Que Vo Industrial Zone, Phuong Lieu, Que Vo, Bac Ninh, Vietnam",
  Common_HNCommpany_Address: "17th floor, ICON4 Tower, 243A De La Thanh, Lang Thuong, Dong Da, Hanoi, Vietnam",
  Common_BNCommpany_Address: "Km7, Highway 18, Que Vo Industrial Zone, Phuong Lieu, Que Vo, Bac Ninh, Vietnam",
  Common_Tel: "TEL: ",
  Common_Fax: "FAX: ",
  Common_Address: "Address: ",
  Common_Page: 'Page',
  Common_Subject: 'Subject',
  Common_Name: 'Name',
  Common_Email: 'Email',
  Common_Phone: 'Phone',
  Common_Mobile: 'Mobile',
  Common_Message: 'Message',
  Common_Copyright: "Copyright Nippon Steel Metal Products Vietnam Co., Ltd. All Rights Reserved.",
  Common_Upload: "Upload",
  Common_Show: "show",
  Common_Hidden: "hidden",
  Common_Done: "Done",
  Common_Cancel: 'Cancel',
  Common_Yes: 'Yes',
  Common_No: 'No',

  Footer_HeadOffice: "Head Office & Manufacturing Base",
  Footer_HNOffice: "Hanoi Office",


  Site_Banner_title: "Sites",
  Site_AboutManufacturuing_title: "About Nippon Steel Metal Products Co., Ltd.",
  Site_HaNoi: "Hanoi",
  Site_BacNinh: "Bac Ninh",
  Site_AboutRepresentOffice_title: "About Nippon Steel Metal Products Vietnam Co., Ltd.",
  Site_Manufacturuing_title: "Head Office & Manufacturing Base",
  Site_RepresentOffice_title: "Hanoi Office",

  Header_Button_TopPage: "Top page",
  Header_Button_Introduct: "Introduction",
  Header_Button_Products: "Products",
  Header_Button_Recruitment: "Recruitment",
  Header_Button_Contact: "Contact",

  Header_Admin_Button_Manager: "Manage",
  Header_Admin_Button_News: "News",
  Header_Admin_Button_Product: "Product",
  Header_Admin_Button_Recruitment: "Recruitment",

  Toppage_title: "Top page",
  Toppage_Introduction_title: "Company introduction",
  Toppage_company_introduction_part_1: "NSMV is established with the goal of supplying steel pipes and processed steel products (formwork, scaffolding, etc) with quality equivalent to products from Japan and proper price in Vietnam market.",
  Toppage_company_introduction_part_2: "Having inherited achievements of production technology, management experience and significant support from founding members, the goal of NSMV is to become the top leading company in our business field in Vietnam and in South East Asia in future.",

  Toppage_navigate_AboutUs: "About us",
  Toppage_navigate_Project: "Projects",
  Toppage_navigate_Sites: "Sites",
  Toppage_navigate_Recuitment: "Recruitment",

  Toppage_nsmvNews_title: "Topics",
  Toppage_industryNews_title: "What's new in the industry",
  Toppage_cultuteNews_title: "Company News",

  Toppage_Product_title: "Product lineup",
  Toppage_Cerificate_title: "Certificates of quality",
  Toppage_Customer_title: "Who's using our product",
  
  Toppage_catalog: "Download our catalogue",

  Project_Banner_title: "Projects",
  Project_Segment_NationProject: "Vietnam Projects",
  Project_Segment_OverseaProject: "Oversea Projects",
  Project_DienMatTroiHauGiang: 'Hau Giang Solar Power',
  Project_NhietDienNghiSon:"Nghi Son Thermal Power, Thanh Hoa",
  Project_GiengChimLachChay:"Sink well in Lach Huyen port, Hai Phong",
  Project_MayTapTheDuc:'Exercise machine',
  Project_NhaMayNuocThaiHoaLac:'Wastewater treatment plant in Hoa Lac High Industrial Park, Hanoi',
  Project_LandMark81: 'Landmark 81 Building, Ho Chi Minh City',
  Project_DienGioDongHai: 'Dong Hai 1 Wind Power, Bac Lieu',
  Project_CaoTocDaNang: 'Da Nang - Quang Ngai Expressway',
  Project_CauHoangVanThu:'Hoang Van Thu Bridge, Hai Phong',
  Project_CauDongTru: 'Dong Tru Bridge, Dong Anh',
  Project_CauVanUc: 'Bridge over the Van Uc River in Tien Lang, Hai Phong',
  Project_NhietDienMongDuong:'Mong Duong 2 Thermal Power Plant',
  Project_MelodyResident: 'Melody Residences - Hanoi',
  Project_LGHaiPhong: 'LG Hai Phong Project',
  Project_QuocLo45: 'North-South Expressway section Mai Son - National Highway 45',
  Project_Dhaka: 'Dhaka Urban Railway Bridge (Bangladesh)',
  Project_Selabam: 'Selabam Hydroelectricity (Nong Du, Lao PDR)',

  AboutUS_Banner_title: "About us",
  AboutUs_GeneralDirector_title: "General Director",
  AboutUs_GeneralDirector_name: "Ishikawa Takufumi",

  AboutUs_NSMP_Content1:"<b>Nippon Steel Metal Products Co., Ltd. (NSMP)</b>, as the core member of Nippon Steel Corp. Group, is the leading manufacturer in the field of cold roll formed Steel products in Japan. We have developed a high degree of expertise in the application of these products in the construction and civil engineering fields.",
  AboutUs_NSMP_Content2:"<b>Date of Establishment</b>: April 20, 1973",
  AboutUs_NSMP_Content3:"<b>Total Sales</b>: 133 billion JPY (FY2021 Consolidated)",
  AboutUs_NSMP_Content4:"<b>Capital</b>: 53 million USD </br><b>Stockholders</b>: Nippon Steel Corporation 100%",
  AboutUs_NSMP_Content5:"<b>Number of employees</b>: 1,614 people (as of March 31, 2022)",
  AboutUs_NSMP_Link:"Click HERE to navigate to NSMP's homepage",

  AboutUS_NSMV_Content1: "<b>Nippon Steel Metal Products Vietnam (NSMV)</b> is one of the leading companies in the field of manufacturing and processing  various metal-products for buildings and traffic infrastructures in Vietnam. With main office & factory located at Que Vo Industrial Park, Bac Ninh and Hanoi office, NSMV has a prime location, convenient traffic to transport goods to all provinces and cities in the country.",
  AboutUS_NSMV_Content2: "<b>NSMV</b> specializes in manufacturing metal-products such as black steel pipe (box steel pipe, round steel pipe), oval steel pipe, concrete formwork, construction scaffolding, sawing tape and many other products. Our company always ensures high product quality, meeting the strict standards and technical requirements of our customers.",
  AboutUS_NSMV_Content3: "<b>NSMV</b> is proud of having highly experienced and professionally trained staffs and engineers, who always dedicate to work with our customers.",
  AboutUS_NSMV_Content4: "Our company focuses on developing sustainably, creating value for the community, and contributing to development of the country/Vietnam.",
  AboutUS_NSMV_Content5: "We will always be a trustworthy partner of domestic and foreign customers by reliable production process, fair and evidence management, accompanying customers in developing and expanding business together.",


  Product_Banner_title: 'Products',
  Product_Contact: 'Contact for support',

  Recruitment_Banner_title: 'Recruitment',
  Recruitment_LifeAtOffice: 'Life at office',
  Recruitment_LifeAtFactory: 'Life at factory',
  Recruitment_DirectorMessage1: "",
  Recruitment_DirectorMessage2:"Director's Greeting",
  Recuitment_Content: `Welcome to our career page!<br/><br/>
  We're very glad that you are interested in joining our team.<br/>
  We are a dynamic and innovative company with a passion for understanding and respecting both our own cultures, Vietnamese and Japanese, and acting globally to make a difference in the world..<br/>
  We're looking for talented individuals who share our values and vision with the enthusiasm. As a member of our team, you'll have the opportunity to work on challenging and valuable projects that have a real impact on people's lives.<br/>
  We offer a range of exciting career opportunities across a variety of functions and locations including Japan. You'll also have access to a supportive and collaborative work environment, where you can learn and grow both professionally and personally.<br/>
  We're committed to providing our employees with competitive compensation, comprehensive benefits, and most importantly the various opportunities for self-achievement.<br/>
  If you're ready to take your career to the next level, we encourage you to explore our current job openings and apply today!<br/>
  We look forward to hearing from you!`,
  Recuitment_Button: 'For recruitment information <br> click >> <span style="color: #f6a447"> HERE </span> <<',
  
  Contact_Banner_title: 'Contact',
  Contact_Content: 'We welcome any questions, comments, or feedback you may have about our products or services. Please fill out the form below and we will get back to you as soon as possible.',
  Contact_BnOffice: 'Head Office & Manufacturing Base',
  Contact_HnOffice: 'Hanoi Office',
  Contact_ContactUs: 'Contact Us',
  Contact_Submit: 'Submit Form',

  Main_Product: 'Main Products',
  New_Tech_Product: 'Other Products',
  Design_Product: 'Design Products',
  IT_Product:'IT Products',

  Admin_Product_Title_ProductName: "Product name",
  Admin_Product_Title_ProductDetail: "Product detail",
  Admin_Product_Title_Contact: "Contact",
  Admin_Product_Title_Standards: "Standards",
  Admin_Product_Title_Image: "Images",
  Admin_Product_Button_AddStandard: "Add standard",
  Admin_Product_Button_SaveStandard: "Save standard",
  Admin_Product_Button_Preview: "Preview",
  Admin_Product_Button_Submit: "Submit",
  Admin_Product_Button_Save: "Save",
  Common_Source: 'Source',

  Admin_News_Title_Title: "Title",
  Admin_News_Title_Summary: "Summary",
  Admin_News_Button_Upload: "Upload",
  Admin_News_Button_Save: "Save",
  Admin_News_Button_Preview: "Preview",

  Admin_News_Image_Type_Fill: "Fill",
  Admin_News_Image_Type_Contain: "Contain",
  Admin_News_Image_Type_Cover: "Cover",

  News_Category_Culture: "Compaty culture",
  News_Category_Nsmv: "Nsmv's news",
  News_Category_Industy: "Industry's news",
  News_Category_All: "All",

  Admin_Manage_Title_News: "News",
  Admin_Manage_Title_Product: "Products",
  Admin_Manage_DeleteNews_Title: "Delete news",
  Admin_Manage_DeleteNews_Confirm: "Are you sure to delete this news?",
  Admin_Manage_DeleteProduct_Title: "Delete Product",
  Admin_Manage_DeleteProduct_Confirm: "Are you sure to delete this product?",

  Admin_Recuitment_Title_Intro:"Introduction",
  Admin_Recuitment_Title_FactoryImages:"Factory Images", 
  Admin_Recuitment_Title_OfficeImages:"Office images", 
  Admin_Recuitment_Title_RecruitmentLinks:"Recruitment links", 
  Admin_Recuitment_Button_Save:"Save", 
  Admin_Recuitment_Title_Link:"Link", 
  Admin_Recuitment_PlaceholderLink:"Enter reference link...", 

  Slate_Placeholder: "Enter content...",

  Api_Message_NOT_LOGIN : "Login is required",
  Api_Message_INVALID_PARAM : "Input data not valid",
  Api_Message_OK : "Success",
  Api_Message_EMPTY : "Data is empty",
  Api_Message_NOT_FOUND : "No data",
  Api_Message_UPDATED : "Update successed",
  Api_Message_REGISTER : "Registed",
  Api_Message_INVALID_FILE : "File not suppported",
  Api_Message_INVALID_FILE_CULTURE : "Company's culture require image",
  Api_Message_Error: "An error occurred. Please try again later",
  Api_Message_Require_ContactName: "Contact name is required",
  Api_Message_Require_ContactPhone: "Contact phone is required",
  Api_Message_Require_ContactEmail: "Contact email is required",
  Api_Message_Require_Image: "Image required",
  Api_Message_Require_Standard: "At least a standard required",
  Api_Message_Require_StandardName: "Standard's name required",
  Api_Message_Require_StandardDetail: "Standard's detail required",

  Contact_MailForm_Error_Subject: "Please fill in email's subject",
  Contact_MailForm_Error_Name: "Please fill in your name",
  Contact_MailForm_Error_Email: "Please fill in your email",
  Contact_MailForm_Error_Phone: "Please fill in your phone number",
  Contact_MailForm_Success_Message : "Thank you! Your submission has been sent."
};

export default en;





