import { Button } from 'antd'
import React from 'react'
import { useRef } from 'react'
import { SlateEditor } from './components'

import renderHTML from 'react-render-html';
import { useState } from 'react';

 const Sample = () => {
  const slate = useRef()
  const [someHtml, set_someHtml] = useState('<p style = "text-align: undefined">This is editable <b>rich</b> text, <i>much</i> better than a !</p><p style = "text-align: left">123</p><p style = "text-align: center">456</p><p style = "text-align: right">879</p><p style = "text-align: undefined"> </p>')
  return (
    <div>
        <SlateEditor style = {{width: '50vw', margin: '20px'}} ref = {slate}/>
        <Button title='getHtml' onClick={() => {set_someHtml( slate.current.getHtml());}}>get</Button>
        <Button title='getHtml' onClick={() => { slate.current.parseHtml(someHtml);}}>parse</Button>
        {renderHTML(someHtml)}
    </div>
  )
}

export default Sample