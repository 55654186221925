const languageKeys = {
  Language_label: "Language_label",
  Common_ViewMore: "Common_ViewMore",
  Common_Commpany_Address: "Common_Commpany_Address",
  Common_HNCommpany_Address: "Common_HNCommpany_Address",
  Common_BNCommpany_Address: "Common_BNCommpany_Address",
  Common_Tel: "Common_Tel",
  Common_Fax: "Common_Fax",
  Common_Address: "Common_Address",
  Common_Page: 'Common_Page',
  Common_Subject: 'Common_Subject',
  Common_Name: 'Common_Name',
  Common_Email: 'Common_Email',
  Common_Phone: 'Common_Phone',
  Common_Mobile: 'Common_Mobile',
  Common_Message: 'Common_Message',
  Common_Copyright: "Common_Copyright",
  Common_Upload: "Common_Upload",
  Common_Show: "Common_Show",
  Common_Hidden: "Common_Hidden",
  Common_Done: "Common_Done",
  Common_Cancel: 'Common_Cancel',
  Common_Source: "Common_Source",
  Common_Yes: 'Common_Yes',
  Common_No: 'Common_No',

  Footer_HeadOffice: "Footer_HeadOffice",
  Footer_HNOffice: "Footer_HNOffice",

  Site_Banner_title: "Site_Banner_title",
  Site_AboutManufacturuing_title: "Site_AboutManufacturuing_title",
  Site_HaNoi: "Site_HaNoi",
  Site_BacNinh: "Site_BacNinh",
  Site_AboutRepresentOffice_title: "Site_AboutRepresentOffice_title",

  Site_Manufacturuing_title: "Site_Manufacturuing_title",
  Site_RepresentOffice_title: "Site_RepresentOffice_title",

  Header_Button_TopPage: "Header_Button_TopPage",
  Header_Button_Introduct: "Header_Button_Introduct",
  Header_Button_Products: "Header_Button_Products",
  Header_Button_Recruitment: "Header_Button_Recruitment",
  Header_Button_Contact: "Header_Button_Contact",

  Header_Admin_Button_Manager: "Header_Admin_Button_Manager",
  Header_Admin_Button_News: "Header_Admin_Button_News",
  Header_Admin_Button_Product: "Header_Admin_Button_Product",
  Header_Admin_Button_Recruitment: "Header_Admin_Button_Recruitment",


  //key trang top page
  Toppage_title: "Toppage_title",
  Toppage_Introduction_title: "Toppage_Introduction_title",
  Toppage_company_introduction_part_1: "Toppage_company_introduction_part_1",
  Toppage_company_introduction_part_2: "Toppage_company_introduction_part_2",

  Toppage_navigate_AboutUs: "Toppage_navigate_AboutUs",
  Toppage_navigate_Project: "Toppage_navigate_Project",
  Toppage_navigate_Sites: "Toppage_navigate_Sites",
  Toppage_navigate_Recuitment: "Toppage_navigate_Recuitment",

  Toppage_nsmvNews_title: "Toppage_nsmvNews_title",
  Toppage_industryNews_title: "Toppage_industryNews_title",
  Toppage_cultuteNews_title: "Toppage_cultuteNews_title",

  Toppage_Product_title: "Toppage_Product_title",
  Toppage_Cerificate_title: "Toppage_Cerificate_title",
  Toppage_Customer_title: "Toppage_Customer_title",

  Toppage_catalog: "Toppage_catalog",
  
  Project_Banner_title: "Project_Banner_title",
  Project_Segment_NationProject: "Project_Segment_NationProject",
  Project_Segment_OverseaProject: "Project_Segment_OverseaProject",
  Project_DienMatTroiHauGiang: 'Project_DienMatTroiHauGiang',
  Project_NhietDienNghiSon:"Project_NhietDienNghiSon",
  Project_GiengChimLachChay:"Project_GiengChimLachChay",
  Project_MayTapTheDuc:'Project_MayTapTheDuc',
  Project_NhaMayNuocThaiHoaLac:'Project_NhaMayNuocThaiHoaLac',
  Project_LandMark81: 'Project_LandMark81',
  Project_DienGioDongHai: 'Project_DienGioDongHai',
  Project_CaoTocDaNang: 'Project_CaoTocDaNang',
  Project_CauHoangVanThu:'Project_CauHoangVanThu',
  Project_CauDongTru: 'Project_CauDongTru',
  Project_CauVanUc: 'Project_CauVanUc',
  Project_NhietDienMongDuong:'Project_NhietDienMongDuong',
  Project_MelodyResident: 'Project_MelodyResident',
  Project_LGHaiPhong: 'Project_LGHaiPhong',
  Project_QuocLo45: 'Project_QuocLo45',
  Project_Dhaka: 'Project_Dhaka',
  Project_Selabam: 'Project_Selabam',

  AboutUS_Banner_title: "AboutUS_Banner_title",
  AboutUs_GeneralDirector_title: "AboutUs_GeneralDirector_title",
  AboutUs_GeneralDirector_name: "AboutUs_GeneralDirector_name",


  AboutUs_NSMP_Content1:"AboutUs_NSMP_Content1",
  AboutUs_NSMP_Content2:"AboutUs_NSMP_Content2",
  AboutUs_NSMP_Content3:"AboutUs_NSMP_Content3",
  AboutUs_NSMP_Content4:"AboutUs_NSMP_Content4",
  AboutUs_NSMP_Content5:"AboutUs_NSMP_Content5",
  AboutUs_NSMP_Link:"AboutUs_NSMP_Link",

  AboutUS_NSMV_Content1: "AboutUS_NSMV_Content1",
  AboutUS_NSMV_Content2: "AboutUS_NSMV_Content2",
  AboutUS_NSMV_Content3: "AboutUS_NSMV_Content3",
  AboutUS_NSMV_Content4: "AboutUS_NSMV_Content4",
  AboutUS_NSMV_Content5: "AboutUS_NSMV_Content5",

  Recruitment_Banner_title: 'Recruitment_Banner_title',
  Recruitment_LifeAtOffice: 'Recruitment_LifeAtOffice',
  Recruitment_LifeAtFactory: 'Recruitment_LifeAtFactory',
  Recruitment_DirectorMessage1: 'Recruitment_DirectorMessage1',
  Recruitment_DirectorMessage2: 'Recruitment_DirectorMessage2',
  Recuitment_Content: 'Recuitment_Content',
  Recuitment_Button: 'Recuitment_Button',

  Product_Banner_title: 'Product_Banner_title',
  Product_Contact: 'Product_Contact',

  Contact_Banner_title: 'Contact_Banner_title',
  Contact_BnOffice: 'Contact_BnOffice',
  Contact_HnOffice: 'Contact_HnOffice',
  Contact_ContactUs: 'Contact_ContactUs',
  Contact_Submit: 'Contact_Submit',
  Contact_Content: 'Contact_Content',

  Main_Product: '',
  New_Tech_Product: '',
  Design_Product: '',
  IT_Product:'',


  Admin_Product_Title_ProductName: "Admin_Product_Title_ProductName",
  Admin_Product_Title_ProductDetail: "Admin_Product_Title_ProductDetail",
  Admin_Product_Title_Contact: "Admin_Product_Title_Contact",
  Admin_Product_Title_Standards: "Admin_Product_Title_Standards",
  Admin_Product_Title_Image: "Admin_Product_Title_Image",
  Admin_Product_Button_AddStandard: "Admin_Product_Button_AddStandard",
  Admin_Product_Button_SaveStandard: "Admin_Product_Button_SaveStandard",
  Admin_Product_Button_Preview: "Admin_Product_Button_Preview",
  Admin_Product_Button_Submit: "Admin_Product_Button_Submit",
  Admin_Product_Button_Save: "Admin_Product_Button_Save",

  Admin_News_Title_Title: "Admin_News_Title_Title",
  Admin_News_Title_Summary: "Admin_News_Title_Summary",
  Admin_News_Button_Upload: "Admin_News_Button_Upload",
  Admin_News_Button_Save: "Admin_News_Button_Save",
  Admin_News_Button_Preview: "Admin_News_Button_Preview",

  Admin_News_Image_Type_Fill: "Admin_News_Image_Type_Fill",
  Admin_News_Image_Type_Contain: "Admin_News_Image_Type_Contain",
  Admin_News_Image_Type_Cover: "Admin_News_Image_Type_Cover",

  News_Category_Culture: "News_Category_Culture",
  News_Category_Nsmv: "News_Category_Nsmv",
  News_Category_Industy: "News_Category_Industy",
  News_Category_All: "News_Category_All",

  Admin_Manage_Title_News: "Admin_Manage_Title_News",
  Admin_Manage_Title_Product: "Admin_Manage_Title_Product",
  Admin_Manage_DeleteNews_Title: "Admin_Manage_DeleteNews_Title",
  Admin_Manage_DeleteNews_Confirm: "Admin_Manage_DeleteNews_Confirm",
  Admin_Manage_DeleteProduct_Title: "Admin_Manage_DeleteProduct_Title",
  Admin_Manage_DeleteProduct_Confirm: "Admin_Manage_DeleteProduct_Confirm",


  Admin_Recuitment_Title_Intro:"Admin_Recuitment_Title_Intro",
  Admin_Recuitment_Title_FactoryImages:"Admin_Recuitment_Title_FactoryImages", 
  Admin_Recuitment_Title_OfficeImages:"Admin_Recuitment_Title_OfficeImages", 
  Admin_Recuitment_Title_RecruitmentLinks:"Admin_Recuitment_Title_RecruitmentLinks", 
  Admin_Recuitment_Button_Save:"Admin_Recuitment_Button_Save", 
  Admin_Recuitment_Title_Link:"Admin_Recuitment_Title_Link", 
  Admin_Recuitment_PlaceholderLink:"Admin_Recuitment_PlaceholderLink", 

  Slate_Placeholder: "Slate_Placeholder",



  Api_Message_NOT_LOGIN : "Api_Message_NOT_LOGIN",
  Api_Message_INVALID_PARAM : "Api_Message_INVALID_PARAM",
  Api_Message_OK : "Api_Message_OK",
  Api_Message_EMPTY : "Api_Message_EMPTY",
  Api_Message_NOT_FOUND : "Api_Message_NOT_FOUND",
  Api_Message_UPDATED : "Api_Message_UPDATED",
  Api_Message_REGISTER : "Api_Message_REGISTER",
  Api_Message_INVALID_FILE : "Api_Message_INVALID_FILE",
  Api_Message_INVALID_FILE_CULTURE : "Api_Message_INVALID_FILE_CULTURE",
  Api_Message_Error: "Api_Message_Error",
  Api_Message_Require_Image: "Api_Message_Require_Image",
  Api_Message_Require_ContactName: "Api_Message_Require_ContactName",
  Api_Message_Require_ContactPhone: "Api_Message_Require_ContactPhone",
  Api_Message_Require_ContactEmail: "Api_Message_Require_ContactEmail",
  Api_Message_Require_Standard: "Api_Message_Require_Standard",
  Api_Message_Require_StandardName: "Api_Message_Require_StandardName",
  Api_Message_Require_StandardDetail: "Api_Message_Require_StandardDetail",

  Contact_MailForm_Error_Subject: "Contact_MailForm_Error_Subject",
  Contact_MailForm_Error_Name: "Contact_MailForm_Error_Name",
  Contact_MailForm_Error_Email: "Contact_MailForm_Error_Email",
  Contact_MailForm_Error_Phone: "Contact_MailForm_Error_Phone",
  Contact_MailForm_Success_Message : "Contact_MailForm_Success_Message"
  
};

export default languageKeys;
