import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { paths } from "../constants";
import { NotFound } from "../pages/common";
import AuthRoute from "./AuthRoute";
import MainRoute from "./MainRoute";
import AdminRoute from "./AdminRoute";
import { PrivateRoute, PublicRoute } from "./ConfigRoutes";
import '../assets/style/mixins.less'
import { useDispatch, useSelector } from "react-redux";
import { message, Spin } from "antd";
import { resetMessage } from "../ducks/slices/appSlice";
import { useEffect } from "react";
import { HomePage } from "../pages/main";
import DefaultRoute from "./DefaultRoute";

const RootRoute = () => {
  const isLoading = useSelector(state => state.app.isLoading)
  
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch()
  const messages = useSelector(state => state.app.message)

  useEffect(() => {
    if (!!messages) {
      messageApi.open(messages).then(() => {
        dispatch(resetMessage())
      });
    }
  }, [messages, dispatch, messageApi]);

  return (
    <BrowserRouter>
    {contextHolder}
      <Switch>
        <PublicRoute path={paths.auth} component={AuthRoute} />
        <PublicRoute path={paths.main_tag} component={MainRoute} />
        <PrivateRoute path={paths.admin_tag} component={AdminRoute} />
        <PublicRoute path={paths.main} component={DefaultRoute} />

        <Route
          path={paths.auth}
          render={(props) => <AuthRoute key={props.location.key} />}
        />
        <Route
          path={paths.main_tag}
          render={(props) => <MainRoute key={props.location.key} />}
          />
        <Route
          path={paths.admin_tag}
          render={(props) => <AdminRoute key={props.location.key} />}
          />

        <Redirect exact from={paths.main} to={paths.main} />
        <Redirect exact from={paths.home_page_route} to={paths.main} />
        <Route component={NotFound} />
      </Switch>
      {isLoading && <div className = 'loading'>
        <Spin size='large' />
        </div>}
    </BrowserRouter>
  );
};

export default RootRoute;
