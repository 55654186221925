import { InboxOutlined } from '@ant-design/icons';
import { Modal, message, Upload, Button } from 'antd';
import axios from 'axios';
import React from 'react'
import { useImperativeHandle } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { apis } from '../../../../constants';
import { getBase64 } from '../../../../helpers';
import uploadModal from './uploadModal.module.less'

const UploadModal = React.forwardRef((props, ref) => {
  let { dataUploadSuccess = () => {}, maxCount = 1, onClose = () => {}, noUpload = false } = props;

  const auth = useSelector(state => state.auth)
  const [visible, set_visible] = useState(false)
  const [uploadData, set_uploadData] = useState([])

  useImperativeHandle(ref, () => {
    return {
      open() {
        set_visible(true)
      },
      close() {
        set_visible(false)
      }
    };
  }, []);

  const customRequest = (options) => {
    if(noUpload){
      return null
    }
    const { onSuccess, onError, file, onProgress } = options;
    const formData = new FormData();
    const config = {
      headers: {
        Authorization: auth.token,
        
      },
      onUploadProgress: event => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      }
    };
    formData.append("files", file);
    axios
      .post(apis.Post_Image_Upload, formData, config)
      .then(res => {
        onSuccess(res.data.data[0]);
      })
      .catch(err => {
        const error = new Error('Some error');
        onError({ event: error });
      });
  }

  const draggerProps = {
    name: 'files',
    multiple: maxCount > 1,
    maxCount,
    listType: 'picture',
    action : null,
    onChange(info) {
      info.fileList.filter(file => !file.type.includes("image"))
        .map((file, idx) => info.fileList.splice(idx, 1));
      const { status } = info.file;
      set_uploadData(info.fileList)
      if (status !== 'uploading') {
      }
      if (status === 'done') {
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
    },
  };

  const confirmUpload = () => {
    dataUploadSuccess(uploadData)
    set_uploadData([])
    set_visible(false)
  }

  return <Modal
    open={visible}
    wrapClassName={uploadModal['container']}
    mask
    maskClosable={true}
    destroyOnClose
    footer={null}
    onCancel={() => {set_visible(false); onClose()}}>
    <Upload.Dragger {...draggerProps} customRequest = {customRequest} beforeUpload = {async (file) => {
      if(!noUpload) return true;         
      file.url = await getBase64(file);          
      set_uploadData([...uploadData, file]);   
      return false
      }}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Click or drag file to this area to upload</p>
      <p className="ant-upload-hint">
        Support for a single or bulk upload. Strictly prohibit from uploading company data or other
        band files
      </p>
    </Upload.Dragger>
    <Button type='primary' style={{ alignSelf: 'flex-end', marginTop: '10px' }}
      onClick={confirmUpload}>Done</Button>

  </Modal>

})

export default UploadModal;