import { DragOutlined } from '@ant-design/icons';
import React, { useEffect, useRef } from 'react'
import { useSelected, useFocused } from "slate-react";
import useResize from '../CustomHooks/useResize';
import elementCss from './element.module.less';
import { throttle } from '../../../../../helpers';


const ImageElement = ({ attributes, element, children, onChangeSize = () => {} }) => {
  const {url,alt} = element;
  const selected = useSelected();
  const focused = useFocused();
  const [size,onMouseDown,resizing, setFileSize ] = useResize();
  const imgRef = useRef()

  
  useEffect(() => {
    throttle(function () {
      onChangeSize(size)
    }, 1000);
  }, [size])


  useEffect(() => {
    if( (!size.width || !size.height) && !!imgRef.current.clientWidth && !!imgRef.current.clientHeight){
      setFileSize({width:imgRef.current.clientWidth , height:imgRef.current.clientHeight })
    }
  }, [imgRef.current?.clientWidth, imgRef.current?.clientHeight, setFileSize])


  const getAlignElement = () => {
    switch(element.align){
      case 'left':
        return {justifyContent: 'flex-start'}
      case 'right':
        return {justifyContent: 'flex-end'}
      default:
        return {justifyContent: 'center'}
    }
  }

  return (
    <div style={{display:'flex', width: '100%', ...getAlignElement()}} >
    <div
      {...attributes}
      className={elementCss['embed']}
      style={{display:'flex',boxShadow: selected && focused &&  '0 0 3px 3px lightgray', justifyContent: 'center'}}
      {...element.attr}
    >
      <div contentEditable={false} style={{width: (!size.width && !!element.size) ? element.size.width : `${size.width}px`,height: `${size.height}px`, position: 'relative'}} >
        
        <img ref = {imgRef} alt={alt} src={url} className = {elementCss['img-contain']}/>
        {
          selected && 
          <div onMouseDown={onMouseDown} style={{width:'15px',height:'15px',opacity:1,background:'transparent', position: 'absolute', right: '-10px', bottom: '-10px'}}><DragOutlined /></div>
        }
      </div>
      {children}
    </div>

    </div>
  );
};
export default ImageElement;