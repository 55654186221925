import { languageKeys, languages, Translate } from "../i18n";
import { en, jp, vi } from "../i18n/languages";

const configs = {
    Domain: 'https://api.nsmv.com.vn/api/',
    // Domain: 'http://172.18.101.74:8181/homepage_backend/api/',

   


    Language_Dropdown_Keys: [
        {
            value: languages.tieng_anh,
            label: en.Language_label
        },
        {
            value: languages.tieng_nhat_api,
            label: jp.Language_label
        },
        {
            value: languages.tieng_viet,
            label: vi.Language_label
        }
    ],

    News_Category_Keys: [
        {
            value: 'culture',
            label: Translate(languageKeys.News_Category_Culture)
        },
        {
            value: 'nsmv',
            label: Translate(languageKeys.News_Category_Nsmv)
        },
        {
            value: 'industry',
            label: Translate(languageKeys.News_Category_Industy)
        }
    ],

    Admin_News_Category_Keys: [
        {
            value: '',
            label: Translate(languageKeys.News_Category_All)
        },
        {
            value: 'culture',
            label: Translate(languageKeys.News_Category_Culture)
        },
        {
            value: 'nsmv',
            label: Translate(languageKeys.News_Category_Nsmv)
        },
        {
            value: 'industry',
            label: Translate(languageKeys.News_Category_Industy)
        }
    ]
};

export default configs;
