import { Col, Layout, Row } from "antd";
import { NsmvLogoImg } from "../../../assets/img";
import cn from "classnames";
import { languageKeys, Translate } from "../../../i18n";
import footer from "./footer.module.less";
import { paths } from "../../../constants";
import { useHistory } from "react-router-dom";

const Footer = ({ children, ...props }) => {
  const history = useHistory()
  return (
    <Layout.Footer className={footer["container"]} {...props}>
      <Row gutter={15} style={{ width: '100%' }}>
        <Col span={6} style={{ display: 'flex', justifyContent: 'center' }} onDoubleClick={() => history.push(paths.admin_list)}>
          <img src = {NsmvLogoImg}  className={footer["img-contain"]} alt="logo"/>
        </Col>
        <Col span={18}  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', justifySelf: 'center' }} flex={'auto'}>
          <Row gutter={20}>
            <Col span={12}>
            <b className={footer["footer-head"]}>{Translate(languageKeys.Footer_HeadOffice)}</b>
              <div className={footer["footer-detail"]}>
                {Translate(languageKeys.Common_BNCommpany_Address)}
                <p>{Translate(languageKeys.Common_Tel)}+84-222 3617568 <p>{Translate(languageKeys.Common_Fax)} 0222 361 7567</p></p>
                <p></p>
                <p>Email: contact@nsmv.com.vn</p>
              </div>
            </Col>
            <Col span={12} >
              <b className={footer["footer-head"]}>{Translate(languageKeys.Footer_HNOffice)}</b>
              <div className={cn(footer["footer-detail"], footer["right"])}>
                {Translate(languageKeys.Common_HNCommpany_Address)}
                <p>{Translate(languageKeys.Common_Tel)}+84-243 227 2083</p>
                <p>Email: contact@nsmv.com.vn</p>
              </div>
            </Col>
          </Row>
          <b className={footer["copyright"]}>{Translate(languageKeys.Common_Copyright)}</b>
        </Col>
      </Row>
    </Layout.Footer>
  );
};

export default Footer;
