const vi = {
  Language_label : "Tiếng Việt",
  Common_ViewMore: "Xem thêm",
  Common_Commpany_Address: "Km 7, Quốc lộ 18, KCN Quế Võ, Phương Liễu, Quế Võ, Bắc Ninh, Việt Nam",
  Common_HNCommpany_Address: "Tầng 17, tòa nhà ICON4, 243A Đê La Thành, phường Láng Thượng, Đống Đa, Hà Nội, Việt Nam",
  Common_BNCommpany_Address: "Km 7, Quốc lộ 18, KCN Quế Võ, Phương Liễu, Quế Võ, Bắc Ninh, Việt Nam",
  Common_Tel: "Điện thoại: ",
  Common_Fax: "FAX: ",
  Common_Address: "Địa chỉ: ",
  Common_Page: 'Trang',
  Common_Subject: 'Chủ đề',
  Common_Name: 'Tên',
  Common_Email: 'Email',
  Common_Phone: 'Điện thoại',
  Common_Mobile: 'Di động',
  Common_Message: 'Lời nhắn',
  Common_Copyright: "Bản quyền thuộc về công ty Nippon Steel Metal Products Vietnam",
  Common_Upload: "Tải lên",
  Common_Show: "Hiện",
  Common_Hidden: "Ẩn",
  Common_Done: "Xong",
  Common_Cancel: 'Hủy',
  Common_Source: 'Nguồn',
  Common_Yes: 'Có',
  Common_No: 'Không',

  Footer_HeadOffice: "Trụ sở chính & Nhà máy",
  Footer_HNOffice: "Văn phòng Hà Nội",

  Site_Banner_title: "Trụ sở",
  Site_AboutManufacturuing_title: "Về công ty Nippon Steel Metal Products",
  Site_HaNoi: "Hà Nội",
  Site_BacNinh: "Bắc Ninh",
  Site_AboutRepresentOffice_title: "Về công ty Nippon Steel Metal Products Vietnam",
  Site_Manufacturuing_title: "Trụ sở chính & Nhà máy",
  Site_RepresentOffice_title: "Văn phòng Hà Nội",

  Header_Button_TopPage: "Trang chủ",
  Header_Button_Introduct: "Giới thiệu",
  Header_Button_Products: "Sản phẩm",
  Header_Button_Recruitment: "Tuyển dụng",
  Header_Button_Contact: "Liên hệ",

  Header_Admin_Button_Manager: "Quản lí",
  Header_Admin_Button_News: "Tin tức",
  Header_Admin_Button_Product: "Sản phẩm",
  Header_Admin_Button_Recruitment: "Tuyển dụng",

  Toppage_title: "TRANG CHỦ",
  Toppage_Introduction_title: "Giới thiệu chung",
  Toppage_company_introduction_part_1: "NSMV được thành lập với mục tiêu cung cấp các sản phẩm ống thép và thép gia công (cốp pha, giàn giáo) với chất lượng tương đương với các sản phẩm từ Nhật Bản với giá thành hợp lý tại thị trường Việt Nam.",
  Toppage_company_introduction_part_2: "Bằng việc liên tục tích lũy những thành tựu về công nghệ sản xuất cũng như dịch vụ khách hàng,... từ những ngày đầu thành lập, mục tiêu của NSMV là trở thành công ty hàng đầu trong lĩnh vực sản xuất vật liệu xây dựng tại Việt Nam và khu vực Đông Nam Á trong tương lai.",

  Toppage_navigate_AboutUs: "Thông tin",
  Toppage_navigate_Project: "Dự án",
  Toppage_navigate_Sites: "Trụ sở",
  Toppage_navigate_Recuitment: "Tuyển dụng",

  Toppage_nsmvNews_title: "Topics",
  Toppage_industryNews_title: "Ngành công nghiệp của chúng tôi có gì mới",
  Toppage_cultuteNews_title: "Tin tức doanh nghiệp",

  Toppage_Product_title: "Sản phẩm của chúng tôi",
  Toppage_Cerificate_title: "Chứng chỉ tiêu chuẩn chất lượng",
  Toppage_Customer_title: "Người dùng tin tưởng của chúng tôi",

  Toppage_catalog: "Tải catalogue",

  Project_Banner_title: "Dự án",
  Project_Segment_NationProject: "Dự án trong nước",
  Project_Segment_OverseaProject: "Dự án quốc tế",
  Project_DienMatTroiHauGiang: 'Điện mặt trời Hậu Giang',
  Project_NhietDienNghiSon:"Nhiệt điện Nghi Sơn, Thanh Hóa",
  Project_GiengChimLachChay:"Giếng chìm cảng Lạch Huyện, Hải Phòng",
  Project_MayTapTheDuc:'Máy tập thể dục',
  Project_NhaMayNuocThaiHoaLac:'Nhà máy xử lý nước thải KCN cao Hòa Lạc, Hà Nội',
  Project_LandMark81: 'Tòa nhà Landmark 81, Tp Hồ Chí Minh',
  Project_DienGioDongHai: 'Điện gió Đông Hải 1, Bạc Liêu',
  Project_CaoTocDaNang: 'Tuyến cao tốc Đà Nẵng - Quảng Ngãi',
  Project_CauHoangVanThu:'Cầu Hoàng Văn Thụ, Hải Phòng',
  Project_CauDongTru: 'Cầu Đông Trù, Đông Anh, Hà Nội',
  Project_CauVanUc: 'Cầu Vượt sông Văn Úc, Tiên Lãng, Hải Phòng',
  Project_NhietDienMongDuong:'Nhà máy Nhiệt điện Mông Dương 2, Cẩm Phả, Quảng Ninh ',
  Project_MelodyResident: 'Chung cư Melody Residences- Hà Nội',
  Project_LGHaiPhong: 'Dự án LG, Cát Hải, Hải Phòng',
  Project_QuocLo45: 'Cao tốc Bắc Nam Đoạn Mai Sơn - Quốc lộ 45 ',
  Project_Dhaka: 'Cầu đường sắt đô thị Dhaka (Bangladesh)',
  Project_Selabam: 'Thủy điện Selabam (Nong Du, CHDCND Lào)',

  AboutUS_Banner_title: "Về chúng tôi",
  AboutUs_GeneralDirector_title: "Tổng giám đốc",
  AboutUs_GeneralDirector_name: "Ishikawa Takufumi",

  AboutUs_NSMP_Content1:"<b>Công ty Nippon Steel Metal Products (NSMP)</b>, với tư cách là thành viên cốt lõi của Tập đoàn thép Nippon Steel, là nhà sản xuất hàng đầu trong lĩnh vực sản phẩm thép, ứng dụng chủ yếu trong lĩnh vực xây dựng và các công trình dân dụng tại Nhật Bản.",
  AboutUs_NSMP_Content2:"<b>Ngày thành lập</b>: 20 tháng 4 năm 1973",
  AboutUs_NSMP_Content3:"<b>Tổng doanh số</b>: 1 tỉ USD (dữ liệu tổng hợp năm 2021)",
  AboutUs_NSMP_Content4:"<b>Vốn tài chính</b>: 53 triệu USD",
  AboutUs_NSMP_Content5:"<b>Tổng số nhân viên</b>: 1614 người (dữ liệu ngày 31/03/2022)",
  AboutUs_NSMP_Link:"Nhấn vào đây để tới trang chủ của NSMP",

  AboutUS_NSMV_Content1: "<b>Nippon Steel Metal Products Vietnam (NSMV)</b> là một trong những công ty hàng đầu trong lĩnh vực sản xuất và gia công các loại sản phẩm kim loại cho các công trình xây dựng và hạ tầng giao thông tại Việt Nam. Với trụ sở chính và nhà máy đặt tại khu công nghiệp Quế Võ, Bắc Ninh cùng với văn phòng được đặt tại Hà Nội, NSMV có một vị trí đắc địa thuận tiện cho việc vận chuyển sản phẩm chất lượng cao đến mọi tỉnh thành trên cả nước.",
  AboutUS_NSMV_Content2: "<b>NSMV</b> chuyên sản xuất các sản phẩm từ kim loại như: thép ống đen (thép ống hộp, thép ống tròn), thép ống oval, cốp pha bê tông, giàn giáo xây dựng và nhiều sản phẩm khác.",
  AboutUS_NSMV_Content3: "Với kinh nghiệm phong phú và trình độ chuyên môn cao của đội ngũ nhân viên, kỹ sư cùng với sự chỉ đạo sản xuất của đội ngũ quản lý có kinh nghiệm lâu năm, chúng tôi nỗ lực hết mình để luôn đáp ứng được những nhu cầu của khách hàng.",
  AboutUS_NSMV_Content4: "Công ty cũng chú trọng phát triển bền vững, tạo ra giá trị cho cộng đồng, luôn đóng góp cho sự phát triển của đất nước.",
  AboutUS_NSMV_Content5: "<b>NSMV</b> sẽ luôn là đối tác tin cậy của khách hàng trong và ngoài nước bằng cách thực hiện quy trình sản xuất đáng tin cậy, quản lý công bằng và minh bạch, đồng thời chúng tôi sẽ luôn đồng hành cùng khách hàng trong việc cùng nhau phát triển và mở rộng kinh doanh.",


  Product_Banner_title: 'Sản phẩm',
  Product_Contact: 'Liên hệ',

  Recruitment_Banner_title: 'Tuyển dụng',
  Recruitment_LifeAtOffice: 'Môi trường ở văn phòng',
  Recruitment_LifeAtFactory: 'Môi trường ở nhà máy',
  Recruitment_DirectorMessage1: '',
  Recruitment_DirectorMessage2: 'Lời chào từ Tổng giám đốc',
  Recuitment_Content: `Chào mừng đến với trang tuyển dụng của chúng tôi!<br/><br/>
  Chúng tôi rất vui vì bạn quan tâm đến việc tham gia với đội ngũ của chúng tôi.<br/> 
  Chúng tôi là một công ty năng động và sáng tạo với niềm đam mê tìm hiểu và tôn trọng cả hai nền văn hóa của cả Việt Nam và Nhật Bản, đồng thời hoạt động trên phạm vi toàn cầu để tạo nên sự khác biệt trên thế giới.<br/>
  Chúng tôi đang tìm kiếm những cá nhân tài năng, những người nhiệt tình chia sẻ các giá trị và tầm nhìn của chúng tôi.<br/>
  Là thành viên của NSMV, bạn sẽ có cơ hội làm việc trong các dự án đầy thử thách và có giá trị, có tác động thực sự đến cuộc sống của mọi người.<br/>
  Chúng tôi mang đến nhiều cơ hội nghề nghiệp hấp dẫn ở nhiều vị trí công việc và địa phương khác nhau, bao gồm cả Nhật Bản.<br/>
  Bạn cũng sẽ được tiếp cận với môi trường làm việc hòa đồng cùng với những đồng nghiệp luôn sẵn sàng hợp tác và hỗ trợ, nơi bạn có thể học hỏi và phát triển toàn diện về mặt chuyên môn giúp bạn nâng cao trình độ của bản thân.<br/>
  Chúng tôi cam kết cung cấp cho nhân viên của mình mức thù lao cạnh tranh, lợi ích toàn diện và quan trọng nhất là nhiều cơ hội để đạt được thành tích bản thân.<br/>
  Nếu bạn đã sẵn sàng đưa sự nghiệp của mình lên một tầm cao mới, chúng tôi khuyến khích bạn khám phá các cơ hội việc làm hiện tại của chúng tôi và nộp đơn ngay hôm nay!<br/>
  Chúng tôi rất mong nhận được thông tin từ bạn!`,
  Recuitment_Button: 'Thông tin tuyển dụng <br> bấm >> <span style="color: #f6a447">Vào đây</span><<',

  Contact_Banner_title: 'Liên hệ',
  Contact_Content: 'Chúng tôi hoan nghênh mọi câu hỏi, nhận xét hoặc phản hồi mà bạn có về các sản phẩm hoặc dịch vụ của chúng tôi. Vui lòng điền vào biểu mẫu bên dưới và chúng tôi sẽ liên hệ lại với bạn sớm nhất có thể.',
  Contact_BnOffice: 'Trụ sở chính & Nhà máy',
  Contact_HnOffice: 'Văn phòng Hà Nội',
  Contact_ContactUs: 'Liên hệ',
  Contact_Submit: 'Gửi yêu cầu',

  Main_Product: 'Các sản phẩm chính',
  New_Tech_Product: 'Sản phẩm Công nghệ mới',
  Design_Product: 'Sản phẩm Thiết kế',
  IT_Product:'Công nghệ phần mềm',

  Admin_Product_Title_ProductName: "Tên sản phẩm",
  Admin_Product_Title_ProductDetail: "Mô tả sản phẩm",
  Admin_Product_Title_Contact: "Liên hệ",
  Admin_Product_Title_Standards: "Tiêu chuẩn",
  Admin_Product_Title_Image: "Ảnh sản phẩm",
  Admin_Product_Button_AddStandard: "Thêm tiêu chuẩn",
  Admin_Product_Button_SaveStandard: "Lưu tiêu chuẩn",
  Admin_Product_Button_Preview: "Xem trước",
  Admin_Product_Button_Submit: "Tạo",
  Admin_Product_Button_Save: "Lưu",

  Admin_News_Title_Title: "Tiêu để",
  Admin_News_Title_Summary: "Tóm tắt",
  Admin_News_Button_Upload: "Tải lên",
  Admin_News_Button_Save: "Lưu",
  Admin_News_Button_Preview: "Xem trước",

  Admin_News_Image_Type_Fill: "Bao phủ",
  Admin_News_Image_Type_Contain: "Bao hàm",
  Admin_News_Image_Type_Cover: "Trung tâm",

  News_Category_Culture: "Tin tức doanh nghiệp",
  News_Category_Nsmv: "Tin Nsmv",
  News_Category_Industy: "Tin nhà máy",
  News_Category_All: "Tất cả",

  Admin_Manage_Title_News: "Tin tức",
  Admin_Manage_Title_Product: "Sản phẩm",
  Admin_Manage_DeleteNews_Title: "Xóa tin",
  Admin_Manage_DeleteNews_Confirm: "Bạn có chắc chắn muốn xóa tin này?",
  Admin_Manage_DeleteProduct_Title: "Xóa sản phẩm",
  Admin_Manage_DeleteProduct_Confirm: "Bạn có chắc chắn muốn xóa sản phẩm này?",

  Admin_Recuitment_Title_Intro:"Lời mở đầu",
  Admin_Recuitment_Title_FactoryImages:"Ảnh nhà máy", 
  Admin_Recuitment_Title_OfficeImages:"Ảnh văn phòng", 
  Admin_Recuitment_Title_RecruitmentLinks:"Đường dẫn tuyển dụng", 
  Admin_Recuitment_Button_Save:"Lưu", 
  Admin_Recuitment_Title_Link:"Đường dẫn", 
  Admin_Recuitment_PlaceholderLink:"Nhập đường dẫn...", 

  Slate_Placeholder: "Nhập nội dung...",

  Api_Message_NOT_LOGIN : "Thao tác yêu cầu đăng nhập",
  Api_Message_INVALID_PARAM : "Dữ liệu không đầy đủ",
  Api_Message_OK : "Thành công",
  Api_Message_EMPTY : "Không có dữ liệu",
  Api_Message_NOT_FOUND : "Không tim thấy dữ liệu",
  Api_Message_UPDATED : "Cập nhật thành công",
  Api_Message_REGISTER : "Đăng ký thành công",
  Api_Message_INVALID_FILE : "Tệp không hỗ trợ",
  Api_Message_INVALID_FILE_CULTURE : "Mục văn hóa công ty yêu cầu có ảnh đại diện",
  Api_Message_Error: "Có lỗi xảy ra. Vui lòng thử lại sau",
  Api_Message_Require_Image: "Ảnh tải lên là bắt buộc",
  Api_Message_Require_ContactName: "Tên liên hệ tải lên là bắt buộc",
  Api_Message_Require_ContactPhone: "Điện thoại liên hệ là bắt buộc",
  Api_Message_Require_ContactEmail: "Email liên hệ là bắt buộc",
  Api_Message_Require_Standard: "Cần ít nhất một tiêu chuẩn được thêm vào",
  Api_Message_Require_StandardName: "Tiêu chuẩn cần có tiêu đề",
  Api_Message_Require_StandardDetail: "Tiêu chuẩn cần có nội dung",

  Contact_MailForm_Error_Subject: "Hãy điền tiêu đề email",
  Contact_MailForm_Error_Name: "Hãy điền tên của bạn",
  Contact_MailForm_Error_Email: "Hãy cho chúng tôi biết email của bạn đề phản hồi nhé",
  Contact_MailForm_Error_Phone: "Vui lòng điền số điện thoại liên hệ của bạn",
  Contact_MailForm_Success_Message : "Cảm ơn! Tin nhắn của bạn đã được gửi đi."


};

export default vi;
