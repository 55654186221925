import { Segmented } from "antd";
import { useLocation } from "react-router-dom";
import { keys } from "../../../constants";
import intro from "./introduction.module.less";
import { useEffect, useState } from "react";
import Project from "../Projects/Project";
import AboutUs from "../AboutUs/AboutUs";
import Site from "../Sites/Site";
import { getImageUrl } from "../../../helpers";
import { languageKeys, Translate } from "../../../i18n";

const Introduction = () => {
  const location = useLocation()
  const [curentSegment, set_currentSegment] = useState(Translate(languageKeys.AboutUS_Banner_title))

  useEffect(() =>{
    if(!!location.state){
      set_currentSegment(location?.state)
    }else{
      set_currentSegment(Translate(languageKeys.AboutUS_Banner_title))
    }
   
  },[location])

  //get banner depends on page
  const renderBanner = () => {
    switch (curentSegment) {

      case Translate(languageKeys.AboutUS_Banner_title):
        
      return <img src={getImageUrl(keys.banner_aboutus)} className={intro["img-cover"]} alt='banner'/>
      case Translate(languageKeys.Site_Banner_title):
      return <img src={getImageUrl(keys.banner_site)} className={intro["img-cover"]} alt='banner'/>
      case Translate(languageKeys.Project_Banner_title):
        return <img src={getImageUrl(keys.banner_projects)} className={intro["img-cover"]} alt='banner'/>
      default:
        return <img src={getImageUrl(keys.banner_aboutus)} className={intro["img-cover"]} alt='banner'/>
    }
  }

  //get content depends on page
  const renderContent = () => {
    switch (curentSegment) {

      case Translate(languageKeys.AboutUS_Banner_title):
        return <AboutUs />;
      case Translate(languageKeys.Site_Banner_title):
        return <Site />;
      case Translate(languageKeys.Project_Banner_title):
        return <Project />;
      default:
        return <AboutUs />;
    }
  }

  return (
    <div className={intro["container"]}>
      <div className={intro["banner-container"]}>
        {renderBanner()}
        <div className={intro["banner-content"]}>
          <div className={intro["content-title"]}>{curentSegment}</div>
          {/* <div className={intro["content-data"]}>Lorem ipsum dolor sit amet consectetur. Scelerisque at ullamcorper aliquet cursus ornare tempor nisi. Massa dignissim leo velit ipsum. </div> */}
        </div>
      </div>
      <div className={intro["tabbar-container"]}>
        <Segmented className={intro["segment"]} block value={curentSegment} defaultValue={Translate(languageKeys.AboutUS_Banner_title)} options={[Translate(languageKeys.AboutUS_Banner_title), Translate(languageKeys.Site_Banner_title), Translate(languageKeys.Project_Banner_title)]} onChange={set_currentSegment} />
      </div>

      {/* <div className={intro["content-container"]}> */}

      {renderContent()}
      {/* </div> */}

    </div>
  );
};

export default Introduction;
