import { Col, Pagination, Row, Select, Switch, Popconfirm } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { apis, configs, keys, paths } from '../../../constants';
import { languageKeys, languages, Translate } from '../../../i18n';
import listAdmin from './listAdmin.module.less';
import { appLoading, appLoadingDone, displayMessage } from "../../../ducks/slices/appSlice";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { convertLanguageKey } from '../../../helpers';

const ListAdmin = (props) => {
    const [categoryList, set_categoryList] = useState([]);
    const [productList, set_productList] = useState([]);

    const [currentNewCategory, set_currentNewCategory] = useState(configs.Admin_News_Category_Keys[0].value)
    const [currentNewsList, set_currentNewsList] = useState([])
    const [totalNews, set_totalNews] = useState(0)
    const [newsPageSize, set_newsPageSize] = useState(5) //số item news mỗi trang
    const [currentNewsPage, set_currentNewsPage] = useState(1)

    const [totalProduct, set_totalProduct] = useState(0)
    const [productPageSize, set_productPageSize] = useState(5) //số item products mỗi trang
    const [currentProductPage, set_currentProductPage] = useState(1)

    const auth = useSelector(state => state.auth);
    const app = useSelector(state => state.app);
    const dispatch = useDispatch();

    const initProduct = async ({ page = currentNewsPage, size = productPageSize }) => {
        dispatch(appLoading())

        let config = {
            headers: {
                'Authorization': auth.token,
                
            },
        };
        let request_body = {
            lang: '',
            page_index: page - 1,
            page_size: size,
            is_pagination: true
        }
        await axios.post(apis.Get_Product_List, request_body, config)
            .then(res => {
                if (totalProduct > 1) {
                    dispatch(appLoadingDone())
                }

                if (res.status === keys.STATUS_SUCCESS) {
                    let dataResponse = res.data;
                    if (dataResponse.status === keys.STATUS_SUCCESS && !!dataResponse.data) {
                        set_currentProductPage(page)
                        set_totalProduct(dataResponse.totalRecords)
                        set_productList(dataResponse.data["PRODUCT_ALL"]);
                    }
                } else {
                    dispatch(displayMessage({
                        type: 'error',
                        content: Translate(languageKeys[`Api_Api_Message_${res.message}`]),
                    }))
                }
            })
            .catch(err => {
                dispatch(displayMessage({
                    type: 'error',
                    content: Translate(languageKeys.Api_Message_Error),
                }))

            });

        await axios.get(apis.Get_Product_Categories, config)
            .then(res => {
                let options = [];
                for (let i = 0; i < res.data.data.length; i++) {
                    options[i] = {
                        value: res.data.data[i].name,
                        name: res.data.data[i].name,
                    };
                }
                set_categoryList(options);
            })
            .catch(err => {
                dispatch(displayMessage({
                    type: 'error',
                    content: Translate(languageKeys.Api_Message_Error),
                }))
                dispatch(appLoadingDone())
            });
    }

    const initNews = ({ page = currentNewsPage, size = newsPageSize, type = '' }) => {
        dispatch(appLoading())
        let requestBody = {
            type: type,
            page_index: page - 1,
            page_size: size,
            is_pagination: true
        }
        let config = {
            headers: {
                Authorization: auth.token,
                
            },
        };
        set_currentNewsList([])
        axios.post(apis.Get_News_List, requestBody, config)
            .then(res => {
                if (totalNews > 1) {
                    dispatch(appLoadingDone())
                }
                if (res.status === keys.STATUS_SUCCESS) {
                    let dataResponse = res.data;
                    if (dataResponse.status === keys.STATUS_SUCCESS && !!dataResponse.data) {
                        set_currentNewsPage(page)
                        set_currentNewsList(dataResponse.data)
                        set_totalNews(dataResponse.totalRecords)
                    }
                } else {
                    dispatch(displayMessage({
                        type: 'error',
                        content: Translate(languageKeys[`Api_Api_Message_${res.message}`]),
                    }))
                }
                dispatch(appLoadingDone())
            })
            .catch(err => {
                
                dispatch(displayMessage({
                    type: 'error',
                    content: Translate(languageKeys.Api_Message_Error),
                }))
                dispatch(appLoadingDone())
            });
    }

    const handleCategorySelect = (id, val) => {
        let updateCate = { 'id': id, 'category': val };
        const updatedProductList = productList.map(content => content.id === id ? { ...content, category: val } : content);
        const json = JSON.stringify(updateCate);
        const config = {
            headers: {
                'Authorization': auth.token,
                'Content-Type': 'application/json',
                
            },
        };

        axios.post(apis.Post_Product_Categories_Update, json, config)
            .then(res => {
                
                if (res.status === 200)
                    set_productList(updatedProductList);

            })
            .catch(err => {
                
            });
    };

    const handleDelete = (id) => {
        const config = {
            headers: {
                'Authorization': auth.token,
                
            },
        };

        let request_body = {
            'id': id,
        }

        axios.post(apis.Delete_Product, request_body, config)
            .then(res => {
                
                if (res.status === 200) {
                    const updatedProductList = productList.filter(content => content.id !== id);
                    set_productList(updatedProductList);
                }


            })
            .catch(err => {
                
            });
    };

    const onChangeNewsPageSize = (size) => {
        set_newsPageSize(size)
        initNews({ page: 1, size, type: currentNewCategory })
    }

    const onChangeNewsPage = (page) => {
        initNews({ page })
    }

    const onChangeProductPageSize = (size) => {
        // Change page size
        if (size !== productPageSize) {
            set_productPageSize(size);
            initProduct({ page: 1, size: size })
        }
        else {
            initProduct({ page: 1, size: size })
        }
    }

    const onChangeProductPage = (page) => {
        initProduct({ page })
    }
    const onDeleteNews = (id) => {
        const config = {
            headers: {
                'Authorization': auth.token,
                'Content-Type': 'application/json',
                
            }
        };
        axios.post(apis.Post_News_Delete, { id }, config)
            .then(res => {
                if (res.status === 200) {
                    initNews({})
                } else {
                    dispatch(displayMessage({
                        type: 'error',
                        content: Translate(languageKeys[`Api_Api_Message_${res.message}`]),
                    }))
                }

            })
            .catch(err => {
                dispatch(displayMessage({
                    type: 'error',
                    content: Translate(languageKeys.Api_Message_Error),
                }))
            });
    }

    const onChangeNewProperties = (id, hideFlg, type) => {
        const config = {
            headers: {
                'Authorization': auth.token,
                'Content-Type': 'application/json',
                
            }
        };
        const requestBody = {
            id,
            hideFlg,
            type,
        }
        dispatch(appLoading())
        axios.post(apis.Post_News_UpdateProperties, requestBody, config)
            .then(res => {
                
                if (res.status === 200) {
                    let new_data = currentNewsList.map(item => item.id === id ? { ...item, hideFlg, type } : item)
                    set_currentNewsList(new_data)
                }
                dispatch(appLoadingDone())
            })
            .catch(err => {
                dispatch(appLoadingDone())
                
            });
    }

    const NewItems = (data) => {
        return <div key={data.id.toString()} className={listAdmin["news-list-item"]}>

            <Row gutter={20} justify='end' align='middle'>
                <Col flex='auto'>
                    <div className={listAdmin["news-list-title"]}>
                        {data.type === 'culture' ? data.language[app.currentLanguage].title : data.language[app.currentLanguage].summary}
                    </div>
                </Col>
                <Col>
                    <Row align={'middle'} gutter={10}>
                        <Col>
                            <Select
                                style={{
                                    width: '150px',
                                }}
                                value={data.type}
                                onChange={(value) => {

                                    if (value === 'culture' && data.imageResponses.length < 1) {
                                        dispatch(displayMessage({
                                            type: 'error',
                                            content: Translate(languageKeys.Api_Message_INVALID_FILE_CULTURE),
                                        }))
                                        return
                                    }

                                    onChangeNewProperties(data.id, data.hideFlg, value)
                                }}
                                options={configs.News_Category_Keys}
                            />
                        </Col>
                        <Col>
                            <Switch checked={data.hideFlg === '1' ? false : true} checkedChildren={Translate(languageKeys.Common_Show)} unCheckedChildren={Translate(languageKeys.Common_Hidden)}
                                onClick={() => { onChangeNewProperties(data.id, data.hideFlg === "1" ? "0" : "1", data.type) }} />
                        </Col>
                        <Col className={listAdmin["icon-button"]}>
                            <Link to={paths.admin_news + `/${data.id}`}>
                                <EditOutlined style={{ color: 'blue' }} />
                            </Link>
                        </Col>
                        <Col className={listAdmin["icon-button"]} >
                            <Popconfirm
                                title={Translate(languageKeys.Admin_Manage_DeleteNews_Title)}
                                description={Translate(languageKeys.Admin_Manage_DeleteNews_Confirm)}
                                onConfirm={() => onDeleteNews(data.id)}
                                okText={Translate(languageKeys.Common_Yes)}
                                cancelText={Translate(languageKeys.Common_No)}
                            >
                                <DeleteOutlined style={{ color: 'red' }} />
                            </Popconfirm>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    }

    const ProductItems = (data) => {
        console.log(convertLanguageKey(app.currentLanguage),data.language, data.language[convertLanguageKey(app.currentLanguage)]);

        let name = '';
        if(convertLanguageKey(app.currentLanguage) === languages.tieng_nhat_api){
            if(!!data.language[languages.tieng_nhat]){
                name = data.language[languages.tieng_nhat].productName
            }else{
                name = data.language[languages.tieng_nhat_api].productName
            }
        }else{
            name = data.language[convertLanguageKey(app.currentLanguage)].productName;
        }
        
        return <div key={data.id.toString()} className={listAdmin["product-list-item"]}>
            <Row gutter={20} align='middle'>
                <Col flex={'auto'}>
                    <div className={listAdmin["product-list-title"]}>
                        {name}
                    </div>
                </Col>
                <Col span={4}>
                    <Select
                        style={{
                            width: '100%',
                        }}
                        id={data.id}
                        value={data.category}
                        options={categoryList}
                        onSelect={(val) => handleCategorySelect(data.id, val)}
                    />
                </Col>
                <Col>
                    <Link to={paths.admin_product + '?id=' + data.id}>
                        <EditOutlined style={{ color: 'blue' }}/>
                    </Link>
                </Col>
                <Col>
                    <Popconfirm
                        title={Translate(languageKeys.Admin_Manage_DeleteProduct_Title)}
                        description={Translate(languageKeys.Admin_Manage_DeleteProduct_Confirm)}
                        onConfirm={() => handleDelete(data.id)}
                        okText={Translate(languageKeys.Common_Yes)}
                        cancelText={Translate(languageKeys.Common_No)}>
                        <DeleteOutlined style={{ color: 'red' }} />
                    </Popconfirm>
                </Col>
            </Row>
        </div>
    }

    useEffect(() => {
        if (!!auth.token) {
            Promise.all([
                initNews({}),
                initProduct({})
            ]).then((values) => {
                setTimeout(() => { dispatch(appLoadingDone()) }, 1000)
            }).catch(err => {
            })
        }
    }, [auth, dispatch])

    return <div className={listAdmin['container']}>
        <div className={listAdmin["news-list-container"]}>
            <Row align={'middle'} justify='space-between'>
                <Col>
                    <h2>{Translate(languageKeys.Admin_Manage_Title_News)}</h2>
                </Col>
                <Select
                    style={{ minWidth: '200px' }}
                    value={currentNewCategory}
                    onChange={(value) => {
                        set_currentNewCategory(value)
                        initNews({ page: 1, type: value })
                    }}
                    options={configs.Admin_News_Category_Keys}
                />
            </Row>
            {currentNewsList.map((item, index) => NewItems(item))}
            <Row justify={'end'}>
                <Pagination
                    locale={{ items_per_page: '' }}
                    pageSizeOptions={[5, 10, 20]}
                    // showSizeChanger
                    className={listAdmin["pagination"]}
                    pageSize={newsPageSize}
                    hideOnSinglePage
                    current={currentNewsPage}
                    onChange={(index) => { onChangeNewsPage(index) }}
                    // onShowSizeChange={onChangeNewsPageSize}
                    defaultCurrent={1}
                    total={totalNews}
                />
                <Select value={newsPageSize} defaultValue={newsPageSize} options={pageSizes} onChange={onChangeNewsPageSize} style={{ marginLeft: '10px' }} />

            </Row>
        </div>
        <div className={listAdmin["product-list-container"]}>
            <h2>{Translate(languageKeys.Admin_Manage_Title_Product)}</h2>
            {productList.map((item, index) => ProductItems(item))}
            <Row justify={'end'}>
                <Pagination
                    locale={{ items_per_page: '' }}
                    pageSizeOptions={[5, 10, 20]}
                    // showSizeChanger
                    className={listAdmin["pagination"]}
                    pageSize={productPageSize}
                    current={currentProductPage}
                    onChange={(index, size) => { onChangeProductPage(index, size) }}
                    defaultCurrent={1}
                    total={totalProduct}
                />
                <Select value={productPageSize} defaultValue={productPageSize} options={pageSizes} onChange={onChangeProductPageSize} style={{ marginLeft: '10px' }} />
            </Row>
        </div>
    </div>
}

export default ListAdmin;

const pageSizes = [
    {
        value: 5,
        label: `5 /${Translate(languageKeys.Common_Page)}`
    },
    {
        value: 10,
        label: `10 /${Translate(languageKeys.Common_Page)}`
    },
    {
        value: 20,
        label: `20 /${Translate(languageKeys.Common_Page)}`
    }
]