import { Button, Col, Input, Radio, Row, Select, Space } from 'antd';
import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ImageAdd } from '../../../assets/svg';
import { apis, configs, keys, paths } from '../../../constants';
import { appLoading, appLoadingDone, displayMessage } from '../../../ducks/slices/appSlice';
import { getImageUrl, getLanguage } from '../../../helpers';
import { languageKeys, languages, Translate } from '../../../i18n';
import { SlateEditor, UploadModal } from '../components';
import newEdit from './newEditor.module.less';
import NewPreviewModal from './NewsPreviewModal';

const contentFormControls = {
    en: {
        language: languages.tieng_anh,
        title: '',
        summary: '',
        description: '',
        image: ''
    },
    jp: {
        language: languages.tieng_nhat_api,
        title: '',
        summary: '',
        description: '',
        image: ''
    },
    vi: {
        language: languages.tieng_viet,
        title: '',
        summary: '',
        description: '',
        image: ''
    }
}

const NewsEditor = (props) => {

    let { id } = useParams();

    const uploadModal = useRef();
    const slateEditor = useRef();
    const previewModal = useRef()

    const history = useHistory();
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const [formControls, set_formControls] = useState(contentFormControls)
    const [title, set_title] = useState('')
    const [summary, set_summary] = useState('')
    const [image, set_image] = useState('')
    const [imageObject, set_imageObject] = useState({})
    const [currentLanguage, set_currentLanguage] = useState(getLanguage())
    const [currentCategory, set_currentCategory] = useState(configs.News_Category_Keys[0].value)
    const [imageStyle, set_imageStyle] = useState('fill')

    useEffect(() => {
        if (!!id && !!auth.token) {
            initNewsDetail(id);
        }
        if (!id) {
            resetData()
        }
    }, [id, auth])

    const resetData = () => {
        set_formControls(contentFormControls)
        set_title('')
        set_summary('')
        set_image('')
        set_imageObject({})
        set_currentLanguage(getLanguage())
        slateEditor.current?.reset()
    }

    //initNewsDetail: lấy data của news để update
    const initNewsDetail = async (id) => {
        let config = {
            headers: {
                Authorization: auth.token,

            },
        }
        let requestPath = apis.Get_News_Detail + id;
        dispatch(appLoading())
        await axios.get(requestPath, config)
            .then(response => {
                if (response.status === keys.STATUS_SUCCESS) {
                    let { data } = response;
                    if (data.status === keys.STATUS_SUCCESS && !!data.data[0]) {
                        setDataInit(data.data[0])
                    }
                } else {
                    dispatch(displayMessage({
                        type: 'error',
                        content: Translate(languageKeys[`Api_Api_Message_${response.message}`]),
                    }))
                }
                dispatch(appLoadingDone())
            })
            .catch(err => {
                dispatch(appLoadingDone())
                dispatch(displayMessage({
                    type: 'error',
                    content: Translate(languageKeys.Api_Message_Error),
                }))

            })
    }

    //setDataInit
    const setDataInit = (data) => {
        console.log(data);
        set_formControls(data.language)
        set_currentCategory(data.type)
        if (!!data.imageResponses[0]) {
            set_image(data.imageResponses[0].localPath)
            set_imageObject(data.imageResponses)
            set_imageStyle(data.imageStyle)
        }
        set_title(data.language[`${currentLanguage}`][`${keys.New_FormControls.title}`])
        set_summary(data.language[`${currentLanguage}`][`${keys.New_FormControls.summary}`])

        if (!!data.language[`${currentLanguage}`][`${keys.New_FormControls.description}`]) {
            slateEditor.current.parseHtml(data.language[`${currentLanguage}`][`${keys.New_FormControls.description}`])
        }
    }

    const onEditFormControl = ({ value }, control) => {
        let newControls = formControls
        newControls[`${currentLanguage}`][`${control}`] = value;
        set_formControls(newControls)
        switch (control) {
            case keys.New_FormControls.title:
                set_title(value)
                break;
            case keys.New_FormControls.summary:
                set_summary(value)
                break;
            default: break;
        }
    }

    //click thay doi ngon ngu
    const onChangeLanguage = (value) => {
        set_currentLanguage(value)
        set_title(formControls[`${value}`][`${keys.New_FormControls.title}`])
        set_summary(formControls[`${value}`][`${keys.New_FormControls.summary}`])
        if (!!formControls[`${value}`][`${keys.New_FormControls.description}`]) {
            slateEditor.current.parseHtml(formControls[`${value}`][`${keys.New_FormControls.description}`])
        } else {
            slateEditor.current.reset()
        }
    }

    //thay doi anh thumbnail
    const onChangeImage = (data) => {
        if (!!data.length) {
            set_image(data[0].originFileObj)
        }
    }

    //thay đổi style ảnh bìa
    const onChangeRadio = (ev) => {
        set_imageStyle(ev.target.value)
    }

    const onSubmitUpload = async () => {
        if (currentCategory === 'culture' && image === '') {
            dispatch(displayMessage({
                type: 'error',
                content: Translate(languageKeys.Api_Message_Require_Image)
            }))
            return
        }
        let requestBody = {}
        requestBody = {
            id: id || "",
            contentNews: [{
                lang: "vi",
                title: formControls.vi.title,
                summary: formControls.vi.summary,
                description: formControls.vi.description.replaceAll('homepage-api.nsmv.com.vn', 'api.nsmv.com.vn')
            },
            {
                "lang": "en",
                title: formControls.en.title,
                summary: formControls.en.summary,
                description: formControls.en.description.replaceAll('homepage-api.nsmv.com.vn', 'api.nsmv.com.vn')
            },
            {
                "lang": "jp",
                title: formControls.jp.title,
                summary: formControls.jp.summary,
                description: formControls.jp.description.replaceAll('homepage-api.nsmv.com.vn', 'api.nsmv.com.vn')
            }
            ],
            image_style: imageStyle,
            type: currentCategory,
            hideFlg: "0",
            images_delete: (!!id && imageObject.length > 0 && typeof image === 'object') ? imageObject.map(i => i.id) : []
        }
        const blob = new Blob([JSON.stringify(requestBody)], {
            type: 'application/json'
        });

        let formData = new FormData();
        formData.append('request', blob)
        if (typeof image === 'object') {
            formData.append('files', image)
            if (!!id) {
                requestBody.images_delete = [imageObject.id]
            }
        }
        let config = {
            headers: {
                Authorization: auth.token,

            },
        }
        dispatch(appLoading())
        await axios.post(apis.Post_News_CreateNews, formData, config)
            .then(response => {
                if (response.status === keys.STATUS_SUCCESS) {
                    let { data } = response;
                    if (data.status === keys.STATUS_SUCCESS &&
                        (data.message === keys.API_RESPONSE_MESSAGE_REGISTED ||
                            data.message === keys.API_RESPONSE_MESSAGE_UPDATED)) {
                        history.push(paths.admin_list)
                    } else {
                        dispatch(displayMessage({
                            type: 'error',
                            content: Translate(languageKeys[`Api_Api_Message_${response.message}`]),
                        }))
                    }
                } else {
                    dispatch(displayMessage({
                        type: 'error',
                        content: Translate(languageKeys.Api_Message_Error),
                    }))
                }
                dispatch(appLoadingDone())
            })
            .catch(err => {
                dispatch(appLoadingDone())
                dispatch(displayMessage({
                    type: 'error',
                    content: Translate(languageKeys.Api_Message_Error),
                }))

            })
    }

    const onOpenPreview = () => {
        let previewData = {
            title,
            summary,
            image,
            html: slateEditor.current.getHtml()
        }
        previewModal.current.openPreview(previewData)
    }


    return <div className={newEdit['container']}>
        <div className={newEdit['content-container']}>
            <Row gutter={50}>
                <Col span={15}>
                    <div className={newEdit['form-container']}>
                        <div className={newEdit['form-title']}>{Translate(languageKeys.Admin_News_Title_Title)}</div>
                        <Input
                            value={title}
                            onChange={({ target }) => onEditFormControl(target, keys.New_FormControls.title)} />
                        <div className={newEdit['form-title']}>{Translate(languageKeys.Admin_News_Title_Summary)}</div>
                        <Input.TextArea style={{ flex: 1, overflow: 'auto' }}
                            value={summary}
                            onChange={({ target }) => onEditFormControl(target, keys.New_FormControls.summary)} />
                    </div>
                </Col>
                <Col span={9}>
                    <div
                        className={newEdit['image-container']}
                        onClick={() => !image && uploadModal.current?.open()}>
                        {!!image ?
                            <img alt='upload' src={typeof image === 'string' ? getImageUrl(image) : image.url} className={newEdit[`img-${imageStyle}`]} style={{ borderRadius: '12px', width: '100%', height: '100%' }} />
                            : <ImageAdd width={240} height={220} />}
                        {!!image && <div
                            className={newEdit['change-image']}
                            onClick={() => uploadModal.current?.open()}>

                            <ImageAdd width={40} height={40} />
                        </div>}
                    </div>
                    <Radio.Group onChange={onChangeRadio} value={imageStyle}>
                        <Space direction="horizontal">
                            <Radio value={'fill'}>{Translate(languageKeys.Admin_News_Image_Type_Fill)}</Radio>
                            <Radio value={'contain'}>{Translate(languageKeys.Admin_News_Image_Type_Contain)}</Radio>
                            <Radio value={'cover'}>{Translate(languageKeys.Admin_News_Image_Type_Cover)}</Radio>
                        </Space>
                    </Radio.Group>
                </Col>
            </Row>
            <SlateEditor ref={slateEditor} style={{ width: '100%', minHeight: '200px', marginTop: '40px' }} onChangeHtmlContent={html => onEditFormControl({ value: html }, keys.New_FormControls.description)} />
            <Row gutter={20}>
                <Col>
                    <Select
                        value={currentLanguage}
                        style={{ width: 120 }}
                        onChange={onChangeLanguage}
                        options={configs.Language_Dropdown_Keys} />
                </Col>
                <Col flex={'auto'}>
                    <Select
                        value={currentCategory}
                        style={{ width: 220 }}
                        onChange={(cate) => set_currentCategory(cate)}
                        options={configs.News_Category_Keys} />
                </Col>
                <Col onClick={onOpenPreview}>
                    <Button className={newEdit['button']}>{Translate(languageKeys.Admin_News_Button_Preview)}</Button>
                </Col>
                <Col>
                    <Button className={newEdit['button']} type='primary'
                        onClick={onSubmitUpload}>{!!id ? Translate(languageKeys.Admin_News_Button_Save) : Translate(languageKeys.Admin_News_Button_Upload)}</Button>
                </Col>
            </Row>
            <NewPreviewModal ref={previewModal} />
            <UploadModal ref={uploadModal} dataUploadSuccess={onChangeImage} noUpload={true} />
        </div>
    </div>
}

export default NewsEditor;