import {  Col, Row } from 'antd';
import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { apis, keys } from '../../../constants';
import { appLoading, appLoadingDone, displayMessage } from '../../../ducks/slices/appSlice';
import { convertMillisecondToDate, convertMilliUTCToTime } from '../../../helpers';
import newDetail from './newDetail.module.less';
import '../../../assets/style/previewHtml.css';
import renderHTML from 'react-render-html';
import { calendarIcon, clockIcon } from '../../../assets/img';
import cn from 'classnames'


const NewsDetail = (props) => {

    let { id } = useParams();
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const [data, set_data] = useState()
    const currentLanguage = useSelector(state => state.app.currentLanguage)

    useEffect(() => {
        if (!!id) {
            initNewsDetail(id);
        } else {
            resetData()
        }
    }, [id, auth])

    const resetData = () => {
    }

    //initNewsDetail: lấy data của news để update
    const initNewsDetail = async (id) => {
        let config = {
            headers: {
                Authorization: auth.token,
                
            },
        }
        let requestPath = apis.Get_News_Detail + id;
        dispatch(appLoading())
        await axios.get(requestPath, config)
            .then(response => {
                if (response.status === keys.STATUS_SUCCESS) {
                    let { data } = response;
                    if (data.status === keys.STATUS_SUCCESS && !!data.data[0]) {
                        setDataInit(data.data[0])
                    }
                } else {
                    dispatch(displayMessage({
                        type: 'error',
                        content: response.message,
                    }))
                }
                dispatch(appLoadingDone())
            })
            .catch(err => {
                dispatch(appLoadingDone())
                dispatch(displayMessage({
                    type: 'error',
                    content: err.message,
                }))
                
            })
    }

    //setDataInit
    const setDataInit = (data) => {
        set_data(data)
    }

    return <div className={newDetail['container']}>
        {!!data && <div className={newDetail['content-container']}>
            {/* {(!!data.imageResponses && data.imageResponses.length > 0) &&
                <img src={getImageUrl(data.imageResponses[0].localPath)} className={newDetail[`img-${data.imageStyle}`]} style={{ width: '100%' }} />} */}
            {(!!data && !!data.language[currentLanguage]) && <div className={newDetail['content']}>
                <div className={newDetail['preview-title']}>{data.language[currentLanguage].title}</div>
                <Row align='middle' gutter = {10} className={cn(newDetail["news-block-content-time"])}>
                    <Col style={{display: 'flex', alignItems: 'center'}}><img src={calendarIcon} alt='icon'  className = {newDetail['img-contain']}/></Col><Col>{convertMillisecondToDate(data.createDate)}</Col>
                    <Col style={{display: 'flex', alignItems: 'center'}}><img src={clockIcon} alt='icon' className = {newDetail['img-contain']} /></Col><Col>{convertMilliUTCToTime(data.createDate)}</Col>
                    </Row>
                <div className={newDetail['preview-summary']}>{data.language[currentLanguage].summary}</div>
                <div className={newDetail['preview-html']}>{renderHTML(data.language[currentLanguage].description)}</div>
            </div>
            }
        </div>}
    </div>
}

export default NewsDetail;