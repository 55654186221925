import QueueAnim from "rc-queue-anim";
import { useEffect, useRef, useState } from "react";
import useIsInViewport from "../../hooks/viewPortHook";

const QueueAnimate = (props) => {
    let {children, type = 'right', style = {} } = props;
    const [show, set_show] = useState(false)
    const queueAnimeRef = useRef(null)
    const isInViewport1 = useIsInViewport(queueAnimeRef);

    useEffect(() => {
        if(isInViewport1){
            set_show(true)
        }
    },[isInViewport1])


    return <div ref={queueAnimeRef} style = {{width: '100%', height: '100%', display:'flex', flexDirection: 'column', flex:1, ...style}} id = '1'>
        {show && <QueueAnim delay={200} duration = {2500} style = {{width: '100%', height: '100%', display:'flex', flexDirection: 'column', flex:1}} type = {type}>
            <div key="a"  style = {{width: '100%', height: '100%', display:'flex', flexDirection: 'column', flex:1}} type = {type}>
              {children}
            </div>
    </QueueAnim>}
        </div>
}

export default QueueAnimate