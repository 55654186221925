import { Col, Row, Image, Segmented } from "antd";
import React, { useEffect } from 'react'
import { useState } from 'react';
import cn from "classnames";
import product from './product.module.less';
import QRCode from "react-qr-code";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import renderHTML from 'react-render-html';
import { getImageUrl } from '../../../helpers';
import { useDispatch, useSelector } from "react-redux";
import { displayMessage, appLoading, appLoadingDone } from "../../../ducks/slices/appSlice";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { apis, keys } from "../../../constants";
import { languageKeys, languages, Translate } from "../../../i18n";
import { Zalo } from "../../../assets/svg";

const Product = () => {
  const [productName, set_productName] = useState()
  const [productDetail, set_productDetail] = useState()
  const [contactList, set_contactList] = useState([]);
  const [standardList,] = useState([]);
  const [image, set_image] = useState('')
  const [imageList, set_imageList] = useState([])

  const [initData, set_initData] = useState({ standards: [], standards_en: [], standards_jp: [] })
  const [isLoading, set_isLoading] = useState(true)
  const [currentStandard, set_currentStandard] = useState('')
  const [segment,] = useState()
  const [dataproduct, set_dataproduct] = useState("<div></div>")
  const dispatch = useDispatch();
  const search = useLocation().search;
  const id = new URLSearchParams(search).get('id');
  const currentLanguage = useSelector(state => state.app.currentLanguage);

  useEffect(() => {
    init()
  }, [])


  useEffect(() => {
    if (!!standardList[segment]) {
      let detail = standardList[segment].standardDetail.replaceAll('homepage-api.nsmv.com.vn', 'api.nsmv.com.vn');
      console.log('segment detail', detail);
      set_dataproduct(detail)
    }

  }, [segment])

  const init = () => {
    let config = {
      params: {
        id: id
      },
    };
    let requestPath = apis.Get_Product;
    // dispatch(appLoading())
    set_isLoading(true)
    axios.get(requestPath, config)
      .then(response => {
        if (response.status === keys.STATUS_SUCCESS) {
          let { data } = response;
          if (data.status === keys.STATUS_SUCCESS && !!data.data) {
            setData(data.data)
          }
        } else {
          dispatch(displayMessage({
            type: 'error',
            content: response.message,
          }))
        }
        setTimeout(() => {
          set_isLoading(false)

        }, 10000000)
      })
      .catch(err => {
        set_isLoading(false)
        dispatch(displayMessage({
          type: 'error',
          content: err.message,
        }))

      })
  }

  const setData = (data) => {
    set_initData(data)
    // Handle image
    let images = data.imageResponses || [];
    set_imageList(images)
    if (!!images.length)
      set_image(images[0])

    set_contactList(data.contacts)

    console.log(data.language, currentLanguage);

    Object.keys(data.language).map((item, index) => {
      if (item === currentLanguage) {
        set_productName(data.language[item].productName);
        set_productDetail(data.language[item].productDetail);
      }
      return item;
    })
    // Handle standards

    let data_segment = getCurrentLangStandard(data).map(item => item.standardName)
    let segment_detail = getCurrentLangStandard(data).map(item => item.standardDetail)
    set_currentStandard(data_segment[0])
    set_dataproduct(segment_detail[0] || '<div></div>')
  }

  const onSelectStandard = (value) => {
    let itemStandard = getCurrentLangStandard().find(item => item.standardName === value)
    set_dataproduct(itemStandard.standardDetail)
    set_currentStandard(value)
  }
  const segmentImageOption = (index, currentImage) => {
    return <Col span={6}>
      <div className={product["image-container"]}>
        <Image
          className={product["sub-image"]}
          style={{ borderRadius: '15px' }}
          src={getImageUrl(currentImage.localThumbnailPath)}
          height={'100%'}
          width={'100%'}
          preview={false}
          onClick={() => set_image({ ...currentImage, currentIndex: index })} />
      </div>
      <Row>
        <Col span={24} >
          <div className={image.currentIndex === index && product["selected"]} />
        </Col>
      </Row>
    </Col>
  }

  const getCurrentLangStandard = (data = initData) => {
    switch (currentLanguage) {
      case languages.tieng_viet:
        return data?.standards || []
      case languages.tieng_anh:
        return data?.standards_en || []
      case languages.tieng_nhat:
      case languages.tieng_nhat_api:
        return data?.standards_jp || []
      default:
        return [];
    }
  }


  return <div className={product["container"]}>
    <div className={product["content-container"]}>
      <div className={product["block-content"]}>
        <Row>
          <Col span={12}>
            <Row>
              <div className={cn(product["image-container"], product["main-image"])}>
                {!!image ? <Image height={'100%'} style={{ borderRadius: '15px' }} src={getImageUrl(image.localPath)} /> : ''}
              </div>
            </Row>

            <Row gutter={16} wrap={false} style={imageList.length > 4 && { overflowX: 'scroll' }}>
              {imageList.map((item, index) =>
                segmentImageOption(index, item)
              )}
            </Row>

          </Col>
          <Col flex={"auto"}>

          </Col>
          <Col span={10}>
            <div className={product["item-info"]}>
              <div className={product["item-title"]}>{productName}</div>
              <div className={product["content"]}>
                {productDetail}
              </div>
            </div>

            <div className={product["contact-title"]}>{Translate(languageKeys.Product_Contact)}</div>
            {contactList.map((contact) => (
              <div className={product["contact-info"]}>

                <div className={product["contact-body"]}>
                  <Col span={6}>
                    <QRCode value={contact.contactPhone} size={64} className={product["qr-code"]} />
                  </Col>
                  <Col span={18}>
                    <b>{contact.contactName}</b><br />
                    <PhoneOutlined /> <a href={`tel:${contact.contactPhone}`}>{contact.contactPhone}</a><br />
                    <MailOutlined /> <a href={`mailto:${contact.email}`}>{contact.email}</a> <br />
                    {!!contact.zaloPhone && <div><Zalo /> {contact.zaloPhone} <br /> </div>}
                  </Col>
                </div>
              </div>
            ))}

          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Segmented
              block
              // defaultValue={keys.product_jis}
              value={currentStandard}
              options={getCurrentLangStandard().map(item => item.standardName)}
              onChange={(value) => { onSelectStandard(value) }}
              className={product["segment-bar"]} size='large' />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className={product["segment-container"]}>
              {renderHTML(dataproduct.replaceAll('homepage-api.nsmv.com.vn', 'api.nsmv.com.vn'))}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  </div>


}

export default Product;